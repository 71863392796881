import { trim, uniq } from "lodash";
import { Format } from "../Enums/Format";
import { Language } from "../Enums/Language";
import IOptionData from "../Interfaces/IOptionData";

export class GeneratorHelper {

    public static topicsToArray(topics: string): string[] {
        const arr = topics.split("\n");
        return arr;
    }

    public static filterProcessTopics(topics: string): string[] {
        const arrOld = topics.split("\n");

        let arrNew: string[] = [];

        arrOld.map(value => {
            arrNew.push(trim(value));
        })

        arrNew = uniq(arrNew);
        return arrNew;
    }

    public static getFormatLabel(format: Format) {
        switch (format) {

            case Format.Html:
                return "HTML";

            case Format.Markdown:
                return "Markdown";

            default:
        }
    }

    public static getArticleRangeOptions(): IOptionData[] {
        return [
            { label: "2 podnadpisy (200 - 400 slov)", value: 2 },
            { label: "3 podnadpisy (350 - 550 slov) - doporučujeme", value: 3 },
            { label: "4 podnadpisy (350 - 550 slov)", value: 4 },
            { label: "5 podnadpisů (500 - 800 slov)", value: 5 },
            { label: "6 podnadpisů (600 - 950 slov)", value: 6 },
            { label: "7 podnadpisů (750 - 950 slov)", value: 7 },
            { label: "8 podnadpisů (800 - 1050 slov)", value: 8 },
            { label: "9 podnadpisů (1000 - 1200 slov)", value: 9 },
            { label: "10 podnadpisů (1000 - 1400 slov)", value: 10 },
        ];
    }

    public static getArticleLanguageOptions(): IOptionData[] {
        return [
            { label: "Čeština", value: Language.Czech },
            { label: "Slovenština", value: Language.Slovak },
            { label: "Angličtina", value: Language.English },
        ]
    }
}