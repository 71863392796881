import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IWebsite from "../../Interfaces/IWebsite";
import IEditWebsiteRequest from "../../Interfaces/Websites/IEditWebsiteRequest";
import WebsitesApiService from "../../Services/WebsitesApiService";
import { LanguageHelper } from "../../Utils/LanguageHelper";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { WebsiteHelper } from "../../Utils/WebsiteHelper";
import InputText from "../Inputs/InputText";
import InputUrl from "../Inputs/InputUrl";
import SelectBox from "../Inputs/SelectBox";
import { SubmitButton } from "../Inputs/SubmitButton";

interface IProps {
    onHide(): void,
    website: IWebsite,
}

const websitesApiService = new WebsitesApiService();

export const EditWebsiteModal = (props: IProps) => {

    const { onHide, website } = props;

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState<string>(website.name);
    const nameRef = useRef<InputText>(null);

    const [domain, setDomain] = useState<string | undefined>(website.domain);
    const domainRef = useRef<InputUrl>(null);

    const [cms, setCms] = useState(website.cms);
    const [language, setLanguage] = useState(website.language);
    const languageRef = useRef<SelectBox>(null);

    useEffect(() => {
        return (() => {
            websitesApiService.cancelRequests();
        })
    }, [])


    const onSubmit = () => {
        const isValid = ValidationHelper
            .validateInputs([nameRef, domainRef, languageRef])

        if (isValid) {
            setLoading(true);

            const editWebsiteRequest: IEditWebsiteRequest = {
                domain: domain,
                name: name,
                cms: cms,
                language: language!,
            };

            websitesApiService.editWebsite(website.id, editWebsiteRequest)
                .then(data => {
                    if (data.success) {
                        onHide();
                        toast.success("Změny uloženy");
                    }
                });
        }
    }

    return (
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Upravit web "{website.name}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col col-md-6">
                        <InputText
                            ref={nameRef}
                            label="Název webu"
                            value={name}
                            onChange={(val) => setName(val)}
                            required
                        />
                    </div>

                    <div className="col col-md-6">
                        <InputUrl
                            ref={domainRef}
                            label="Url webu"
                            value={domain || ''}
                            onChange={(val) => setDomain(val)}
                            placeholder="např. https://mujweb.cz"
                        />
                    </div>

                    <div className="col col-md-6 mb-md-2">
                        <SelectBox
                            ref={languageRef}
                            label="Jazyk"
                            defaultValue="Vyberte jazyk"
                            value={language}
                            onChange={(val) => setLanguage(val)}
                            options={WebsiteHelper.getLanguagesOptions()}
                            required
                        />
                    </div>

                    <div className="col col-md-6 mb-md-2">
                        <SelectBox
                            label="CMS"
                            defaultValue="Vyberte"
                            value={cms}
                            onChange={(val) => setCms(val)}
                            options={WebsiteHelper.getCmsOptions()}
                        />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    loading={loading}
                    onClick={onSubmit}
                >
                    Uložit změny
                </SubmitButton>

                <Button
                    onClick={onHide}
                    variant="secondary"
                    disabled={loading}
                >
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)

}