import { useNavigate } from "react-router-dom";
import IWebsite from "../../Interfaces/IWebsite";
import { Tooltip } from 'react-tooltip'
import { useState } from "react";
import { WebsiteXmlExportModal } from "./WebsiteXmlExportModal";
import { WebsiteJsonExportModal } from "./WebsiteJsonExportModal";

interface IProps {
    website: IWebsite,
}

export const WebsiteArticlesExportsButtons = (props: IProps) => {

    const { website } = props;

    const [showXmlExportModal, setShowXmlExportModal] = useState(false);
    const [showJsonExportModal, setShowJsonExportModal] = useState(false);

    const navigate = useNavigate();

    return <>
        {showXmlExportModal &&
            <WebsiteXmlExportModal
                onHide={() => setShowXmlExportModal(false)}
                website={website}
            />}

        {showJsonExportModal &&
            <WebsiteJsonExportModal
                onHide={() => setShowJsonExportModal(false)}
                website={website}
            />}

        <div className="float-end">
            <button onClick={() => navigate(`/websites/${website.id}/wp-export`)} className="btn btn-label-primary me-2">
                <i className="fa-solid fa-upload me-2"></i>Wordpress
            </button>

            <Tooltip
                anchorId="wpExportDisabled"
                place="bottom"
                html={`Jakmile přiřadíte web budete moci exportovat do Wordpressu`}
            />

            <a
                onClick={() => setShowJsonExportModal(true)}
                className="btn btn-label-primary me-2"
            >
                <i className="fa-solid fa-download me-2"></i>JSON
            </a>

            <a
                onClick={() => setShowXmlExportModal(true)}
                className="btn btn-label-primary me-2"
            >
                <i className="fa-solid fa-download me-2"></i>XML
            </a>
        </div>
    </>
}