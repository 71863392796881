import { inject, observer } from "mobx-react";
import CurrentUserApiService from "../../Services/CurrentUserApiService"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import React, { useEffect, useRef, useState } from "react";
import InputEmail from "../Inputs/InputEmail";
import InputPassword from "../Inputs/InputPassword";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import IInputField from "../../Interfaces/IInputField";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import IFormError from "../../Interfaces/IFormError";
import { toast } from "react-toastify";
import { SubmitButton } from "../Inputs/SubmitButton";

interface IProps {
    currentUserStore?: CurrentUserStore,
    onLogged(): void,
    defaultEmail?: string,
}

const currentUserApiService = new CurrentUserApiService();

export const LoginForm = inject('currentUserStore')(observer((props: IProps) => {

    const [email, setEmail] = useState(props.defaultEmail || "");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<IFormError[]>([]);

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    }, []);

    const emailRef = useRef<InputEmail>(null);
    const passRef = useRef<InputEmail>(null);

    var arr: React.RefObject<IInputField>[] = [emailRef, passRef];

    const [loadingLogin, setLoadingLogin] = useState(false);

    function onSubmit() {
        const isValid = ValidationHelper.validateInputs(arr);

        if (isValid) {
            setLoadingLogin(true);
            currentUserApiService.login(email, password)
                .then(response => {
                    if (response.data.success) {
                        toast.success("Byli jste přihlášeni");
                        props.onLogged();
                    }
                    else {
                        setErrors(response.data.formErrors);
                    }
                })
                .finally(() => {
                    setLoadingLogin(false);
                })
        }
    }

    return (<>
        <InputEmail
            ref={emailRef}
            label="Email"
            value={email}
            onChange={(val) => setEmail(val)}
            required
        />

        <InputPassword
            ref={passRef}
            label="Heslo"
            value={password}
            onChange={(val) => setPassword(val)}
            required={true}
        />

        <FormErrorSummary
            errors={errors}
        />

        <SubmitButton
            loading={loadingLogin}
            onClick={onSubmit}
            className="mt-3 w-100"
        >
            <>Přihlásit se</>
        </SubmitButton>
    </>)
}))