import { filter, find, uniq } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBox from "../../Components/Inputs/CheckBox";
import InputEmail from "../../Components/Inputs/InputEmail";
import { NewTextarea } from "../../Components/Inputs/NewTextarea";
import SelectBox from "../../Components/Inputs/SelectBox";
import { SubmitButton } from "../../Components/Inputs/SubmitButton";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle";
import { FormErrorSummary } from "../../Components/Others/FormErrorSummary";
import { Language } from "../../Enums/Language";
import IFormError from "../../Interfaces/IFormError";
import ICreateOrderRequest from "../../Interfaces/Orders/ICreateOrderRequest";
import OrdersApiService from "../../Services/OrdersApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { PricingHelper } from "../../Utils/PricingHelper";
import { Tooltip } from 'react-tooltip'
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import { Format } from "../../Enums/Format";
import WebsiteStore from "../../Stores/WebsiteStore";
import { WebsiteHelper } from "../../Utils/WebsiteHelper";
import InputText from "../../Components/Inputs/InputText";
import { LoginModal } from "../../Components/Account/LoginModal";
import { OrderState } from "../../Enums/OrderState";
import { HowToWriteHeadingsModal } from "../../Components/AboutApp/HowToWriteHeadingsModal";
import { Currency } from "../../Enums/Currency";
import MultiSelectBox from "../../Components/Inputs/MultiSelectBox";
import IOptionData from "../../Interfaces/IOptionData";
import { WebsiteWordpressUpdateCategoriesModal } from "../../Components/Websites/WebsiteWordpressUpdateCategoriesModal";

interface IProps {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
}

const orderApiService = new OrdersApiService();
const currentUserApiService = new CurrentUserApiService();

export const GenerateArticlesPage = inject('currentUserStore', 'websiteStore')(observer((props: IProps) => {

    const navigate = useNavigate();

    const currentUser = props.currentUserStore?.user;

    const storageTopicsArr = JSON.parse(localStorage.getItem("topics") || "[]") as string[];

    const [topicsArr, setTopicsArr] = useState<string[]>(storageTopicsArr || []);

    const [paragraphCount, setParagraphCount] = useState(3);

    const [email, setEmail] = useState("");
    const emailRef = useRef<InputEmail>(null)

    const [language, setLanguage] = useState(Language.Czech);
    const [websiteId, setWebsiteId] = useState<string | undefined>(undefined);
    const [websiteOption, setWebsiteOption] = useState<string | undefined>(undefined);

    const [agreeTerms, setAgreeTerms] = useState(false);

    const [errors, setErrors] = useState<IFormError[]>([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [addNewWebsite, setAddNewWebsite] = useState(false);

    const [showLoginModal, setShowLoginModal] = useState(false);

    const [showHowToWriteHeadingsModal, setShowHowToWriteHeadingsModal] = useState(false);

    useEffect(() => {
        if (websiteOption === "other") {
            setAddNewWebsite(true);
            setWebsiteId(undefined);
        } else {
            setAddNewWebsite(false);
            setWebsiteId(websiteOption);
        }

    }, [websiteOption])

    const websites = currentUser ? props.websiteStore?.getByUserId(currentUser.id) : [];

    const websitesOptions = WebsiteHelper.getWebsiteOptions(websites || []);
    websitesOptions.push({ label: "Jiný web", value: "other" })

    const [newWebsiteName, setNewWebsiteName] = useState("");
    const [wordpressCategoriesIds, setWordpressCategoriesIds] = useState<number[]>([]);

    const selectedWebsite = find(websites, { id: websiteId });

    const [showWebWpUpdateCategoriesModal, setShowWebWpUpdateCategoriesModal] = useState(false);

    useEffect(() => {
        return () => {
            orderApiService.cancelRequests();
            currentUserApiService.cancelRequests();
        }
    }, []);

    function onSubmit() {
        let errors: IFormError[] = [];
        var uniqTopicsArr = uniq(topicsArr);

        if (uniqTopicsArr.length === 0) {
            errors.push({ message: "Zadejte nadpisy článků, které chcete napsat" });
        }

        if (!paragraphCount) {
            errors.push({ message: "Zadejte počet odstavců" });
        }

        if (!currentUser && !agreeTerms) {
            errors.push({ message: "Musíte souhlasit s podmínkami" });
        }

        if (!currentUser && !email) {
            errors.push({ message: "Zadejte e-mail" });
        }

        if (errors.length > 0) {
            setErrors([errors[0]]);
        }
        else {
            setErrors([]);
            setSubmitLoading(true);

            // create order
            var createOrderRequest: ICreateOrderRequest = {
                articleWordsCount: undefined,
                language: language,
                paragraphsCount: paragraphCount,
                topicsTitles: uniqTopicsArr,
                useHeadings: true,
                format: Format.Html,
                leadParagraph: true,
                subscribeNews: false,
                websiteId: websiteId,
                newWebsiteName: newWebsiteName,
                newUserEmail: email,
                wordpressCategoriesIds: wordpressCategoriesIds,
            };

            orderApiService.createOrder(createOrderRequest)
                .then(data => {
                    if (data.success) {
                        const order = data.records.orders.items[0];

                        cleanTopicsLocalStorage();

                        toast.success("Objednávka vytvořena");

                        if (order.state === OrderState.PendingPayment) {
                            navigate(`/account`)
                        }
                        else {
                            navigate(`/orders/${order.id}`)
                        }
                    }
                    else {
                        const errorType = data.others.get("errorType");

                        if (errorType === "loginRequired") {
                            setShowLoginModal(true);

                            toast.warning("Již jste registrován. Je nutné se přihlásit");
                        }

                        setErrors(data.formErrors);
                        setSubmitLoading(false);
                    }
                })
        }
    }

    const saveTopicsToLocalStorage = () => {
        let array = topicsArr.filter(str => str !== "");
        array = uniq(array);
        localStorage.setItem("topics", JSON.stringify(array));

        setTopicsArr(array);
    }

    const cleanTopicsLocalStorage = () => {
        localStorage.setItem("topics", JSON.stringify([]));
    }

    const isFree = !currentUser && topicsArr.length <= 1;

    const wpCategoriesOptions = WebsiteHelper.convertWpCategoriesToOptions(selectedWebsite?.wordpressCategories);

    const selectedCategoriesOptions = filter(wpCategoriesOptions, (opt: IOptionData) => {
        if (wordpressCategoriesIds.includes(opt.value)) {
            return true;
        }
        return false;
    })

    return <>
        <CustomDocumentTitle title="Generovat články">
            {showLoginModal && <LoginModal
                defaultEmail={email}
                onLogged={() => {
                    setShowLoginModal(false);
                    setErrors([]);
                }}
                onHide={() => setShowLoginModal(false)}
            />}

            {showHowToWriteHeadingsModal &&
                <HowToWriteHeadingsModal
                    onHide={() => setShowHowToWriteHeadingsModal(false)}
                />}

            {showWebWpUpdateCategoriesModal && selectedWebsite &&
                <WebsiteWordpressUpdateCategoriesModal
                    website={selectedWebsite!}
                    onHide={() => setShowWebWpUpdateCategoriesModal(false)}
                />
            }

            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Hromadný generátor článků</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-12 col-md-6 col-xl-6">
                    <div className="portlet" style={{ backgroundColor: 'rgb(59 243 33 / 10%)' }}>
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3
                                className="portlet-title text-black"
                                style={{ whiteSpace: 'pre-wrap' }}
                            >
                                <i className="fa-solid fa-list me-1"></i> 1. KROK - ZADEJTE NÁZVY ČLÁNKŮ, KTERÉ CHCETE NAPSAT
                            </h3>
                        </div>
                        <div className="portlet-body" id="s">
                            <p>
                                Aplikace vám vygeneruje články na základě jejich nadpisů.&nbsp;
                                <a onClick={() => setShowHowToWriteHeadingsModal(true)} className="text-primary clickable">Jak psát nadpisy</a>.
                            </p>

                            <p className="mt-3 text-primary">
                                <i className="fa-solid fa-circle-info" /> Každý nadpis = 1 článek, relevantní podnadpisy se vygenerují samy
                            </p>

                            <NewTextarea
                                onChange={(value) => setTopicsArr(GeneratorHelper.topicsToArray(value))}
                                label=""
                                value={topicsArr.join("\n")}
                                rows={11}
                                className="topics"
                                placeholder="&#10;např.‎ &#10;  Nejlepší místa ve Francii&#10;...Výhody elektromobilů&#10;...Jak začít investovat&#10;...Srovnání Intel a AMD procesorů&#10;...Čím vyčistit podlahu&#10;...Jak se zbavit škůdců&#10;...Jak se starat o kočku&#10;...Jak udržovat kočičí srst&#10;"
                                required
                                onBlur={() => saveTopicsToLocalStorage()}
                            />

                            <p className="mt-2">TIP: <b>Můžete zadat 1 až 300 nadpisů</b>.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-gear me-2"></i>2. KROK - PARAMETRY ČLÁNKU</h3>
                        </div>
                        <div className="portlet-body">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <SelectBox
                                        label="Rozsah článku"
                                        options={GeneratorHelper.getArticleRangeOptions()}
                                        value={paragraphCount}
                                        required
                                        onChange={(val) => setParagraphCount(val)}
                                        id="subtitlesCount"
                                    />

                                    <Tooltip
                                        anchorId="subtitlesCount"
                                        place="bottom"
                                        className="text-center"
                                        html={`Počet slov může být velmi proměnný v závislosti na tématu článku. <br /> <span class="text-warning">Zaplatíte férově dle počtu znaků</span>`}
                                    />
                                </div>
                                {/* 
                                <div className="col-12 col-sm-6 col-lg-6">
                                    <SelectBox
                                        label="Styl úvodního odstavce"
                                        options={[
                                            { label: "Informativní", value: Language.Czech },
                                            { label: "Poutavý", value: Language.Czech },
                                            { label: "Příběh", value: Language.Czech },
                                        ]}
                                        value={language}
                                        required
                                        onChange={(val) => setLanguage(val)}
                                    />
                                </div>*/}

                                <div className="col-12 col-sm-6 col-lg-6">
                                    <SelectBox
                                        label="Jazyk"
                                        options={GeneratorHelper.getArticleLanguageOptions()}
                                        value={language}
                                        required
                                        onChange={(val) => setLanguage(val)}
                                    />
                                </div>
                            </div>

                            {paragraphCount > 5 && <p className="text-secondary mt-4">
                                <b>
                                    Příliš dlouhé články nemusí být dostatečně kvalitní. Ideální jsou o 2 - 4 podnadpisech.
                                </b></p>}

                            <p className="mt-3 text-primary">
                                <i className="fa-solid fa-check" /> Včetně úvodního odstavce
                            </p>

                            {currentUser && <> <hr />
                                <div className="row mb-3 position-relative">
                                    <div className="col-12 col-lg-6">
                                        <SelectBox
                                            defaultValue="Nevybráno"
                                            label="Přiřadit k webu"
                                            options={websitesOptions}
                                            value={websiteOption}
                                            onChange={(val) => setWebsiteOption(val)}
                                        />
                                    </div>

                                    {selectedWebsite && selectedWebsite.wordpressSetuped && <>
                                        <div className="col-12 col-lg-6">

                                            <div className="position-relative">
                                                <MultiSelectBox
                                                    label="Kategorie"
                                                    options={wpCategoriesOptions}
                                                    onChange={(ids) => setWordpressCategoriesIds(ids.map(s => parseInt(s)))}
                                                    selected={selectedCategoriesOptions}
                                                    uid=""
                                                    required={false}
                                                />

                                                <div
                                                    className="position-absolute"
                                                    style={{ top: 0, left: '63px' }}
                                                >
                                                    <span
                                                        className="text-primary clickable"
                                                        style={{ fontSize: '9px' }}
                                                        onClick={() => setShowWebWpUpdateCategoriesModal(true)}
                                                    >
                                                        Aktualizovat
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </>}

                                    {addNewWebsite && <>
                                        <div className="col-12 col-lg-6">
                                            <InputText
                                                label="Název webu"
                                                value={newWebsiteName}
                                                onChange={(val) => setNewWebsiteName(val)}
                                                required
                                            />
                                        </div>
                                    </>}
                                </div>
                            </>}

                            <hr />

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <p className="text-primary">Cena za 1 článek cca&nbsp;
                                        {isFree ?
                                            <>
                                                <b><s>{PricingHelper.getEstimatedPrice(1, paragraphCount, true, language, Currency.Czk)} Kč</s></b>
                                            </>
                                            :
                                            <><b>{PricingHelper.getEstimatedPrice(1, paragraphCount, true, language, Currency.Czk)} Kč</b> (vyšší odhad)</>}
                                    </p>

                                    {!currentUser && <p className="text-danger"><b>První článek máte ZDARMA!</b></p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    {topicsArr.length >= 2 && <>
                                        <p className="text-primary">
                                            {topicsArr.length} článků bude stát&nbsp;
                                            <b>zhruba {PricingHelper.getEstimatedPrice(topicsArr.length, paragraphCount, true, language, Currency.Czk)} Kč</b>
                                        </p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portlet">
                        <div className="portlet-body">
                            {!currentUser &&
                                <>
                                    <div className="row mb-4">
                                        <div className="col-12 col-lg-6">
                                            <InputEmail
                                                ref={emailRef}
                                                label="Váš e-mail"
                                                value={email}
                                                onChange={(val) => setEmail(val)}
                                                required
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <InputText
                                                label="Název vašeho webu"
                                                value={newWebsiteName}
                                                onChange={(val) => setNewWebsiteName(val)}
                                                placeholder="nepovinné"
                                            />
                                        </div>
                                    </div>
                                </>}

                            {!currentUser && <CheckBox
                                checked={agreeTerms}
                                htmlLabel={<>Souhlasím s <a href="/documents/vop.pdf" target="_blank">podmínkami služby</a></>}
                                onChange={(val) => setAgreeTerms(val)}
                            />}

                            <FormErrorSummary
                                errors={errors}
                                className="mt-3"
                            />

                            <div className="text-center m-4">
                                <SubmitButton
                                    loading={submitLoading}
                                    className="btn-lg"
                                    loadingText="Odesílám Vaši objednávku..."
                                    onClick={onSubmit}
                                >
                                    <>
                                        <i className="fa-solid fa-arrow-right me-2"></i> {isFree ? "Vygenerovat článek" : "Zaplatit z kreditu"}
                                    </>
                                </SubmitButton>
                                {!isFree && <p className="text-secondary mt-3">Za 1 NS zaplatíte 3,6 Kč (tj. 1800 znaků)</p>}
                                <p className="text-primary mt-3">Následně můžete <b>exportovat do Wordpressu, JSON a XML</b>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))