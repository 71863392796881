import { Button, Modal } from "react-bootstrap";

interface IProps {
    onHide(): void,
}

export const HowToWriteHeadingsModal = (props: IProps) => {

    const { onHide } = props;

    return (
        <Modal size="lg" centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Jak psát nadpisy</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ul className="list-unstyled" style={{ fontSize: '16px' }}>
                    <li>
                        <span className="text-success">
                            <i className="fa-solid fa-check me-2" /> Pište celé nadpisy finálních článků</span> <span className="text-danger">(tzn. nepište témata)
                        </span>
                    </li>

                    <li className="mt-2">
                        <span className="text-success">
                            <i className="fa-solid fa-check me-2" /> Stylem nadpisů ovlivňujete tón výsledného textu
                        </span>
                    </li>

                    <li className="mt-2">
                        <span className="text-success">
                            <i className="fa-solid fa-check me-2" /> Co máte v nadpisu, to se objeví v textu
                        </span>
                    </li>
                </ul>

                <h5 className="mt-5">Příklady</h5>
                <hr />
                <div style={{ fontSize: '12px' }}>

                    <p>
                        <span className="text-danger">
                            <i className="fa-solid fa-times" /> Elon Musk
                        </span>
                    </p>

                    <p>
                        <span className="text-success">
                            <i className="fa-solid fa-check" /> Elon Musk, kariéra a osobní život
                        </span>
                    </p>

                    <hr />

                    <p>
                        <span className="text-danger">
                            <i className="fa-solid fa-times" /> Robotický vysavač
                        </span>
                    </p>

                    <p>
                        <span className="text-success">
                            <i className="fa-solid fa-check" /> Robotický vysavač, ušetří Vám čas a zajistí čistotu
                        </span>
                    </p>
      
                    <hr />

                    <p>
                        <span className="text-danger">
                            <i className="fa-solid fa-times" /> Itálie jídlo
                        </span>
                    </p>

                    <p>
                        <span className="text-success">
                            <i className="fa-solid fa-check" /> Nejlepší jídla v Itálii, která nesmíte minout
                        </span>
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)
}