import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import IWebsite from "../../Interfaces/IWebsite";
import WebsitesApiService from "../../Services/WebsitesApiService"
import { ValidationHelper } from "../../Utils/ValidationHelper";
import InputText from "../Inputs/InputText";
import InputUrl from "../Inputs/InputUrl";
import { SubmitButton } from "../Inputs/SubmitButton";
import { WebsiteWordpressSetupTutorialModal } from "./WebsiteWordpressSetupTutorialModal";

interface IProps {
    website: IWebsite,
}

const websiteApiService = new WebsitesApiService();

export const WebsiteWpSetup = (props: IProps) => {

    const website = props.website;

    const [wpUserName, setWpUserName] = useState(website.wordpressUserName);
    const userNameRef = useRef<InputText>(null);

    const [wpPass, setWpPass] = useState(website.wordpressPassword);
    const passRef = useRef<InputText>(null);

    const [domain, setDomain] = useState(website.domain);
    const domainRef = useRef<InputUrl>(null);

    const [showSetupTutorial, setShowSetupTutorial] = useState(false);


    useEffect(() => {
        return () => {
            websiteApiService.cancelRequests();
        }
    }, [])

    const onConnect = () => {
        const inputs = [userNameRef, passRef, domainRef];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            websiteApiService.wordpressSetup(website.id, wpUserName!, wpPass!, domain!)
                .then(data => {
                    if (data.success) {
                        toast.success("Propojeno");
                    }
                    else {
                        toast.error("Propojení se nezdařilo");
                    }
                })
        }
    }

    const onUnconnect = () => {
        websiteApiService.removeWpSetup(website.id)
            .then(data => {
                if (data.success) {
                    toast.success("Odpojeno");
                }
            })
    }

    if (website.wordpressSetuped) {
        return <>
            <div className="portlet">
                <div className="portlet-header portlet-header-bordered mt-2">
                    <h3 className="portlet-title"><i className="fa-solid fa-globe me-2"></i>Wordpress Web - <b>{website?.name}</b> - Export</h3>

                    <button
                        onClick={onUnconnect}
                        className="btn btn-label-danger float-end"
                    >
                        <i className="fa-solid fa-xmark me-2"></i>Zrušit propojení
                    </button>
                </div>
                <div className="portlet-body">

                </div>
            </div>
        </>
    }

    return <>
        <div className="portlet">
            <div className="portlet-header portlet-header-bordered mt-2">
                <h3 className="portlet-title"><i className="fa-solid fa-globe me-2"></i>Web - <b>{website?.name}</b> - Wordpress Export</h3>
            </div>
            <div className="portlet-body">
                <p><b>K propojení budete potřebovat uživatelské jméno a vygenerované heslo pro aplikace.</b></p>

                <button
                    className="btn btn-label-primary"
                    onClick={() => setShowSetupTutorial(true)}>
                    <i className="fa-solid fa-circle-info me-2"></i>Zobrazit nápovědu
                </button>

                {showSetupTutorial &&
                    <WebsiteWordpressSetupTutorialModal onHide={() => setShowSetupTutorial(false)} />}

                <div className="row mt-4">
                    <div className="col-12 col-md-4">
                        <InputText
                            ref={userNameRef}
                            placeholder=""
                            label="Přihlašovací jméno nebo email"
                            value={wpUserName}
                            onChange={(val) => setWpUserName(val)}
                            required
                        />
                    </div>

                    <div className="col-12 col-md-4">
                        <InputText
                            ref={passRef}
                            placeholder="např. 4TRe b8wY 5Y6Z t1Z5 Aann c7rP"
                            label="Autorizační heslo Wordpressu (pro aplikace)"
                            value={wpPass}
                            onChange={(val) => setWpPass(val)}
                            required
                        />
                    </div>

                    <div className="col-12 col-md-4">
                        <InputUrl
                            ref={domainRef}
                            placeholder=""
                            label="Url webu"
                            value={domain}
                            onChange={(val) => setDomain(val)}
                            required
                        />
                    </div>
                </div>

                <SubmitButton
                    className="mt-2"
                    onClick={onConnect}
                >
                    <>Propojit</>
                </SubmitButton>
            </div>
        </div>
    </>
}