import { Modal } from "react-bootstrap"
import { Bag, BookHalf, FileText } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

interface IProps {
    onHide(): void,
}

export const SelectTypeArticleToGenerateModal = (props: IProps) => {

    const { onHide } = props;

    return (
        <Modal size={"xl"} centered show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Jaký typ článku si přejete generovat?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-4 text-center card-select">
                        <NavLink to="/generate/articles" onClick={onHide}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="my-3">
                                        <FileText size={40} />
                                    </div>
                                    <h3>Klasické články</h3>
                                    <hr />
                                    <p>
                                        Klasický článek na jakékoli téma
                                    </p>
                                    <p className="text-primary">
                                        např. Zdravotní benefity kokosové vody
                                    </p>

                                    <button className="btn btn-primary w-100 mt-4">
                                        Pokračovat
                                    </button>
                                </div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-12 col-lg-4 text-center card-select mt-4 mt-lg-0">
                        <NavLink to="/generate/products-articles" onClick={onHide}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="my-3">
                                        <Bag size={40} />
                                    </div>
                                    <h3>Produktové články</h3>
                                    <hr />
                                    <p>
                                        Článek popisující konkrétní produkt
                                    </p>
                                    <p className="text-primary">
                                        např. Apple iPhone 14 Pro 128 GB
                                    </p>

                                    <button className="btn btn-primary w-100 mt-4">
                                        Pokračovat
                                    </button>
                                </div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-12 col-lg-4 text-center card-select mt-4 mt-lg-0">
                        <NavLink to="/generate/stories-articles" onClick={onHide}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="my-3">
                                        <BookHalf size={40} />
                                    </div>
                                    <h3>Příběhové články</h3>
                                    <hr />
                                    <p>
                                        Zaujměte své čtenáře okouzlujícím příběhem
                                    </p>

                                    <p className="text-primary">
                                        např. Jak drak dobyl Rusko a zachránil Ukrajinu
                                    </p>

                                    <button className="btn btn-primary w-100 mt-4">
                                        Pokračovat
                                    </button>
                                </div>
                            </div>
                        </NavLink>
                    </div>

                </div>
            </Modal.Body>
        </Modal>)
}