import IOptionData from '../Interfaces/IOptionData';
import { filter, replace } from 'lodash';

export default class StringHelper {

    public static separateThousands(number: any): string {
        if (!number && number !== 0) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;")
    }

    public static separateThousandsNoHtml(number: any): string {
        if (!number && number !== 0) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    public static escapeHtml(unsafe: string) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }


    public static replaceAllSpacesToNonBreakingSpace(text: string) {
        for (var i = 0; i < text.length; i++) {

            text = replace(text, ' ', '&nbsp;');
        }
        return text;
    }

    public static convertToOptionData(values: number[], list: IOptionData[]): IOptionData[] {
        return filter(list, item => {
            if ((values || []).includes(parseInt(item.value))) {
                return true;
            }
            return false;
        });
    }
}