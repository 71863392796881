import { AppLayout } from './Components/Layout/AppLayout';
import { Provider } from 'mobx-react';

import {
    Outlet
} from "react-router-dom";
import CurrentUserStore from './Stores/CurrentUserStore';
import OrderStore from './Stores/OrderStore';
import AccountTransactionStore from './Stores/AccountTransactionStore';
import ArticleStore from './Stores/ArticleStore';
import WebsiteStore from './Stores/WebsiteStore';

export const currentUserStore: CurrentUserStore = new CurrentUserStore();
export const orderStore: OrderStore = new OrderStore();
export const accountTransactionStore: AccountTransactionStore = new AccountTransactionStore();
export const articleStore: ArticleStore = new ArticleStore();
export const websiteStore: WebsiteStore = new WebsiteStore();

export const currentFrontEndVersion = 4;

function App() {
    return (
        <>
            <Provider
                currentUserStore={currentUserStore}
                orderStore={orderStore}
                accountTransactionStore={accountTransactionStore}
                articleStore={articleStore}
                websiteStore={websiteStore}
            >
                <AppLayout>
                    <Outlet />
                </AppLayout>
            </Provider>
        </>
    );
}
export default App;