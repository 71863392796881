import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IWebsite from "../../Interfaces/IWebsite";
import WebsitesApiService from "../../Services/WebsitesApiService";
import { LoadingScreen } from "../Others/LoadingScreen";

interface IProps {
    onHide(): void,
    website: IWebsite,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteWordpressUpdateCategoriesModal = (props: IProps) => {

    const { onHide, website } = props;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        websitesApiService.updateWpCategories(website.id)
            .then(data => {
                if (data.success) {
                    toast.success("Kategorie byly aktualizovány");
                }
                else {
                    toast.error("Nepodařilo se načíst kategorie");
                }
                setLoading(false);
                onHide();
            })

        return (() => {
            websitesApiService.cancelRequests();
        })
    }, [])

    return (
        <Modal centered={true} show={true} onHide={() => { }}>
            <Modal.Body>
                {loading && <LoadingScreen title="Probíhá aktualizace kategorií..." />}
            </Modal.Body>
        </Modal>)
}