import { inject, observer } from "mobx-react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import CurrentUserStore from '../../Stores/CurrentUserStore';
import { SelectTypeArticleToGenerateModal } from "../Generator/SelectTypeArticleToGenerateModal";
interface IProps {
    currentUserStore?: CurrentUserStore,
    onShowNav(show: boolean): void,
}

export const Header = inject('currentUserStore')(observer((props: IProps) => {
    const navigate = useNavigate();

    const currentUser = props.currentUserStore?.user;

    const [showSelectArticleType, setShowSelectArticleType] = useState(false);

    return (
        <>
            {showSelectArticleType && <SelectTypeArticleToGenerateModal
                onHide={() => setShowSelectArticleType(false)}
            />}

            <div className="header">
                {/* BEGIN Header Holder */}
                <div className="header-holder header-holder-desktop">
                    <div className="header-container container-fluid g-5">
                        <div className="header-wrap">
                            <h4 onClick={() => navigate("/")} className="header-brand clickable"><i className="fa-solid fa-feather me-2"></i>Clonty AI Writer</h4>
                        </div>

                        <i className="header-divider" />
                        <div className="header-wrap header-wrap-block justify-content-start">
                            {/* BEGIN Nav */}
                            <ul className="nav nav-pills" data-toggle="header-nav">
                                {!currentUser && <li className="nav-item" data-toggle="header-tab">
                                    <NavLink
                                        to='/'
                                        className="nav-link"
                                    >
                                        O aplikaci
                                    </NavLink>
                                </li>}

                                <li className="nav-item" data-toggle="header-tab">
                                    <span
                                        onClick={() => setShowSelectArticleType(true)}
                                        className="nav-link clickable"
                                    >
                                        <i className="fa-solid fa-rocket me-1"></i> Generovat články
                                    </span>
                                </li>

                                {currentUser && <>
                                    <li className="nav-item" data-toggle="header-tab">
                                        <NavLink
                                            to='/orders'
                                            className="nav-link"
                                        >
                                            Objednávky
                                        </NavLink>
                                    </li>

                                    <li className="nav-item" data-toggle="header-tab">
                                        <NavLink
                                            to='/websites'
                                            className="nav-link"
                                        >
                                            Weby
                                        </NavLink>
                                    </li>

                                    <li className="nav-item" data-toggle="header-tab">
                                        <NavLink
                                            to='/articles'
                                            className="nav-link"
                                        >
                                            Články
                                        </NavLink>
                                    </li>
                                </>}

                                <li className="nav-item" data-toggle="header-tab">
                                    <NavLink
                                        to='/examples'
                                        className="nav-link"
                                    >
                                        {!currentUser ? "Příklady a ukázky" : "Ukázky"}
                                    </NavLink>
                                </li>

                                <li className="nav-item" data-toggle="header-tab">
                                    <NavLink
                                        to='/faq'
                                        className="nav-link"
                                    >
                                        FAQ
                                    </NavLink>
                                </li>

                                <li className="nav-item" data-toggle="header-tab">
                                    <NavLink
                                        to='/contact'
                                        className="nav-link"
                                    >
                                        Kontakt
                                    </NavLink>
                                </li>
                            </ul>
                            {/* END Nav */}
                        </div>
                        <div className="header-wrap hstack gap-2">

                            {/*   <button
                                className="btn btn-label-primary btn-icon"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvas-todo"
                            >
                                <i className="far fa-calendar-alt" />
                            </button>
                            <button
                                className="btn btn-label-primary btn-icon"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvas-settings"
                            >
                                <i className="far fa-list-alt" />
                            </button>*/}
                            {/* BEGIN Dropdown */}

                            {/*!currentUser &&
                                <button className="btn btn-icon btn-label-primary  btn-icon">
                                   Kč
                                </button>*/}

                            <div className="dropdown">

                                {currentUser ? <button
                                    onClick={() => navigate("/account")}
                                    className="btn btn-flat-primary widget13 userLogged"
                                    data-bs-toggle="dropdown"
                                >
                                    <div className="widget13-text">
                                        <strong>{currentUser.name}</strong>
                                    </div>
                                    {/* BEGIN Avatar */}
                                    <div className="avatar avatar-info widget13-avatar bg-success">
                                        <div className="avatar-display">
                                            <i className="fa fa-user-alt" />
                                        </div>
                                    </div>
                                    {/* END Avatar */}
                                </button> :

                                    <button
                                        onClick={() => navigate("/account/login")}
                                        className="btn btn-flat-primary widget13"
                                        data-bs-toggle="dropdown"
                                    >
                                        <div className="widget13-text">
                                            <strong>Nepřihlášen</strong>
                                        </div>
                                        {/* BEGIN Avatar */}
                                        <div className="avatar avatar-info widget13-avatar bg-primary">
                                            <div className="avatar-display">
                                                <i className="fa fa-user-alt" />
                                            </div>
                                        </div>
                                        {/* END Avatar */}
                                    </button>}
                            </div>
                            {/* END Dropdown */}
                        </div>
                    </div>
                </div>
                {/* END Header Holder */}
                {/* BEGIN Header Holder */}
                <div className="header-holder header-holder-mobile">
                    <div className="header-container container-fluid g-5">
                        <div className="header-wrap header-wrap-block justify-content-start">
                            <h4
                                onClick={() => navigate("/")}
                                className="header-brand clickable"
                            >
                                Clonty AI Writer
                            </h4>
                        </div>
                        <div className="header-wrap hstack gap-2">
                            <button
                                className="btn btn-label-primary btn-icon"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvas-navigation"
                                onClick={() => props.onShowNav(true)}
                            >
                                <i className="fa fa-bars"></i>
                            </button>

                            <div className="dropdown">
                                {/*currentUser ?
                                    <button
                                        className="btn btn-flat-primary widget13 userLogged"
                                        data-bs-toggle="dropdown"
                                        onClick={() => navigate("/account")}
                                    >
                                        <div className="widget13-text">
                                            <strong>Můj účet</strong>
                                        </div>

                                        <div className="avatar avatar-info widget13-avatar bg-success">
                                            <div className="avatar-display">
                                                <i className="fa fa-user-alt" />
                                            </div>
                                        </div>
                                    </button>
                                    :
                                    <button
                                        onClick={() => navigate("/account/login")}
                                        className="btn btn-flat-primary widget13"
                                        data-bs-toggle="dropdown"
                                    >
                                        <div className="widget13-text">
                                            <strong>Nepřihlášen</strong>
                                        </div>

                                        <div className="avatar avatar-info widget13-avatar bg-primary">
                                            <div className="avatar-display">
                                                <i className="fa fa-user-alt" />
                                            </div>
                                        </div>
                                    </button>*/}

                                <div className="dropdown-menu dropdown-menu-wide dropdown-menu-end dropdown-menu-animated overflow-hidden py-0">
                                    {/* BEGIN Portlet */}
                                    <div className="portlet border-0">
                                        <div className="portlet-header bg-primary rounded-0">
                                            {/* BEGIN Rich List Item */}
                                            <div className="rich-list-item w-100 p-0">
                                                <div className="rich-list-prepend">
                                                    {/* BEGIN Avatar */}
                                                    <div className="avatar avatar-label-light avatar-circle">
                                                        <div className="avatar-display">
                                                            <i className="fa fa-user-alt" />
                                                        </div>
                                                    </div>
                                                    {/* END Avatar */}
                                                </div>
                                                <div className="rich-list-content">
                                                    <h3 className="rich-list-title text-white">
                                                        Charlie Stone
                                                    </h3>
                                                    <span className="rich-list-subtitle text-white">
                                                        admin@blueupcode.com
                                                    </span>
                                                </div>
                                                <div className="rich-list-append">
                                                    <span className="badge badge-label-light fs-6">
                                                        9+
                                                    </span>
                                                </div>
                                            </div>
                                            {/* END Rich List Item */}
                                        </div>
                                        <div className="portlet-body p-0">
                                            {/* BEGIN Grid Nav */}
                                            <div className="grid-nav grid-nav-flush grid-nav-action grid-nav-no-rounded">
                                                <div className="grid-nav-row">
                                                    <a href="#" className="grid-nav-item">
                                                        <div className="grid-nav-icon">
                                                            <i className="far fa-address-card" />
                                                        </div>
                                                        <span className="grid-nav-content">Profile</span>
                                                    </a>
                                                    <a href="#" className="grid-nav-item">
                                                        <div className="grid-nav-icon">
                                                            <i className="far fa-comments" />
                                                        </div>
                                                        <span className="grid-nav-content">Messages</span>
                                                    </a>
                                                    <a href="#" className="grid-nav-item">
                                                        <div className="grid-nav-icon">
                                                            <i className="far fa-clone" />
                                                        </div>
                                                        <span className="grid-nav-content">Activities</span>
                                                    </a>
                                                </div>
                                                <div className="grid-nav-row">
                                                    <a href="#" className="grid-nav-item">
                                                        <div className="grid-nav-icon">
                                                            <i className="far fa-calendar-check" />
                                                        </div>
                                                        <span className="grid-nav-content">Tasks</span>
                                                    </a>
                                                    <a href="#" className="grid-nav-item">
                                                        <div className="grid-nav-icon">
                                                            <i className="far fa-sticky-note" />
                                                        </div>
                                                        <span className="grid-nav-content">Notes</span>
                                                    </a>
                                                    <a href="#" className="grid-nav-item">
                                                        <div className="grid-nav-icon">
                                                            <i className="far fa-bell" />
                                                        </div>
                                                        <span className="grid-nav-content">
                                                            Notification
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            {/* END Grid Nav */}
                                        </div>
                                        <div className="portlet-footer portlet-footer-bordered rounded-0">
                                            <button className="btn btn-label-danger">Sign out</button>
                                        </div>
                                    </div>
                                    {/* END Portlet */}
                                </div>
                            </div>
                            {/* END Dropdown */}
                        </div>
                    </div>
                </div>
                {/* END Header Holder */}
            </div>
        </>
    )
}))