import { CloudUploadFill, ImageFill, PencilFill, Youtube } from "react-bootstrap-icons"
import { HomePageFooterCard } from "../Components/AboutApp/HomePageFooterCard"
import { HomePageWelcomeCard } from "../Components/AboutApp/HomePageWelcomeCard"
import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"

export const Homepage = () => {
    return <>
        <CustomDocumentTitle title="Platforma pro generování">
            <HomePageWelcomeCard />

            <div className="row mt-4">
                <div className="col-12 col-lg-6">
                    <div className="portlet homepage-card">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><PencilFill /> AI napíše články za Vás</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Zadáte nadpisy článků a aplikace pomocí AI napíše články včetně podnadpisů a HTML formátování.</b></p>
                            <div className="text-center">
                                <img src="/Templates/Images/Homepage/step1-min.png" className="img-fluid hp-image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="portlet homepage-card">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><ImageFill/> Včetně náhledových obrázků</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Obrázky dělají články atraktivnější</b>, proto ke každému článku se aplikace snaží nalézt nejvhodnější obrázek z fotobank nebo internetu.</p>
                            <div className="text-center">
                                <img src="/Templates/Images/Homepage/images-min.png" className="img-fluid hp-image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="portlet homepage-card">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><CloudUploadFill /> Chytrý export do Wordpressu, JSON a XML</h3>
                        </div>
                        <div className="portlet-body">
                            <p>V případě Wordpressu se články umí přirozeně naplánovat tak, <b>abych jejich zveřejňování bylo přirozené</b>.</p>
                            <div className="text-center">
                                <img src="/Templates/Images/Homepage/export-min.png" className="img-fluid hp-image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="portlet homepage-card">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><Youtube /> Vložené Youtube video do článků</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Do produktových článků umí aplikace vložit video z Youtube</b>. Takto obohacený textový obsah má pozitivní vliv na SEO.</p>
                            <div className="text-center">
                                <img src="/Templates/Images/Homepage/youtube-min.png" className="img-fluid hp-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HomePageFooterCard />
        </CustomDocumentTitle>
    </>
}