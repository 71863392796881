import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { currentUserStore } from "../App";
import { IBilling } from "../Interfaces/IBilling";
import IResponseData from "../Interfaces/IResponseData";
import IUser from "../Interfaces/IUser";
import BaseApiService from "./BaseApiService";

export default class CurrentUserApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getCurrentUser(): Promise<IUser> {
        return new Promise((resolve, reject): any => {
            this.getRequest('user', {})
                .then(response => {
                    const user: IUser = response.data.records.currentUser;
                    return resolve(user);
                })
                .catch((error: AxiosError) => {
                    return reject(error);
                });
        })
    }

    public logout(): Promise<boolean> {
        return this.postRequest('user/logout', {}, {})
            .then(response => {
                if (response.data.success) {
                    currentUserStore?.logout();
                    toast.success("Byli jste odhlášeni");
                    return true;
                }
                return false;
            })
    }

    public login(email: string, password: string): Promise<AxiosResponse<IResponseData>> {
        return this.postRequest('/user/login', {
            email: email,
            password: password,
        }, {});
    }

    public register(email: string, password: string) {
        return this.postRequest('/user/register', {
            email: email,
            password: password,
        }, {})
    }

    public sendResetPasswordEmail(email: string,) {
        return this.postRequest('/user/reset-password/email', {
            email: email,
        }, {})
    }

    public changePassword(currentPassword: string, newPassword: string) {
        return this.postRequest('/user/change-password', {
            currentPassword: currentPassword,
            newPassword: newPassword
        }, {})
    }

    public newsletterSubscribe() {
        return this.postRequest('/user/newsletter', {}, {})
    }

    public newsletterUnsubscribe() {
        return this.deleteRequest('/user/newsletter', {}, {})
    }

    public getBillingByIc(ic: string,): Promise<IResponseData> {
        return this.postRequest('/user/billing/by-ic', {  }, { ic })
            .then(response => {
                return response.data;
            })
    }

    public editBilling(billing: IBilling): Promise<IResponseData> {
        return this.putRequest('/user/billing',  billing , {})
            .then(response => {
                return response.data;
            })
    }

    public resetPassword(email: string, token: string, password: string): Promise<IResponseData> {
        return this.postRequest('/user/reset-password', {
            email: email,
            token: token,
            password,
        }, {})
            .then(response => {
                return response.data;
            })
    }

    public sendVerifyEmail(): Promise<IResponseData> {
        return this.postRequest('/user/verify-email/send-email', {} , {})
            .then(response => {
                return response.data;
            })
    }

    public verifyEmail(email: string, token: string): Promise<IResponseData> {
        return this.postRequest('/user/verify-email', { email: email, token: token }, {})
            .then(response => {
                return response.data;
            })
    }

    public deleteAccount(): Promise<IResponseData> {
        return this.deleteRequest('/user', { }, {})
            .then(response => {
                return response.data;
            })
    }
}