import { useRef, useState } from "react"
import { toast } from "react-toastify";
import IArticle from "../../Interfaces/IArticle";
import ArticlesApiService from "../../Services/ArticlesApiService";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import InputText from "../Inputs/InputText";
import SelectBox from "../Inputs/SelectBox";

interface IProps {
    websiteId: string,
    onCreated(article: IArticle): void,
}

const articlesApiService = new ArticlesApiService();

export const CreateArticleConceptTr = (props: IProps) => {

    const [title, setTitle] = useState("");
    const titleRef = useRef<InputText>(null);

    const [sectionsCount, setSectionsCount] = useState(3);
    const sectionsCountRef = useRef<SelectBox>(null);

    const onSubmit = () => {
        const inputs = [titleRef, sectionsCountRef];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            articlesApiService.createConcepts({ titles: [title], websiteId: props.websiteId, sectionsCount: sectionsCount })
                .then(data => {
                    if (data.success) {
                        const article = data.records.articles.items[0];
                        props.onCreated(article);
                        toast.success("Koncept vytvořen");

                        setTitle("");
                    }
                })
        }
    }

    return <>
        <tr>
            <td>
                <InputText
                    ref={titleRef}
                    label=""
                    value={title}
                    required
                    onChange={(val) => setTitle(val)}
                    placeholder="Zadejte nadpis článku"
                />
            </td>

            <td>
                <SelectBox
                    ref={sectionsCountRef}
                    options={GeneratorHelper.getArticleRangeOptions()}
                    value={sectionsCount}
                    required
                    onChange={(val) => setSectionsCount(val)}
                    id="subtitlesCount"
                />
            </td>

            <td>
                <button
                    className="btn btn-label-primary"
                    onClick={() => onSubmit()}
                >
                    <i className="fa-solid fa-plus me-2"></i>Přidat
                </button>
            </td>
        </tr>
    </>
}