import CurrentUserApiService from "../../Services/CurrentUserApiService"
import React, { useEffect, useRef, useState } from "react";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import IInputField from "../../Interfaces/IInputField";
import { NavLink, useNavigate } from "react-router-dom";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import IFormError from "../../Interfaces/IFormError";
import InputPassword from "../Inputs/InputPassword";
import { toast } from "react-toastify";
import InputEmail from "../Inputs/InputEmail";

interface IProps {
    code: string,
}

const currentUserApiService = new CurrentUserApiService();

export const ResetPasswordForm = (props: IProps) => {

    const { code } = props;

    const navigate = useNavigate();
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [email, setEmail] = useState("");

    const [errors, setErrors] = useState<IFormError[]>([]);

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    }, []);

    const newPassword1Ref = useRef<InputPassword>(null);
    const newPassword2Ref = useRef<InputPassword>(null);
    const emailRef = useRef<InputEmail>(null);

    var arr: React.RefObject<IInputField>[] = [emailRef, newPassword1Ref, newPassword2Ref];

    const onSubmit = () => {
        const isValid = ValidationHelper.validateInputs(arr);

        if (newPassword1 !== newPassword2) {
            setErrors([{ message: "Hesla se neshodují", key: "" }]);
            return;
        }

        if (isValid) {
            currentUserApiService.resetPassword(email, code, newPassword1)
                .then(data => {
                    if (data.success) {
                        toast.success("Heslo bylo nastaveno. Můžete se přihlásit");
                        navigate("/account/login");
                    }
                    else {
                        setErrors(data.formErrors);
                    }
                })
        }
    }

    return (
        <>
            <InputEmail
                ref={emailRef}
                label="E-mail"
                value={email}
                required
                onChange={(val) => setEmail(val)}
            />

            <InputPassword
                ref={newPassword1Ref}
                label="Zvolte si nové heslo"
                value={newPassword1}
                onChange={(val) => setNewPassword1(val)}
                required
            />

            <InputPassword
                ref={newPassword2Ref}
                label="Nové heslo"
                value={newPassword2}
                onChange={(val) => setNewPassword2(val)}
                required
                minLength={8}
            />

            <FormErrorSummary
                errors={errors}
            />

            <button
                className="btn btn-primary mt-3 w-100"
                onClick={onSubmit}
            >
                Změnit heslo
            </button>

            <p className="text-center mt-3">
                <NavLink to="/account/forgotten-password">Zapomenuté heslo</NavLink>
            </p>
        </>)
}