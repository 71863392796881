import { inject, observer } from "mobx-react";
import { useState } from "react";
import { ArticleState } from "../../Enums/ArticleState";
import IArticle from "../../Interfaces/IArticle";
import OrderStore from "../../Stores/OrderStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import { ArticleDetailModal } from "../Articles/ArticleDetailModal";
import { ArticleStateLabel } from "../Articles/ArticleStateLabel";
import { ButtonViewDetail } from "../Inputs/ButtonViewDetail";
import { ArticleTypeIcon } from "./ArticleTypeIcon";

interface IProps {
    article: IArticle,
    orderStore?: OrderStore,
    websiteStore?: WebsiteStore,
}

export const ArticleItem = inject('orderStore', 'websiteStore')(observer((props: IProps) => {

    const { article } = props;

    const [showDetail, setShowDetail] = useState(false);

    const order = article.orderId ? props.orderStore?.getById(article.orderId) : undefined;

    return <>
        <tr key={article.id}>

            <td>
                <ArticleTypeIcon article={article} />
                {article.title}
            </td>
             
            <td>
                <ArticleStateLabel
                    error={article.error}
                    orderState={order ? order.state : undefined}
                    articleState={article.state}
                    cancellationReason={article.cancellationReason}
                />
            </td>

            <td>{article.contentLength || '-'} znaků / {article.wordsCount || '-'} slov</td>

            <td>
                {article.state === ArticleState.Finished &&
                    <ButtonViewDetail
                        onClick={() => setShowDetail(true)} />
                }

                {showDetail &&
                    <ArticleDetailModal
                        article={article}
                        onHide={() => setShowDetail(false)}
                    />}
            </td>
        </tr>
    </>
}));