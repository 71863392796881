import IArticle from "../../Interfaces/IArticle";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import InputText from "../Inputs/InputText";
import SelectBox from "../Inputs/SelectBox";

interface IProps {
    article: IArticle,
    onChange(article: IArticle): void,
    onCancel(): void,
}

export const ArticleConceptItem = (props: IProps) => {

    const { article } = props;

    const onChange = (key: string, value: any) => {
        props.onChange({
            ...article,
            [key]: value
        })
    }

    return <>
        <tr key={article.id}>

            <td>
                <InputText
                    label=""
                    value={article.title}
                    required
                    onChange={(val) => onChange("title", val)}
                />
            </td>

            <td>
                <SelectBox
                    options={GeneratorHelper.getArticleRangeOptions()}
                    value={article.sectionsCount}
                    required
                    onChange={(val) => onChange("sectionsCount", val)}
                    id="subtitlesCount"
                />
            </td>

            <td>
                <button
                    className="btn btn-label-danger d-block"
                    onClick={() => props.onCancel()}
                >
                    <i className="fa-solid fa-trash me-2"></i>Zahodit
                </button>
            </td>
        </tr>
    </>
}