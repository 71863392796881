import { round } from "lodash";
import { Tab, Tabs } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nnfx } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Language } from "../../Enums/Language";
import { IExampleArticle } from "../../Interfaces/Articles/IExampleArticle";
import { PricingHelper } from "../../Utils/PricingHelper";
import { html_beautify }  from 'js-beautify';
import { Currency } from "../../Enums/Currency";
import { ArticleImageSourceDescription } from "../Articles/ArticleImageSourceDescription";

interface IProps {
    exampleArticle: IExampleArticle
}

export const ExampleGeneralArticle = (props: IProps) => {

    const { exampleArticle } = props;

    const estimatedPrice = PricingHelper.getEstimatedPrice(1, exampleArticle.paragraphCount || 0, exampleArticle.leadParagraph || true, Language.Czech, Currency.Czk);
    const realPrice = round(PricingHelper.getGeneralArticlePricePerChar(Currency.Czk) * exampleArticle.totalChars, 1);

    const beautifiedHtmlString = html_beautify(exampleArticle.content,
        { 
            indent_level: 0,
            wrap_line_length: 85,
            end_with_newline: false,
            indent_head_inner_html: false,
            max_preserve_newlines: 0,
            preserve_newlines: true,
            indent_size: 0,
            disabled: false,
            content_unformatted: [],
            extra_liners: ["h2", "p"]
        }
    );

    return <>
        <div className="portlet">
            <div className="portlet-header portlet-header-bordered mt-2">
                <h3 className="portlet-title">Ukázka článku "{exampleArticle.title}"</h3>
            </div>
            <div className="portlet-body">
                <Tabs defaultActiveKey="visual">
                    <Tab className="visualHtml exampleArticle" eventKey="visual" title="Vizuálně">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <td>Zadání - Nadpis</td>

                                        <td><b>{exampleArticle.title}</b></td>
                                    </tr>

                                    <tr>
                                        <td>Počet podnadpisů</td>

                                        <td><b>{exampleArticle.paragraphCount}</b></td>
                                    </tr>

                                    <tr>
                                        <td>Jazyk</td>

                                        <td><b>Čeština</b></td>
                                    </tr>

                                    <tr>
                                        <td>Cena odhad</td>

                                        <td><b>{estimatedPrice} Kč</b></td>
                                    </tr>

                                    <tr>
                                        <td>Výsledná cena</td>

                                        <td><b>{realPrice} Kč</b></td>
                                    </tr>

                                    <tr>
                                        <td>Vygenerované znaky / slova</td>

                                        <td><b>{exampleArticle.totalChars} znaků / {exampleArticle.wordsCount}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {exampleArticle.photoFileName &&
                            <>
                                <img
                                    className="img-fluid mt-2"
                                    src={`/Templates/Images/Articles/${exampleArticle.photoFileName}`}
                                />
                                {exampleArticle.imageSource &&
                                    <small>
                                        <span>Zdroj obrázku:</span> <ArticleImageSourceDescription
                                            source={exampleArticle.imageSource}
                                        />
                                    </small>}
                            </>
                        }
                        <h1>{exampleArticle.title}</h1>
                        
                        <div
                            className="mt-4"
                            dangerouslySetInnerHTML={{ __html: exampleArticle.content }}
                        ></div>
                    </Tab>
                    <Tab eventKey="raw" title="HTML">
                        <SyntaxHighlighter
                            language="htmlbars"
                            style={nnfx}
                            wrapLongLines={true}
                        >
                            {beautifiedHtmlString || ''}
                        </SyntaxHighlighter>
                    </Tab>
                </Tabs>
            </div>
        </div>
    </>
}