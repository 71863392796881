import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"

export const HowItWorksPage = () => {
    return <>
        <CustomDocumentTitle title="Jak to funguje">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Jak funguje Clonty Writer?</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">1. Zadáte názvy článků</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Hromadně zadáte nadpisy článků, které si přejete generovat</b> (max. 300 článků najednou). Některá témata budou mít přirozeně delší články, některá kratší.</p>
                            <img src="/Templates/Images/Steps/1.png" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">2. Parametry článku</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Pro všechny články jednotně nastavíte rozsah</b>. Můžete se orientovat dle počtu slov. Doporučujeme si taktéž vygenerovat úvodní odstavec.</p>
                            <img src="/Templates/Images/Steps/2.png" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">3. Platba</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Za články zaplatíte rychlým bankovním převodem</b>. Zaplatíte dle skutečného počtu vygenerovaných znaků prostřednictvím dobitého kreditu.</p>
                            <p><b>Vždy zaplatíte férově za počet vygenerovaných znaků.</b> Kredity, které se nevyužijí, budete moci využít na další články.</p>
                            <img src="/Templates/Images/Steps/3.png" className="img-fluid mt-4" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">4. Články se vygenerují</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Články se následně vygenerují</b>. Pokud generujete velké množství, stránku můžete zavřít a vrátit se později.</p>
                            <img src="/Templates/Images/Steps/5.png" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">5. Export</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Články si můžete stáhnout v JSON, XML nebo přímo exportovat do Wordpressu</b>.</p>
                            <img src="/Templates/Images/Steps/6.png" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">6. Sofistikovaný export do Wordpressu</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Export do WP jsme vyladili k dokonalosti.</b> Články si můžete nechat naplánovat tak, aby jejich četnost publikování byla přirozená.</p>
                            <img src="/Templates/Images/Steps/7.png" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}