import { inject, observer } from "mobx-react";
import { RefObject, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Country } from "../../Enums/Country";
import { IBilling } from "../../Interfaces/IBilling";
import IFormError from "../../Interfaces/IFormError";
import IInputField from "../../Interfaces/IInputField";
import CurrentUserApiService from "../../Services/CurrentUserApiService"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import { CountryHelper } from "../../Utils/CountryHelper";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import InputText from "../Inputs/InputText";
import SelectBox from "../Inputs/SelectBox";
import { SubmitButton } from "../Inputs/SubmitButton";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import { FormSuccessSummary } from "../Others/FormSuccessSummary";

interface IProps {
    currentUserStore?: CurrentUserStore
}

const currentUserApiService = new CurrentUserApiService();

export const BillingForm = inject('currentUserStore')(observer((props: IProps) => {

    useEffect(() => {

        return () => {
            currentUserApiService.cancelRequests();
        }
    }, [])

    const user = props.currentUserStore?.user;

    let usBilling = user?.billing;

    if (!usBilling) {
        usBilling = {
            ic: '',
            dic: '',
            city: '',
            companyName: '',
            country: Country.Czechia,
            street: '',
            streetRegistryNumber: '',
            zip: '',
        }
    }

    const [billing, setBilling] = useState<IBilling>(usBilling);

    const icRef = useRef<InputText>(null);
    const countryRef = useRef<SelectBox>(null);

    const companyNameRef = useRef<InputText>(null);
    const dicRef = useRef<InputText>(null);
    const streetRef = useRef<InputText>(null);
    const streetRegistryNumberRef = useRef<InputText>(null);
    const cityRef = useRef<InputText>(null);
    const zipRef = useRef<InputText>(null);

    const [loadBillingErrors, setLoadBillingErrors] = useState<IFormError[]>([]);
    const [loadBillingSuccessMessages, setLoadBillingSuccessMessages] = useState<string[]>([]);

    const [loadingGetByIc, setLoadingGetByIc] = useState(false);
    const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);

    const getByIc = () => {

        const isValidIc = ValidationHelper.validateInputs([icRef]);

        if (isValidIc) {
            setLoadingGetByIc(true);
            currentUserApiService.getBillingByIc(billing.ic)
                .then(data => {
                    if (data.success) {
                        const billing = data.others.get("billing");
                        setBilling(billing);
                        setLoadBillingSuccessMessages(["Zkontrolujte si načtené údaje a uložte změny"]);
                        setLoadBillingErrors([]);
                    }
                    else {
                        setLoadBillingErrors(data.formErrors);
                    }
                })
                .finally(() => {
                    setLoadingGetByIc(false);
                });
        }
    }

    const saveChanges = () => {

        const inputs: RefObject<IInputField>[] = [
            icRef,
            countryRef,
            companyNameRef,
            dicRef,
            streetRef,
            streetRegistryNumberRef,
            cityRef,
            zipRef,
        ];

        // validate

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            // save
            setLoadingSaveChanges(true);

            currentUserApiService.editBilling(billing)
                .then(data => {
                    if (data.success) {
                        toast.success("Změny uloženy");
                    }
                })
                .finally(() => {
                    setLoadingSaveChanges(false);
                })
        }
    }

    return <>

        <div className="row">
            <div className="col col-md-6">
                <InputText
                    ref={icRef}
                    label="IČ"
                    allowOnlyDigits={true}
                    value={billing.ic}
                    onChange={(val) => setBilling({ ...billing, ic: val })}
                    length={8}
                    required
                    name="ic"
                />
            </div>
            <div className="col col-md-6">
                <SelectBox
                    ref={countryRef}
                    label="Stát"
                    value={billing.country}
                    defaultValue={"Vyberte"}
                    options={CountryHelper.getBillingCountriesOptions()}
                    onChange={(val) => setBilling({ ...billing, country: val })}
                    required
                />
            </div>
        </div>

        {billing.country === Country.Czechia &&
            <>
                <FormErrorSummary
                    errors={loadBillingErrors}
                />

                <FormSuccessSummary
                    messages={loadBillingSuccessMessages}
                />

                <SubmitButton
                    loading={loadingGetByIc}
                    className="mt-3"
                    onClick={getByIc}
                >
                    <>
                        <i className="fa-solid fa-wand-magic-sparkles me-2"></i>Načíst dle IČ
                    </>
                </SubmitButton>
                <hr />
            </>}

        <div className="row">
            <div className="col-12 col-sm-6 col-md-6">
                <InputText
                    ref={companyNameRef}
                    label="Název společnosti / OSVČ"
                    value={billing.companyName}
                    onChange={(val) => setBilling({ ...billing, companyName: val })}
                    required
                    name="company"
                />
            </div>

            <div className="col-6  col-sm-6 col-md-6">
                <InputText
                    ref={dicRef}
                    label="DIČ"
                    value={billing.dic}
                    onChange={(val) => setBilling({ ...billing, dic: val })}
                    name="vat_id"
                />
            </div>

            <div className="col-6 col-sm-6 col-md-6">
                <InputText
                    ref={streetRef}
                    label="Ulice"
                    value={billing.street}
                    onChange={(val) => setBilling({ ...billing, street: val })}
                    required
                    name="street"
                />
            </div>
            <div className="col-6 col-sm-6 col-md-6">
                <InputText
                    ref={streetRegistryNumberRef}
                    label="Číslo popisné"
                    value={billing.streetRegistryNumber}
                    onChange={(val) => setBilling({ ...billing, streetRegistryNumber: val })}
                    required
                />
            </div>
            <div className="col-6 col-sm-6 col-md-6">
                <InputText
                    ref={cityRef}
                    label="Město"
                    value={billing.city}
                    onChange={(val) => setBilling({ ...billing, city: val })}
                    required
                    name="city"
                />
            </div>

            <div className="col-6 col-sm-6 col-md-6">
                <InputText
                    ref={zipRef}
                    label="PSČ"
                    value={billing.zip}
                    onChange={(val) => setBilling({ ...billing, zip: val })}
                    required
                    name="postalcode"
                />
            </div>
        </div>

        <SubmitButton
            loading={loadingSaveChanges}
            className="mt-3"
            onClick={saveChanges}
        >
            <>
                Uložit změny
            </>
        </SubmitButton>
    </>
}))