import React from 'react';
import IInputField from '../../Interfaces/IInputField';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import DateTimeHelper from '../../Utils/DateTimeHelper';

interface IProps {
    required?: boolean;
    placeholder?: string;
    label: string;
    value: Date;
    validate?: Function;
    onChange?(value?: Date): void;
    readonly?: boolean,
    onBlur?(): void,
    inputUtcShowLocalOutputUtc?: boolean,
    timeSelect?: boolean,
}

interface IState {
    errors: string[],
}

export default class InputDatePicker extends React.Component<IProps, IState> implements IInputField {
    protected input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current!.focus();
    }

    getErrors(value = this.props.value || '') {
        let errors: string[] = [];

        if (this.props.validate) {
            this.props.validate(
                value || '',
                errors,
                () => this.validateInput(value, errors),
            );
            if (errors.length !== 0) {
                return errors;
            }
        }
        this.validateInput(value, errors);
        return errors;
    }

    async getErrorsAsync(value = this.props.value || ''): Promise<string[]> {
        let errors: string[] = [];

        if (this.props.validate) {
            this.props.validate(
                value || '',
                errors,
                () => this.validateInput(value, errors),
            );
        }
        this.validateInput(value, errors);
        return errors;
    }

    isValid() {
        // return this.state.errors.length === 0;
        return this.getErrors().length === 0;
    }


    validate(value = this.props.value): string[] {
        const errors = this.getErrors(value);
        this.setState({
            errors: errors,
        })
        return errors;
    }

    async validateAsync(value = this.props.value || '') {
        const errors = await this.getErrorsAsync(value);
        this.setState({
            errors: errors,
        })
    }

    validateInput(value: Date | undefined, errors: string[]) {
        if (this.props.required === false) {
            return;
        }
        if (this.props.required && !value) {
            errors.push("Toto pole je povinné");
        }
    }

    handleOnBlur() {
        // this.validate();
        this.validateAsync().then(value => {
            if (this.props.onBlur) {
                this.props.onBlur();
            }
        })
    }

    handleChange(date: Date | undefined) {

        if (date && this.props.inputUtcShowLocalOutputUtc) {
            date = new Date(date?.toUTCString()!)
        };

        //     this.validate(value);
        this.props.onChange!(date);
    }


    addError(message: string): string[] {
        let errors = this.state.errors;
        errors.push(message);

        this.setState({
            errors: errors
        })
        return errors;
    }

    renderError() {
        if (this.state.errors.length === 0) { return null; }
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderLabel() {
        if (this.props.label.length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        let value = this.props.value;

        if (this.props.inputUtcShowLocalOutputUtc) {
            value = DateTimeHelper.convertUTCDateToLocalDate(value)!;
        }

        return (
            <div className="form-group">
                {this.renderLabel()}
                <div className="input-group">
                    <DatePicker
                        className="form-control"
                        selected={this.props.value}
                        onChange={(date) => this.handleChange(date || undefined)}
                        placeholderText="Vyberte"
                        locale={cs}
                        dateFormat={this.props.timeSelect ? "Pp" : "P"}
                        showTimeSelect={this.props.timeSelect}
                    />

                    {/* <input
                        data-clarity-unmask="True"
                        type="text"
                        ref={this.input}
                        className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                        placeholder={this.props.placeholder}
                        value={this.props.value || ''}
                        onChange={this.handleChange.bind(this)}
                        autoComplete={this.props.autoComplete}
                        readOnly={this.props.readonly}
                        disabled={this.props.readonly}
                        onBlur={this.handleOnBlur.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    />*/}
                </div>
                {this.renderError()}
            </div>
        );
    }
}
