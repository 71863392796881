import { round } from "lodash";
import { Currency } from "../Enums/Currency";
import { Language } from "../Enums/Language";
import IArticle from "../Interfaces/IArticle";

export class PricingHelper {

    public static getEstimatedPrice(topicsCount: number, headingCount: number, leadParagraph: boolean, lang: Language, currency: Currency): number {

        var pricePerOneChar = this.getGeneralArticlePricePerChar(currency);

        let averageWordsChars = 7;

        if (lang === Language.Czech) {
            averageWordsChars = 7;
        }
        const averageParagraphWordsCount = 75;
        const paragraphCount = headingCount * 2;
        let totalChars = ((paragraphCount * averageParagraphWordsCount) * averageWordsChars);

        //totalChars += 45 * averageWordsChars * topicsCount; // 45 is second paragraph plus

        if (leadParagraph === true) {
            totalChars += averageParagraphWordsCount * averageWordsChars;
        }

        let headingsChars = 30 * paragraphCount;

        totalChars += headingsChars;
        return round(round(totalChars * pricePerOneChar, 1) * topicsCount, 1);
    }

    public static getGeneralArticlePricePerChar(currency: Currency): number {
        switch (currency) {
            case Currency.Czk:
                return 0.002;

            case Currency.Eur:
                return 0.000085;
        }
    }

    public static getStoryArticlePricePerChar(currency: Currency): number {
        switch (currency) {
            case Currency.Czk:
                return 0.002;

            case Currency.Eur:
                return 0.000085;
        }
    }

    public static getStoryEstimatedPrice(topicsCount: number, currency: Currency): number {
        const totalChars = 2000;

        return round(this.getStoryArticlePricePerChar(currency) * totalChars * topicsCount, 1);
    }

    public static getGeneralArticlePricePerSP(currency: Currency): number {
        return round(this.getGeneralArticlePricePerChar(currency) * 1800, 1);
    }

    public static getProductArticlePricePerChar(currency: Currency): number {
        switch (currency) {
            case Currency.Czk:
                return 0.00367;

            case Currency.Eur:
                return 0.00016;
        }
    }

    public static getProductArticlePricePerSP(currency: Currency): number {
        return round(this.getProductArticlePricePerChar(currency) * 1800, 1);
    }

    public static getEstimatedPriceForArticles(articles: IArticle[], currency: Currency): number {
        let price = 0;

        articles.forEach(article => {
            let priceForArticle = this.getEstimatedPrice(1, article.sectionsCount, true, article.language, currency);
            price += priceForArticle;
        });
        return round(price, 1);
    }

    public static GetGenerateTitlesPrice(currency: Currency) {
        switch (currency) {
            case Currency.Czk:
                return 1;
            case Currency.Eur:
                return 0.042;
            default:
                return 0;
        }
    }

    public static getPriceForYoutubeVideo(currency: Currency): number {
        switch (currency) {
            case Currency.Czk:
                return 1;

            case Currency.Eur:
                return 0.042;
        }
    }
}