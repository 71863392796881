import { ReactNode, useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import { Footer } from "./Footer";
import { Header } from "./Header";
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import { LoadingScreen } from "../Others/LoadingScreen";
import { SidebarMenu } from "./SidebarMenu";
import { inject, observer } from "mobx-react";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import ReactGA from "react-ga4";
import { useExitIntent } from 'use-exit-intent'
import { ExitModal } from "../AboutApp/ExitModal";
import Cookies from 'universal-cookie';
import { VerifyEmailAlert } from "../Account/VerifyEmailAlert";

interface IProps {
    children?: ReactNode,
    currentUserStore?: CurrentUserStore,
}

const currentUserApiService = new CurrentUserApiService();

export const AppLayout = inject('currentUserStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);

    const [showMenu, setShowMenu] = useState(false);

    const [showExitModal, setShowExitModal] = useState(false);

    const cookies = new Cookies();

    useEffect(() => {
        currentUserApiService.getCurrentUser()
            .then(user => {
                if (user) {
                    window.smartsupp('name', user.name);
                    window.smartsupp('email', user.email);
                    cookies.set('exitModal', 'false', { path: '/' });
                }
            })
            .catch(error => { })
            .finally(() => {
                setLoading(false);
            })

        return () => {
            currentUserApiService.cancelRequests();
        }
    }, []);

    const { registerHandler } = useExitIntent()

    const currentUser = props.currentUserStore?.user;
    ReactGA.initialize("G-XVVG3SV1B2");

    registerHandler({
        id: 'openModal',
        handler: () => {
            if (cookies.get("exitModal") !== "false") {
                if (!currentUser && loading === false) {
                    setShowExitModal(true);
                    cookies.set('exitModal', 'false', { path: '/' });
                }
            }
        },
    })

    return <>
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnHover={true}
                draggable
            />

            {showExitModal && <ExitModal
                onHide={() => setShowExitModal(false)}
            />}

            {/* BEGIN Page Holder */}
            <div className="holder">
                {/* BEGIN Page Wrapper */}
                <div className="wrapper">
                    <Header
                        onShowNav={(show) => setShowMenu(show)}
                    />
                    {/* BEGIN Page Content */}
                    <div className="content">
                        <div className="container-fluid g-5">
                            {currentUser && !currentUser.emailConfirmed &&
                                <VerifyEmailAlert user={currentUser} />}

                            {loading ? <LoadingScreen /> : props.children}
                        </div>
                    </div>
                    {/* END Page Content */}
                    <Footer />
                </div>
                {/* END Page Wrapper */}
            </div>
            {/* END Page Holder */}

            <SidebarMenu
                show={showMenu}
                onHide={() => setShowMenu(false)}
                currentUser={currentUser}
            />
            {/* BEGIN Float Button 
            <div className="floating-btn floating-btn-end d-grid gap-2">
                <button
                    className="btn btn-flat-primary btn-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Change theme"
                    id="theme-toggle"
                >
                    <i className="fa fa-moon" />
                </button>
                <a
                    className="btn btn-flat-primary btn-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Look documentation"
                    href="https://docs.blueupcode.com/guide-html_v3-0-0.html"
                    target="_blank"
                >
                    <i className="fa fa-book" />
                </a>
            </div>
            {/* END Float Button */}
        </>
    </>
}))
