import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBox from "../../Components/Inputs/CheckBox";
import InputDatePicker from "../../Components/Inputs/InputDatePicker";
import MultiSelectBox from "../../Components/Inputs/MultiSelectBox";
import SelectBox from "../../Components/Inputs/SelectBox";
import { SubmitButton } from "../../Components/Inputs/SubmitButton";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { NotFoundScreen } from "../../Components/Others/NotFoundScreen";
import { WebsiteWpSetup } from "../../Components/Websites/WebsiteWpSetup";
import { WpOpenStatus } from "../../Enums/Websites/WpOpenStatus";
import { WpStatus } from "../../Enums/Websites/WpStatus";
import WebsitesApiService from "../../Services/WebsitesApiService";
import ArticleStore from "../../Stores/ArticleStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import DateTimeHelper from "../../Utils/DateTimeHelper";
import { WebsiteHelper } from "../../Utils/WebsiteHelper";

interface IProps {
    websiteStore?: WebsiteStore,
    articleStore?: ArticleStore,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteWpExportPage = inject('websiteStore', 'articleStore')(observer((props: IProps) => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const website = props.websiteStore?.getById(id!)!;

    const [wpSettings, setWpSettings] = useState(website ? website.wordpressOptions : undefined)
    const defToDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000);

    useEffect(() => {
        websitesApiService.getWebsite(id!)
            .then(data => {

                const website = data.records.websites.items[0];
                let wpOptions = website.wordpressOptions;
                if (!wpOptions) {
                    wpOptions = {
                        categories: [],
                        commentStatus: WpOpenStatus.Open,
                        pingStatus: WpOpenStatus.Open,
                        sticky: false,
                        tags: [],
                        authorId: undefined,
                        status: WpStatus.Publish,
                        randomPublish: false,
                        publishDateUtc: new Date(),
                        publishRandomFromUtc: new Date(),
                        publishRandomToUtc: defToDate,
                    }
                }
                setWpSettings(wpOptions);

                setLoading(false);
            })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    // const [publishLocalDate, setPublishLocalDate] = useState(DateTimeHelper.convertUTCDateToLocalDate(wpSettings?.publishDateUtc));

    const navigate = useNavigate();

    const onExport = () => {
        websitesApiService.markToExport(website.id, wpSettings!)
            .then(data => {
                if (data.success) {
                    toast.success("Export bude zahájen");

                    navigate(`/websites/${website.id}`);
                }
            })
    }

    /* useEffect(() => {
         setWpSettings({ ...wpSettings!, publishDateUtc: new Date(publishLocalDate?.getUTCDate()!) })
 
     }, [publishLocalDate])
     */
    if (loading) return <LoadingScreen />
    if (!website) return <NotFoundScreen />

    // const publishDateLocal = DateTimeHelper.convertUTCDateToLocalDate(wpSettings?.publishDateUtc || new Date());

    return <>
        <CustomDocumentTitle title={`Web "${website?.name} - WP Export`}>
            <WebsiteWpSetup website={website} />
            {website.wordpressSetuped &&
                <>
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">
                                <i className="fa-solid fa-upload me-2"></i>Exportní nastavení
                            </h3>
                        </div>
                        <div className="portlet-body">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                    <SelectBox
                                        label="Stav"
                                        value={wpSettings?.status}
                                        onChange={(val: WpStatus) => setWpSettings({ ...wpSettings!, status: val })}
                                        options={WebsiteHelper.getWpStatusOptions()}
                                    />

                                    {wpSettings?.status === WpStatus.Future &&
                                        <CheckBox
                                            checked={wpSettings.randomPublish}
                                            label="Zveřejnit náhodně"
                                            onChange={(val) => setWpSettings({ ...wpSettings, randomPublish: val })}
                                        />}
                                </div>


                                {wpSettings?.status === WpStatus.Future && !wpSettings.randomPublish &&
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                        <InputDatePicker
                                            label="Datum zveřejnění"
                                            value={new Date(wpSettings.publishDateUtc || new Date())}
                                            required
                                            onChange={(date) => setWpSettings({ ...wpSettings, publishDateUtc: date || new Date() })}
                                            inputUtcShowLocalOutputUtc={true}
                                            timeSelect={true}
                                        />
                                    </div>}

                                {wpSettings?.status === WpStatus.Future && wpSettings.randomPublish &&
                                    <>
                                        <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                            <InputDatePicker
                                                label="Zveřejnit od"
                                                value={new Date(wpSettings.publishRandomFromUtc || new Date())}
                                                required
                                                onChange={(date) => setWpSettings({ ...wpSettings, publishRandomFromUtc: date || new Date() })}
                                                inputUtcShowLocalOutputUtc={true}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                            <InputDatePicker
                                                label="Zveřejnit do"
                                                value={new Date(wpSettings.publishRandomToUtc!)}
                                                required
                                                onChange={(date) => setWpSettings({ ...wpSettings, publishRandomToUtc: date || new Date() })}
                                                inputUtcShowLocalOutputUtc={true}
                                            />
                                        </div>
                                    </>}

                                {/* <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                    <MultiSelectBox
                                        label="Kategorie"
                                        onChange={(ids, key) => { }}
                                        options={[]}
                                        selected={[]}
                                        uid=""
                                    />
                                </div>*/}

                                <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                    <SelectBox
                                        label="Komentáře"
                                        value={wpSettings?.commentStatus}
                                        onChange={(val: WpOpenStatus) => setWpSettings({ ...wpSettings!, commentStatus: val })}
                                        options={WebsiteHelper.getWpOpenStatusOptions()}
                                        required
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-4 col-xxl-3">
                                    <SelectBox
                                        label="Pingback"
                                        value={wpSettings?.pingStatus}
                                        onChange={(val: WpOpenStatus) => setWpSettings({ ...wpSettings!, pingStatus: val })}
                                        options={WebsiteHelper.getWpOpenStatusOptions()}
                                        required
                                    />
                                </div>
                            </div>

                            <SubmitButton
                                onClick={onExport}
                                className="mt-4"
                            >
                                <>Zahájit export</>
                            </SubmitButton>
                        </div>
                    </div>
                </>
            }
        </CustomDocumentTitle>
    </>
}))