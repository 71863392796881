import { ArticleCancellationReason } from "../../Enums/ArticleCancellationReason";
import { ArticleState } from "../../Enums/ArticleState";
import { OrderState } from "../../Enums/OrderState";

interface IProps {
    articleState: ArticleState,
    orderState?: OrderState,
    cancellationReason?: ArticleCancellationReason,
    error: boolean,
}

export const ArticleStateLabel = (props: IProps) => {

    const { articleState, orderState, cancellationReason } = props;

    if (orderState === OrderState.PendingPayment) {
        return <span className="badge badge-warning">Čekající</span>;
    }

    if (props.error === true) {
        return <span className="badge badge-danger">Došlo k chybě</span>;
    }

    switch (articleState) {
        case ArticleState.Pending:
            return <span className="badge badge-warning">Ve frontě</span>;

        case ArticleState.Finished:
            return <span className="badge badge-success">Dokončeno</span>;

        case ArticleState.Cancelled:
            return (
                <>
                    <span className="badge badge-danger">
                        Zrušeno {cancellationReason && <> -
                            {cancellationReason === ArticleCancellationReason.ProductNotFound && <> Produkt nenalezen</>}
                        </>}
                    </span>
                </>)

        case ArticleState.Concept:
            return <span className="badge badge-warning">Koncept</span>;
         
        default:
    }


    return <></>
}