import { NavLink } from "react-router-dom";

export const Footer = () => {
    return <>
        <div className="footer">
            <div className="footer-inner">
                <div className="container-fluid g-5">
                    <div className="row g-3">
                        <div className="col-sm-6 text-center text-sm-start">
                            <p className="mb-0">
                                <i className="far fa-copyright" />{" "}
                                <span id="copyright-year" /> 2023 by Clonty.com | <a href="/documents/vop.pdf" target="_blank">Obchodní podmínky</a> | <NavLink to='/contact'>Kontakt</NavLink>
                            </p>
                        </div>
                        <div className="col-sm-6 text-center text-sm-end">
                            <p className="mb-0">
                                S nadšením vytvořil Adam Gajdečka <i className="fa fa-heart text-danger" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}