import IArticle from "../../Interfaces/IArticle";
import IWebsite from "../../Interfaces/IWebsite";
import { WebsiteArticleItem } from "./WebsiteArticleItem";

interface IProps {
    articles: IArticle[],
    website: IWebsite
}

export const WebsiteArticlesTable = (props: IProps) => {

    const { articles, website } = props;

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nadpis</th>

                        <th>Wordpress Export</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {articles.map(article => {
                        return <WebsiteArticleItem
                            key={article.id}
                            website={website}
                            article={article}
                        />
                    })}
                </tbody>
            </table>
        </div>)
}