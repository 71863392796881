import { filter, orderBy } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { OrdersTable } from "../../Components/Orders/OrdersTable";
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import OrdersApiService from "../../Services/OrdersApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import OrderStore from "../../Stores/OrderStore";
import Pagination from 'react-js-pagination';

interface IProps {
    orderStore?: OrderStore
    currentUserStore?: CurrentUserStore,
}

const orderApiService = new OrdersApiService(); 

export const MyOrdersPage = inject('orderStore', 'currentUserStore')(observer((props: IProps) => {

    const countToShow = 15;
    const [loading, setLoading] = useState(true);
    const [skipCount, setSkipCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [ordersIds, setOrdersIds] = useState<string[]>([]);

    useEffect(() => {
        setLoading(true);

        orderApiService.getOrders(countToShow, skipCount)
            .then(data => {
                setLoading(false);

                const orders = data.records.orders.items;

                setOrdersIds(orders.map(s => s.id));
                setTotalCount(data.others.get("totalCount"));
            })

        return () => {
            orderApiService.cancelRequests();
        }
    }, [currentPage])

    const orders = orderBy(filter(props.orderStore!.list, order => {
        if (ordersIds.includes(order.id)) {
            return true;
        }
        return false;

    }), prod => [prod.createdUtc], ['desc']);

    if (loading) return <LoadingScreen />

    const onPageChange = (pageNumber: number) => {
        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        setSkipCount(skipCount);
        setCurrentPage(pageNumber);
    }

    return <>
        <CustomDocumentTitle title="Moje objednávky">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-sharp fa-solid fa-cart-shopping me-2"></i> Moje objednávky</h3>
                        </div>
                        <div className="portlet-body">
                            <p>
                                Na této stránce naleznete veškeré vaše objednávky.
                            </p>

                            <OrdersTable orders={orders || []} />

                            {totalCount > countToShow && < Pagination
                                pageRangeDisplayed={countToShow}
                                activePage={currentPage}
                                itemsCountPerPage={countToShow}
                                totalItemsCount={totalCount}
                                onChange={(val) => onPageChange(val)}
                                linkClass="page-link"
                                itemClass="page-item"
                                innerClass="pagination justify-content-center mt-3"
                                hideDisabled={true}
                                hideFirstLastPages={true}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))