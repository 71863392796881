import { Button, Modal } from "react-bootstrap";
import IWebsite from "../../Interfaces/IWebsite";
import { toast } from "react-toastify";

interface IProps {
    onHide(): void,
    website: IWebsite,
}

export const WebsiteXmlExportModal = (props: IProps) => {

    const { onHide, website } = props;

    const showFileIsDownloading = () => {
        toast.success("Stahování zahájeno");
        onHide();
    }

    return <>
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>XML Export</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h5 className="text-black">XML feed</h5>
                <p>Tento feed můžete stahovat ze svého webu.</p>
                <textarea
                    readOnly
                    className="form-control mb-4"
                    value={`https://writer.clonty.com/api/websites/${website.id}/articles/xml`}
                    disabled
                    rows={3}
                />

                <hr />

                <h5 className="text-black mt-4">Stáhnout do počítače</h5>
                <p>Případně si jej lze stáhnout do počítače.</p>
                <a
                    onClick={showFileIsDownloading}
                    className="btn btn-label-primary d-block"
                    href={`/api/websites/${website.id}/articles/xml`}
                    download={`${website.name}_Articles.xml`}
                >
                    <i className="fa-solid fa-download me-2"></i>Stáhnout XML
                </a>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}