import { ImageSource } from "../../Enums/ImageSource"

interface IProps {
    source: ImageSource,
}

export const ArticleImageSourceDescription = (props: IProps) => {

    const { source } = props;

    if (source === ImageSource.Pexels) {
        return <>Pexels - <a target="_blank" href="https://www.pexels.com/license/">https://www.pexels.com/license/</a></>
    }
    else if (source === ImageSource.Pixabay) {
        return <>Pixabay - <a target="_blank" href="https://pixabay.com/service/license/">https://pixabay.com/service/license/</a></>
    }
    else if (source === ImageSource.Unsplash) {
        return <>Unsplash - <a target="_blank" href="https://unsplash.com/license">https://unsplash.com/license</a></>
    }
    else if (source === ImageSource.Search) {
        return <>Vyhledávání - Obrázek pochází z internetu. Licence neznámá.</>
    }
    else {
        return <></>
    }
}