import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"

export const ContactPage = () => {
    return <>
        <CustomDocumentTitle title="Kontakt">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-address-book me-2"></i> KONTAKT</h3>
                        </div>
                        <div className="portlet-body">
                            <p>
                                V případě, že potřebujete poradit či máte jiný dotaz.
                            </p>

                            <h4><a href="mailto:info@clonty.com">info@clonty.com</a></h4>
                        </div>
                    </div>

                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-file-invoice me-2"></i> Fakturační údaje</h3>
                        </div>
                        <div className="portlet-body">
                            <p>
                                Údaje o provozovateli aplikace.
                            </p>

                            <ul className="list-unstyled">
                                <li><b>Adam Gajdečka</b></li>
                                <li>Patrice Lumumby 2334/28</li>
                                <li>Ostrava 70030</li>
                                <li>IČ: 05366542</li>
                                <li>Neplátce DPH. Zapsán v živnostenském rejstříku.</li>
                            </ul>
                            <hr />
                            <p>  Kontakt: info@clonty.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}