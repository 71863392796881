import { Link } from "react-router-dom";
import { OrderState } from "../../Enums/OrderState";
import IOrder from "../../Interfaces/IOrder";

interface IProps {
    order: IOrder,
}

export const OrderStateDetailScreen = (props: IProps) => {

    const { order } = props;

    switch (order.state) {

        case OrderState.PendingPayment:
            return (
                <div className="text-center">
                    <h4 className="mt-5 mb-3 pt-2 text-primary">Čekající na platbu</h4>
                    <p>Dobíjte si kredit, aby se objednávka mohla zpracovat.</p>
                    <Link className="btn btn-primary" to="/account">
                        <i className="fa-solid fa-money-check-dollar me-2"></i>Dobít kredit
                    </Link>
                </div>
            )

        case OrderState.PendingProcess:
            return (
                <div className="text-center">
                    <h4 className="mt-5 mb-3 pt-2 text-primary">Články se generují...</h4>
                    <p>Vraťte se později. Objednávka se postupně zpracuje.</p>
                </div>
            )

        case OrderState.Finished:
            return (
                <div className="text-center">
                    <h4 className="mt-5 mb-3 pt-2 text-success">Zpracováno</h4>
                    <p>Články si můžete vyexportovat dle vaší potřeby.</p>
                </div>
            )


        case OrderState.Cancelled:
            return (
                <div className="text-center">
                    <h4 className="mt-5 mb-3 pt-2 text-danger">Zrušeno</h4>
                    <p>Objednávka byla zrušena.</p>
                </div>
            )

        default:
            return <></>
    }
}