import { inject, observer } from "mobx-react";
import CurrentUserApiService from "../../Services/CurrentUserApiService"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import React, { useEffect, useRef, useState } from "react";
import InputEmail from "../Inputs/InputEmail";
import InputPassword from "../Inputs/InputPassword";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import IInputField from "../../Interfaces/IInputField";
import { NavLink, useNavigate } from "react-router-dom";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import IFormError from "../../Interfaces/IFormError";
import CheckBox from "../Inputs/CheckBox";
import { toast } from 'react-toastify';

interface IProps {
    currentUserStore?: CurrentUserStore,
}

const currentUserApiService = new CurrentUserApiService();

export const RegisterForm = inject('currentUserStore')(observer((props: IProps) => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);

    const [errors, setErrors] = useState<IFormError[]>([]);

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    }, []);

    const emailRef = useRef<InputEmail>(null);
    const passRef = useRef<InputEmail>(null);
    const agreeTermsRef = useRef<CheckBox>(null);

    var arr: React.RefObject<IInputField>[] = [emailRef, passRef];

    function onSubmit() {
        if (!agreeTerms) {
            setErrors([{ message: "Musíte souhlasit s podmínkami služby" }]);
            return;
        }

        const isValid = ValidationHelper.validateInputs(arr);

        if (isValid) {
            currentUserApiService.register(email, password)
                .then(response => {
                    if (response.data.success) {
                        toast.success('Byli jste zaregistrováni!');
                        navigate("/");
                    }
                    else {
                        setErrors(response.data.formErrors);
                    }
                })
        }
    }

    return (<>
        <InputEmail
            ref={emailRef}
            label="Email"
            value={email}
            onChange={(val) => setEmail(val)}
            required
        />

        <InputPassword
            ref={passRef}
            label="Heslo"
            value={password}
            onChange={(val) => setPassword(val)}
            required={true}
            minLength={8}
        />

        <CheckBox
            ref={agreeTermsRef}
            htmlLabel={<>Souhlasím s <a href="/documents/vop.pdf" target="_blank">podmínkami služby</a></>}
            checked={agreeTerms}
            onChange={(checked) => setAgreeTerms(checked)}
        />

        <FormErrorSummary
            errors={errors}
        />

        <button
            className="btn btn-primary mt-3 w-100"
            onClick={onSubmit}
        >
            Registrovat se
        </button>

        <p className="text-center mt-3">
            <NavLink to="/account/login">Přihlásit se</NavLink> | <NavLink to="/account/forgotten-password">Zapomenuté heslo</NavLink>
        </p>
    </>)
}))