import { observable, action, makeAutoObservable } from 'mobx'
import { filter, find, findIndex, orderBy, remove } from 'lodash';
import IWebsite from '../Interfaces/IWebsite';

export default class WebsiteStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IWebsite[] = [];

    @action
    addRecords(data: IWebsite[]): any {
        data.forEach((record: IWebsite) => this.addRecord(record));
    }

    @action
    private addRecord(record: IWebsite) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getByUserId(userId: string): IWebsite[] {
       let websites = filter(this.list, coupon => {
            if (coupon.userId === userId) {
                return true;
            }
            return false;
       });

        websites = orderBy(websites, ["name"], ["asc"])
        return websites;
    }

    getById(id: string): IWebsite | undefined {
        return find(this.list, { id: id });
    }
}