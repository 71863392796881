import { Language } from "../Enums/Language";

export class LanguageHelper {

    public static getLabel(language: Language) :string {
        switch (language) {
            case Language.Czech:
                return "čeština";

            case Language.Slovak:
                return "slovenština";

            case Language.English:
                return "angličtina";           

            default:
                return "";
        }
    }

}