import * as React from 'react';
import IInputField from '../../Interfaces/IInputField';
import IOptionData from '../../Interfaces/IOptionData';

interface IProps {
    required?: boolean;
    label?: string;
    value: any;
    onChange(value: any): void,
    defaultValue?: string;
    options: IOptionData[];
    className?: string;
    formGroupClassName?: string
    disabled?: boolean,
    saved?: boolean,
    onBlur?(): void,
    id?: string,
}

interface IState {
    errors: string[],
}

export default class SelectBox extends React.Component<IProps, IState> implements IInputField {
    private input: React.RefObject<HTMLSelectElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current!.focus();
    }

    getErrors(value = this.props.value) {
        let errors = [];
        /* if (this.props.validate) {
             return this.props.validate(value || '') || [];
             // errors.push(value)
         }*/
        if (this.props.required && (value === null || value === undefined || value === 0 || value === '')) {
            errors.push("Toto pole je povinné");
        }
        return errors;
    }

    isValid() {

        return this.getErrors().length === 0;
    }

    validate(value = this.props.value) {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    isNumeric(value: string) {
        return /^-{0,1}\d+$/.test(value);
    }

    handleChange(e: any) {
        let value = e.target.value;
        if (value === 'default') {
            value = null;
        }

        this.validate(value);
        if (this.isNumeric(value) && value !== 0) {
            this.props.onChange((parseInt(value)));
        }
        else {
            this.props.onChange(value);
        }
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        const val = this.props.value || 'default';

        return (
            <div>
                <select
                    ref={this.input}
                    disabled={this.props.disabled || false}
                    onChange={this.handleChange.bind(this)}
                    value={val}
                    className={this.props.className || `form-select data-hj-allow`}
                    data-clarity-unmask="True"
                    onBlur={this.props.onBlur}
                    id={this.props.id}
                >
                    {this.props.defaultValue ? <option
                        value="default"
                        disabled={this.props.required}> {this.props.defaultValue}
                    </option> : null}

                    {(this.props.options || []).map((option: IOptionData, index: number) => {
                        return (<option key={index} value={option.value} dangerouslySetInnerHTML={{ __html: option.label }}></option>)
                    })
                    }
                </select>
            </div>
        );
    }

    renderLabel() {
        if (!this.props.label) {
            return null;
        }

        return (
            <label className="form-control-label">
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className={`form-group ${this.props.formGroupClassName}`}>
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}

                {this.props.saved === true ? <small>
                    <span className="float-right text-right text-success mt-1">
                        <i className={`fas fa-check text-success tx-16 lh-0 op-6`}></i> uloženo</span>
                </small> : ''}
            </div>
        );
    }
}