import { html_beautify } from "js-beautify";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import nnfx from "react-syntax-highlighter/dist/esm/styles/hljs/nnfx";
import { toast } from "react-toastify";
import { ArticleType } from "../../Enums/ArticleType";
import { Format } from "../../Enums/Format";
import { ImageSource } from "../../Enums/ImageSource";
import IArticle from "../../Interfaces/IArticle";
import ArticlesApiService from "../../Services/ArticlesApiService";
import { ImageDownloadButton } from "../Inputs/ImageDownloadButton";
import { SubmitButton } from "../Inputs/SubmitButton";
import { ArticleEditContent } from "./ArticleEditContent";
import { ArticleImageSourceDescription } from "./ArticleImageSourceDescription";
import { ProductArticleRegenerateModal } from "./ProductArticleImageRegenerateModal";
import { DownloadButton } from "../Inputs/DownloadButton";

interface IProps {
    content: string,
    article: IArticle
}

const articlesApiService = new ArticlesApiService();

export const ArticleContent = (props: IProps) => {

    const { article } = props;
    const [content, setContent] = useState(props.content);

    const [tabActiveKey, setActiveKey] = useState("visual");

    const beautifiedHtmlString = html_beautify(content,
        {
            indent_level: 0,
            wrap_line_length: 85,
            end_with_newline: false,
            indent_head_inner_html: false,
            max_preserve_newlines: 0,
            preserve_newlines: true,
            indent_size: 0,
            disabled: false,
            content_unformatted: [],
            extra_liners: ["h2", "p"]
        }
    );

    const [loadingFindImage, setLoadingFindImage] = useState(false);
    const image = article.images[0];

    const [showRegenerateImageModal, setShowRegenerateImageModal] = useState(false);

    const onFindImage = () => {
        setLoadingFindImage(true);
        articlesApiService.findImage(article.id)
            .then(data => {
                if (data.success) {
                    toast.success("Obrázek doplněn");
                }
                else {
                    toast.warning("K tomuto článku nelze obrázek doplnit");
                }
                setLoadingFindImage(false);
            })
    }

    const [imgQuery, setImgQuery] = useState("");

    if (article.format === Format.Html) {
        return <>
            {showRegenerateImageModal &&
                <ProductArticleRegenerateModal
                    article={article}
                    onHide={() => setShowRegenerateImageModal(false)}
                    onRefreshImage={() => setImgQuery(`?${Math.random().toString()}`)}
                />}

            <h3>{article.title}</h3>

            {!image && article.type === ArticleType.General && <>
                <div className="my-4">
                    <SubmitButton
                        loading={loadingFindImage}
                        loadingText="Hledám obrázek..."
                        onClick={onFindImage}
                    >
                        <><i className="fa-solid fa-image me-2"></i>Doplnit obrázek)</>
                    </SubmitButton>
                </div>
            </>}


            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                }}
            >
                {image &&
                    <>
                        <ImageDownloadButton
                            fileName={`${article.title}.jpg`}
                            text="Stáhnout obrázek"
                            btnVariant="primary"
                            imageUrl={`https://s3.eu-central-1.amazonaws.com/images-writer.clonty.com/${ImageSource[image.source]}-${image.imgId}.jpg`}
                            className="mt-3"
                        />

                        {article.type === ArticleType.Product &&
                            <button
                                onClick={() => setShowRegenerateImageModal(true)}
                                className="btn btn-secondary ms-2"
                            >
                                <i className="fa-regular fa-images me-2"></i>Přegenerovat obrázek
                            </button>}
                    </>}

                <DownloadButton
                    fileName={`${article.title}.docx`}
                    text="Stáhnout .docx"
                    btnVariant="outline-secondary"
                    fileUrl={`/articles/${article.id}/export/word`}
                    className="mt-3 ms-2"
                />

            </div>

            {image && <>
                <div className="position-relative">
                    <img

                        className="img-fluid mt-3"
                        //   style={{ maxHeight: 300, color: "red", border: "solid white", borderWidth: 5, boxShadow: "2px 2px 5px rgba(0,0,0,0.3)" }}
                        style={{
                            maxHeight: 300, boxShadow: "2px 2px 5px rgba(0,0,0,0.3)", display: 'block', margin: '0 auto'
                        }}
                        src={`${image.url}${imgQuery}`}
                    />

                    {image && <small className="text-center">
                        <p className="mt-2">Zdroj obrázku: <i><ArticleImageSourceDescription source={image.source} /></i></p>
                    </small>}
                </div>
            </>}

            <hr />
            <Tabs activeKey={tabActiveKey as any} onSelect={(key) => setActiveKey(key || '')}>
                <Tab className="visualHtml" eventKey="visual" title="Vizuálně">
                    <div className="mt-4">
                        <div className="row">
                            <div
                                className="col-12 col-lg-12"
                            >
                                <div
                                    style={{ textAlign: 'justify' }}
                                    dangerouslySetInnerHTML={{ __html: content }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="html" title="Zobrazit HTML">
                    <SyntaxHighlighter
                        language="htmlbars"
                        style={nnfx}
                        wrapLongLines={true}
                    >
                        {beautifiedHtmlString || ''}
                    </SyntaxHighlighter>
                </Tab>
                <Tab eventKey="raw" title="RAW">
                    {beautifiedHtmlString || ''}
                </Tab>

                <Tab eventKey="edit" title={<>
                    <span className="text-orange clickable">
                        <i className="fa-solid fa-feather me-2"></i>
                        Upravit článek
                    </span>
                </>}>
                    <ArticleEditContent
                        article={article}
                        content={content}
                        onSaveChanged={(content) => {
                            setContent(content);
                            setActiveKey("visual");
                        }}
                    />
                </Tab>
            </Tabs>
        </>
    }
    else {
        return <>
            <h3>{article.title}</h3>
            <hr />
            {content || ''}
        </>
    }
} 