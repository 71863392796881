import { Link } from "react-router-dom";
import IOrder from "../../Interfaces/IOrder"
import IWebsite from "../../Interfaces/IWebsite";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { LanguageHelper } from "../../Utils/LanguageHelper";
import { ShowDateTime } from "../Others/ShowDateTime";
import { OrderPriceLabel } from "./OrderPriceLabel";
import { OrderStateDetailScreen } from "./OrderStateDetailScreen";
import { OrderStateLabel } from "./OrderStateLabel";

interface IProps {
    order: IOrder,
    website?: IWebsite,
}

export const OrderDetailContent = (props: IProps) => {

    const { order, website } = props;

    return <>
        <div className="row">
            <div className="col-12 col-lg-6">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td>Datum</td>

                                <td><ShowDateTime date={order.createdUtc} /></td>
                            </tr>

                            <tr>
                                <td>Web</td>

                                <td>{website ? <Link to={`/websites/${website.id}`}>{website.name}</Link> : "Nepřiřazeno"}</td>
                            </tr>

                            <tr>
                                <td>Stav</td>

                                <td>
                                    <OrderStateLabel state={order.state} />
                                </td>
                            </tr>

                            <tr>
                                <td>Zpracováno</td>

                                <td>{order.finishedArticlesCount}/{order.articlesCount}</td>
                            </tr>

                            <tr>
                                <td>Cena</td>

                                <td>
                                    <OrderPriceLabel order={order} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="col-12 col-lg-6">
                <OrderStateDetailScreen
                    order={order}
                />
            </div>
        </div>
    </>
}