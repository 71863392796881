import { each, } from 'lodash';
import IResponseRecords from '../Interfaces/IResponseRecords';
import IUser from '../Interfaces/IUser';
import { currentUserStore, orderStore, accountTransactionStore, articleStore, websiteStore, currentFrontEndVersion } from '../App';
import IArticle from '../Interfaces/IArticle';
import IRecord from '../Interfaces/IRecord';
import IOrder from '../Interfaces/IOrder';
import IAccountTransaction from '../Interfaces/IAccountTransaction';
import IWebsite from '../Interfaces/IWebsite';
import Swal from 'sweetalert2';

export default class StoreUtil {

    public static process(data: any) {
        let records = data.records as IResponseRecords;

        const frontEndVersion = data.frontendVersion;

        if (frontEndVersion > currentFrontEndVersion) {
            Swal.fire({
                title: `Nová verze aplikace je dostupná`,
                html: `Aby se změny projevily, je nutné znovu načíst aplikaci.`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Aktualizovat`,
                cancelButtonText: 'Teď ne'
            }).then((result) => {
                if (result.value) {
                    window.location.reload();
                }
            })
        }

        each(records, (records, type: string) => {
            type = type.toLowerCase();
            if (type === 'currentuser') {
                currentUserStore.setCurrentUser(records as IUser);
            }
            else if (type === 'articles') {
                var articleRecords = records as IRecord<IArticle>;
                articleStore.addRecords(articleRecords.items);
            }
            else if (type === 'orders') {
                var orderRecords = records as IRecord<IOrder>;
                orderStore.addRecords(orderRecords.items);
            }
            else if (type === 'accounttransactions') {
                var accountTransactionsRecords = records as IRecord<IAccountTransaction>;
                accountTransactionStore.addRecords(accountTransactionsRecords.items);
            }
            else if (type === 'websites') {
                var websitesRecords = records as IRecord<IWebsite>;
                websiteStore.addRecords(websitesRecords.items);
            }
        });
    }
}