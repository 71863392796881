import { Language } from "../Enums/Language";
import IOptionData from "../Interfaces/IOptionData";
import { IStoryArticleGenerateSettings } from "../Interfaces/IStoryArticleGenerateSettings";

export class StoryArticleGeneratorHelper {

    public static getLanguageOptions(): IOptionData[] {
        return [
            { label: "Čeština", value: Language.Czech },
        ]
    }

    public static getDefaultSettings(): IStoryArticleGenerateSettings {
        return {
            downloadImage: false,
        }
    }
}