
interface IProps {
    messages: string[]
}

export const FormSuccessSummary = (props: IProps) => {

    if (props.messages.length === 0) {
        return null;
    }
    else {
        return <>
            <div className="alert alert-label-success mt-2 mb-0">
                <ul className="list-unstyled m-0 p-0">
                    {props.messages.map((msg: string, index: number) => {
                        return (<li key={index}>{msg}</li>)
                    })}
                </ul>
            </div>
        </>
    }
}