import { inject, observer } from "mobx-react";
import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IArticle from "../../Interfaces/IArticle";
import IFormError from "../../Interfaces/IFormError";
import ArticlesApiService from "../../Services/ArticlesApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { NewTextarea } from "../Inputs/NewTextarea";
import SelectBox from "../Inputs/SelectBox";
import { SubmitButton } from "../Inputs/SubmitButton";
import { FormErrorSummary } from "../Others/FormErrorSummary";

interface IProps {
    onHide(): void,
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
    websiteId: string,
    onCreated(articles: IArticle[]): void,
}

const articlesApiService = new ArticlesApiService();

export const WebsiteBulkConceptsModal = inject('websiteStore', 'currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore?.user;

    const [sectionsCount, setSectionsCount] = useState(3);
    const sectionsCountRef = useRef<SelectBox>(null);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<IFormError[]>([]);

    const [topicsArr, setTopicsArr] = useState<string[]>([]);

    const onSubmit = () => {
        const inputs = [sectionsCountRef];

        if (topicsArr.length === 0) {
            setErrors([{ message: "Zadejte nadpisy" }]);
            return;
        }

        if (ValidationHelper.isValid(inputs)) {
            setLoading(true);
            setErrors([]);

            articlesApiService.createConcepts({ titles: topicsArr, websiteId: props.websiteId, sectionsCount: sectionsCount })
                .then(data => {
                    setLoading(false);

                    if (data.success) {
                        const articles = data.records.articles.items;
                        props.onCreated(articles);
                        toast.success("Koncepty vytvořen");
                    }
                });
        }
    }

    const onHide = () => {
        if (!loading) {
            props.onHide();
        }
        else {
            toast.warning("Počkejte na dokončení");
        }
    }

    return <>
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Hromadné vložení nadpisů</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading && <>
                    <ul>
                        <li><b>Můžete najednou vložit až 100 nadpisů</b></li>
                        <li>Nadpisy pište na řádky (použijte Enter)</li>
                        <li>Pište celé nadpisy, nikoli témata</li>
                    </ul>

                    <hr />

                    <NewTextarea
                        onChange={(value) => setTopicsArr(GeneratorHelper.topicsToArray(value))}
                        label=""
                        value={topicsArr.join("\n")}
                        rows={11}
                        className="topics"
                        placeholder="Zde začněte psát...&#10;1 nadpis = 1 řádek"
                        required
                    />

                    <SelectBox
                        ref={sectionsCountRef}
                        label="Výchozí rozsah"
                        options={GeneratorHelper.getArticleRangeOptions()}
                        value={sectionsCount}
                        defaultValue="Vybrat rozsah"
                        onChange={(val) => setSectionsCount(val)}
                    />
                </>}

                {loading && <div className="my-5 text-center">
                    <h5 className="text-primary">Probíhá zpracování</h5>
                    <p><b>Může trvat i 1 minutu. Vyčkejte prosím na této stránce.</b></p>
                </div>}

                <FormErrorSummary
                    errors={errors}
                />
            </Modal.Body>

            <Modal.Footer>
                <SubmitButton
                    loading={loading}
                    onClick={onSubmit}
                >
                    Vložit nadpisy
                </SubmitButton>

                <Button
                    onClick={onHide}
                    variant="secondary"
                    disabled={loading}
                >
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}))