import { Currency } from "../../../Enums/Currency";
import IAccountTransaction from "../../../Interfaces/IAccountTransaction";
import { TransactionItem } from "./TransactionItem";

interface IProps {
    transactions: IAccountTransaction[],
    currency: Currency,
}

export const TransactionsTable = (props: IProps) => {

    const { transactions, currency } = props;

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>                   
                        <th>Datum</th>

                        <th>Částka</th>

                        <th>Popis</th>

                        <th></th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {transactions.map(transaction => {
                        return <TransactionItem
                            key={transaction.id}
                            transaction={transaction}
                            currency={currency}
                        />
                    })}
                </tbody>
            </table>
        </div>)
}