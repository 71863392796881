import { useState } from "react";
import { Link } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { SelectTypeArticleToGenerateModal } from "../Generator/SelectTypeArticleToGenerateModal"

export const HomePageWelcomeCard = () => {

    const [showSelectArticleType, setShowSelectArticleType] = useState(false);

    return <div className="card" style={{ backgroundColor: "rgb(58 168 255 / 10%)" }}>
        <div className="card-body">
            {showSelectArticleType && <SelectTypeArticleToGenerateModal
                onHide={() => setShowSelectArticleType(false)}
            />}

            <div className="py-5 my-5 text-center">
                <h1 className="text-black">Clonty Writer napíše články za Vás</h1>

                <p className="text-primary mt-4">
                    <span className="text-no-overflow"><i className="fa-solid fa-check"></i> Velmi levně</span>
                    <span className="mx-4 text-no-overflow"><i className="fa-solid fa-check"></i> Hromadně</span>
                    <span className="text-no-overflow"><i className="fa-solid fa-check"></i> Wordpress, JSON & XML</span>
                </p>

                <div className="mt-5">
                    <span id="goGenerate" onClick={() => setShowSelectArticleType(true)} className="btn btn-primary btn-lg d-block d-sm-inline me-sm-1">
                        💪 Vyzkoušet ZDARMA
                    </span>

                    <Tooltip
                        anchorId="goGenerate"
                        place="bottom"
                        html={`<span class="text-warning">Nemáte strach, že Vás nahradí umělá inteligence?</span>`}
                    />

                    <Link id="examplesButton" to="/examples" className="btn btn-secondary d-block d-sm-inline btn-lg ms-sm-1 mt-2 mt-sm-0">
                        Příklady a ukázky
                    </Link>

                    <Tooltip
                        anchorId="examplesButton"
                        place="bottom"
                        html={`<span class="text-warning">To musíte vidět! :D</span>`}
                    />
                </div>

                <div className="mt-5" style={{ fontSize: 16 }}>
                    <span className="text-danger"><i className="fa-solid fa-star"></i> Novinka - Obrázky ke článkům <b>ZDARMA</b></span>
                </div>
            </div>
        </div>
    </div>
}