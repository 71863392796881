import { Currency } from "../Enums/Currency";
import IOptionData from "../Interfaces/IOptionData";

export default class CurrencyHelper {

    public static getOptions(): IOptionData[] {

        let options: IOptionData[] = [];
        options.push({ label: this.getLabel(Currency.Czk), value: Currency.Czk });
        options.push({ label: this.getLabel(Currency.Eur), value: Currency.Eur });
        return options;
    }

    public static getLabel(currency: Currency): string {
        switch (currency) {

            case Currency.Czk:
                return 'CZK';

            case Currency.Eur:
                return 'EUR';
        }
    }

    public static getSymbol(currency: Currency): string {
        switch (currency) {

            case Currency.Czk:
                return 'Kč';

            case Currency.Eur:
                return '€';
        }
    }
}