import { html_beautify } from "js-beautify";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import WebsitesApiService from "../../../Services/WebsitesApiService";
import { ProductArticleGeneratorHelper } from "../../../Utils/ProductArticleGeneratorHelper";
import { ValidationHelper } from "../../../Utils/ValidationHelper";
import CheckBox from "../../Inputs/CheckBox";
import InputNumber from "../../Inputs/InputNumber";
import SelectBox from "../../Inputs/SelectBox";
import { SubmitButton } from "../../Inputs/SubmitButton";
import { chatHide, chatShow } from 'smartsupp-widget'
import { IProductArticleGenerateSettings } from "../../../Interfaces/IProductArticleGenerateSettings";
import IWebsite from "../../../Interfaces/IWebsite";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Textarea from "../../Inputs/Textarea";
import ArticlesApiService from "../../../Services/ArticlesApiService";

interface IProps {
    onHide(): void,
    settings: IProductArticleGenerateSettings,
    website?: IWebsite,
    onSave(settings: IProductArticleGenerateSettings): void,
}

const websitesApiService = new WebsitesApiService();
const articlesApiService = new ArticlesApiService();

export const ProductArticleGenerateSettingsModal = (props: IProps) => {

    const { onHide, website } = props;
    const [loading, setLoading] = useState(false);

    const [settings, setSettings] = useState<IProductArticleGenerateSettings>(props.settings);

    const languageRef = useRef<SelectBox>(null);
    const imageWidthRef = useRef<InputNumber>(null);
    const imageHeightRef = useRef<InputNumber>(null);

    const structureRef = useRef<Textarea>(null);

    const beautifiedHtmlStructureString = html_beautify(ProductArticleGeneratorHelper.getDefaultStructure(),
        {
            indent_level: 0,
            wrap_line_length: 85,
            end_with_newline: false,
            indent_head_inner_html: false,
            max_preserve_newlines: 0,
            preserve_newlines: true,
            indent_size: 0,
            disabled: false,
            content_unformatted: [],
            extra_liners: ["h2", "p"],
        }
    );

    const previewStructure: string = ProductArticleGeneratorHelper.replaceStructureVariables(settings.structure);

    useEffect(() => {
        chatHide();

        return (() => {
            websitesApiService.cancelRequests();
            articlesApiService.cancelRequests();

            chatShow();
        })
    }, [])

    const onSubmit = () => {
        const isValid = ValidationHelper
            .validateInputs([
                languageRef,
                imageWidthRef,
                imageHeightRef,
                structureRef,
            ])

        if (isValid) {

            articlesApiService.validateProductArticleStructure(settings.structure)
                .then(data => {
                    const isValidStructure = data.others.get("isValid");

                    if (isValidStructure) {
                        if (!website) {
                            props.onSave(settings);
                            toast.success("Změny uloženy");
                            onHide();
                        }
                        else {

                            Swal.fire({
                                icon: "question",
                                title: 'Chcete toto nastavení uložit jako výchozí pro tento web?',
                                showConfirmButton: true,
                                showDenyButton: true,
                                denyButtonText: "Ne",
                                confirmButtonText: "Ano",
                                text: 'Toto nastavení můžeme uložit k tomuto webu, abyste ho mohli příště použít.'
                            })
                                .then(result => {
                                    if (result.isConfirmed) {
                                        setLoading(true);

                                        websitesApiService.saveProductArticleGenerateSettings(website.id, settings)
                                            .then(data => {
                                                if (data.success) {
                                                    toast.success("Nastavení uloženo");
                                                    props.onSave(settings);
                                                    onHide();
                                                }
                                            })
                                    }
                                    else {
                                        toast.success("Změny uloženy");
                                        props.onSave(settings);
                                        onHide();
                                    }
                                })
                        }
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Struktura článku je chybná",
                            text: "Zkontrolujte, že obsahuje pouze platné proměnné a opakujte akci",
                            showConfirmButton: true,
                            confirmButtonText: "OK"
                            })
                    }
                })
        }  
    }

    const onChange = (key: string, value: any) => {
        setSettings({
            ...settings,
            [key]: value,
        });
    }

    const onDiscard = () => {
        Swal.fire({
            icon: "warning",
            title: 'Změny se neuloží, chcete pokračovat?',
            showConfirmButton: true,
            showDenyButton: true,
            denyButtonText: "Ne",
            confirmButtonText: "Ano, neuložit",
            text: 'Toto nastavení nebude aplikováno.'
        })
            .then(result => {
                if (result.isConfirmed) {
                    onHide();
                }
            })
    }

    return (
        <Modal size="lg" centered={true} show={true} onHide={() => { }}>
            <Modal.Header className="bg-dark-brown">
                <Modal.Title>Nastavení produktových článků</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h5 className="text-black mt-3">Obecné nastavení</h5>
                        <hr />

                        <SelectBox
                            ref={languageRef}
                            label="Jazyk"
                            options={ProductArticleGeneratorHelper.getProductArticleLanguageOptions()}
                            value={settings.language}
                            required
                            onChange={(val) => onChange('language', val)}
                            defaultValue="Vyberte jazyk"
                        />
                    </div>

                    <div className="col-12 col-lg-6">
                        <h5 className="text-black mt-3">Obrázky</h5>
                        <hr />

                        <CheckBox
                            label="Stáhnout produktový náhledový obrázek"
                            checked={settings.downloadImage}
                            onChange={(val) => onChange('downloadImage', val)}
                        />

                        {settings.downloadImage &&
                            <>
                                <CheckBox
                                    label="Povolit zkompilovat více obrázků do jednoho"
                                    checked={settings.allowCreateImageCollage}
                                    onChange={(val) => onChange('allowCreateImageCollage', val)}
                                />

                                <div className="row mt-3">
                                    <div className="col-12 col-lg-6">
                                        <InputNumber
                                            ref={imageWidthRef}
                                            label="Šířka (px)"
                                            value={settings.imageWidth}
                                            onChange={(val) => onChange('imageWidth', val)}
                                            minValue={50}
                                            required
                                            maxValue={1500}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <InputNumber
                                            ref={imageHeightRef}
                                            label="Výška (px)"
                                            value={settings.imageHeight}
                                            onChange={(val) => onChange('imageHeight', val)}
                                            minValue={50}
                                            required
                                            maxValue={1500}
                                        />
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>      
                <hr />
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h5 className="text-black mt-4">Náhled článku</h5>
                        <p>Náhled toho, jak takový článek bude vypadat.</p>
                        <div className="productArticlePreviewStructure m-2">
                            <div dangerouslySetInnerHTML={{ __html: previewStructure }}></div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <h5 className="text-black mt-4">Editor struktury</h5>
                        <p>Upravte si strukturu, dopište libovolné html.</p>
                        <Textarea
                            ref={structureRef}
                            label=""
                            value={settings.structure}
                            onChange={(val) => onChange('structure', val)}
                            rows={15}
                            required
                        />
                        <p className="my-2 text-primary">Možné proměnné: <b>{`{leadParagraph}, {detailsParagraphs}, {customerExperience}, {conclusion}, {youtubeVideo}, {productName}`}</b></p>
                        <p className="my-2"><b>TIP</b>: na konec článku si můžete přidat CTA (odkaz, tlačítko apod.). Do url můžete vložit proměnnou se jménem produktu.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    loading={loading}
                    onClick={onSubmit}
                >
                    Uložit nastavení
                </SubmitButton>

                <Button
                    onClick={onDiscard}
                    variant="secondary"
                    disabled={loading}
                >
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)
}