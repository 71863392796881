import ReactLoading from 'react-loading';

interface IProps {
    loading?: boolean,
    onClick?(): void,
    className?: string,
    children?: React.ReactChild,
    loadingText?: string,
    spinSize?: number,
    btnVariant?: string,
}

export const SubmitButton = (props: IProps) => {

    function onClick() {
        if (props.onClick && !props.loading) {
            props.onClick();
        }
    }

    if (props.loading) {
        return <>
            <button
                className={`btn btn-${props.btnVariant || 'primary'} ${props.className}`}
            >
                <div className="d-flex">
                    <ReactLoading type={"spin"} color={"white"} height={props.spinSize || 15} width={props.spinSize || 15} />
                    <span className="ms-2">{props.loadingText || "Načítám..."}</span>
                </div>
            </button>
        </>
    }

    return <>
        <button
            className={`btn btn-${props.btnVariant || 'primary'} ${props.className}`}
            onClick={onClick}     
        >
            {props.children}
        </button>
    </>
}