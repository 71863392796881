import { ceil } from "lodash";
import QRCode from "react-qr-code"
import { Currency } from "../../../Enums/Currency";

interface IProps {
    amount: number,
    vs: string,
    currency: Currency,
}

export const PaymentTransferQRPortlet = (props: IProps) => {

    const { vs, currency } = props;

    let amountToDeposit = ceil(props.amount, 0);

    let czkMin = 50;
    let eurMin = 2;

    if (amountToDeposit < czkMin && currency === Currency.Czk) {
        amountToDeposit = czkMin;
    }
    else if (amountToDeposit < eurMin && currency === Currency.Eur) {
        amountToDeposit = eurMin;
    }

    const qrValue = `SPD*1.0*ACC:CZ9120100000002901056991*AM:${ceil(amountToDeposit, 0)}.0*CC:CZK*MSG:Clonty AI Writer Kredit*X-VS:${vs}`;
    return (
        <div className="portlet">
            <div className="portlet-header portlet-header-bordered mt-2">
                <h3 className="portlet-title"><i className="fa-solid fa-qrcode me-2"></i>Dobití kreditu (dobití okamžitou platbou během 3 minut)</h3>
            </div>
            <div className="portlet-body">
                {currency === Currency.Czk &&
                    <>
                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                            <QRCode
                                size={1024}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrValue}
                                viewBox={`0 0 256 256`}
                            />
                        </div>

                        <ul className="list-unstyled mt-4">
                            <li>č. účtu <b>2901056991/2010</b></li>
                            <li>VS: <b>{vs}</b></li>
                            <li>Měna: <b>CZK</b></li>
                            <li>Částka: min. <b>{amountToDeposit} Kč</b></li>
                        </ul>
                        <hr />
                        <p><b className="text-primary">Zašlete libovolnou částku avšak minimální částka je {amountToDeposit} Kč</b>. Platnost kreditu je 1 rok.</p>
                    </>}

                {currency === Currency.Eur &&
                    <>
                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                            <QRCode
                                size={1024}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={`SPD*1.0*ACC:CZ8020100000002001807243*AM:${ceil(amountToDeposit, 0)}.0*CC:EUR*Clonty AI Writer Kredit:MSG*X-VS:${vs}`}
                                viewBox={`0 0 256 256`}
                            />
                        </div>

                        <ul className="list-unstyled mt-4">
                            <li>č. účtu <b>2001807243/2010</b></li>
                            <li>VS: <b>{vs}</b></li>
                            <li>Měna: <b>EUR</b></li>
                            <li>Částka: min. <b>{amountToDeposit} Eur</b></li>
                        </ul>

                        <hr />
                        <p><b className="text-primary">Min. částka dobití je {amountToDeposit} EUR</b>. Platnost kreditu je 1 rok.</p>
                    </>
                }
            </div>
        </div>
    )
}