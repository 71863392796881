import IResponseData from "../Interfaces/IResponseData";
import ICreateOrderFromConceptsRequest from "../Interfaces/Orders/ICreateOrderFromConceptsRequest";
import ICreateOrderRequest from "../Interfaces/Orders/ICreateOrderRequest";
import ICreateProductOrderRequest from "../Interfaces/Orders/ICreateProductOrderRequest";
import ICreateStoryOrderRequest from "../Interfaces/Orders/ICreateStoryOrderRequest";
import BaseApiService from "./BaseApiService";

export default class OrdersApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getOrders(takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`orders`, {
            takeCount: (takeCount || 10).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public createOrder(createOrder: ICreateOrderRequest): Promise<IResponseData> {
        return this.postRequest(`orders`, createOrder, {})
            .then(response => {
                return response.data;
            })
    }

    public getOrder(id: string): Promise<IResponseData> {
        return this.getRequest(`orders/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public getOrderArticles(id: string, takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`orders/${id}/articles`, {
            takeCount: (takeCount || 10).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public cancelOrder(id: string): Promise<IResponseData> {
        return this.deleteRequest(`orders/${id}`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public assignToWebsite(id: string, websiteId: string): Promise<IResponseData> {
        return this.postRequest(`orders/${id}/assign-website`, {}, { websiteId: websiteId })
            .then(response => {
                return response.data;
            })
    }

    public createOrderFromConcepts(createOrder: ICreateOrderFromConceptsRequest): Promise<IResponseData> {
        return this.postRequest(`orders/create-from-concepts`, createOrder, {})
            .then(response => {
                return response.data;
            })
    }

    public createProductOrder(createOrder: ICreateProductOrderRequest): Promise<IResponseData> {
        return this.postRequest(`orders/type/product`, createOrder, {})
            .then(response => {
                return response.data;
            })
    }

    public createStoryOrder(createOrder: ICreateStoryOrderRequest): Promise<IResponseData> {
        return this.postRequest(`orders/type/story`, createOrder, {})
            .then(response => {
                return response.data;
            })
    }   
}