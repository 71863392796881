import { AxiosError } from "axios";
import { inject, observer } from "mobx-react"
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import { TransactionsTable } from "../../Components/Account/Transactions/TransactionsTable";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle";
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { SeparateThousands } from "../../Components/Others/SeparateThousands";
import AccountTransactionsApiService from "../../Services/AccountTransactionsApiService"
import AccountTransactionStore from "../../Stores/AccountTransactionStore"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import CurrencyHelper from "../../Utils/CurrencyHelper";

interface IProps {
    accountTransactionStore?: AccountTransactionStore,
    currentUserStore?: CurrentUserStore,
}

const accountTransactionsApiService = new AccountTransactionsApiService();

export const TransactionsPage = inject('accountTransactionStore','currentUserStore')(observer((props: IProps) => {

    const countToShow = 15;
    const [loading, setLoading] = useState(true);
    const [skipCount, setSkipCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [transactionsIds, setTransactionsIds] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        accountTransactionsApiService.getAccountTransactions(countToShow, skipCount)
            .then(data => {
                setLoading(false);

                const transactions = data.records.accountTransactions.items;

                setTransactionsIds(transactions.map(s => s.id));
                setTotalCount(data.others.get("totalCount"));
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response!.status === 401) {
                    navigate("/account/login");
                }
            })

        return () => {
            accountTransactionsApiService.cancelRequests();
        }
    }, [currentPage])

    const transactions = props.accountTransactionStore?.filterByIds(transactionsIds);

    if (loading) return <LoadingScreen />

    const user = props.currentUserStore?.user!;

    const onPageChange = (pageNumber: number) => {
        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        setSkipCount(skipCount);
        setCurrentPage(pageNumber);
    }

    return <>
        <CustomDocumentTitle title="Pohyby na účtu">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-money-bill me-2"></i> Pohyby na účtu</h3>

                            <span className="float-end">Aktuální zůstatek: <b><SeparateThousands number={user?.creditBalance || 0} /> {CurrencyHelper.getSymbol(user.currency)}</b></span>
                        </div>
                        <div className="portlet-body">
                            <TransactionsTable
                                currency={user.currency}
                                transactions={transactions || []}
                            />

                            {totalCount > countToShow && <Pagination
                                pageRangeDisplayed={countToShow}
                                activePage={currentPage}
                                itemsCountPerPage={countToShow}
                                totalItemsCount={totalCount}
                                onChange={(val) => onPageChange(val)}
                                linkClass="page-link"
                                itemClass="page-item"
                                innerClass="pagination justify-content-center mt-3"
                                hideDisabled={true}
                                hideFirstLastPages={true}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))