import { Button, Modal } from "react-bootstrap"
import { LoginForm } from "./LoginForm";

interface IProps {
    onHide?(): void,
    onLogged(): void,
    defaultEmail?: string,
}

export const LoginModal = (props: IProps) => {

    const { onHide } = props;

    return (
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Přihlásit se</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginForm defaultEmail={props.defaultEmail} onLogged={props.onLogged} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)
}