import CurrentUserApiService from "../../Services/CurrentUserApiService"
import React, { useEffect, useRef, useState } from "react";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import IInputField from "../../Interfaces/IInputField";
import { NavLink, useNavigate } from "react-router-dom";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import IFormError from "../../Interfaces/IFormError";
import InputPassword from "../Inputs/InputPassword";
import { toast } from "react-toastify";

const currentUserApiService = new CurrentUserApiService();

export const ChangePasswordForm = () => {

    const navigate = useNavigate();
    const [originPassword, setOriginPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [errors, setErrors] = useState<IFormError[]>([]);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    },[]);

    const originPasswordRef = useRef<InputPassword>(null);
    const newPasswordRef = useRef<InputPassword>(null);

    var arr: React.RefObject<IInputField>[] = [originPasswordRef, newPasswordRef];

    function onSubmit() {
        const isValid = ValidationHelper.validateInputs(arr);

        if (isValid) {
            currentUserApiService.changePassword(originPassword, newPassword)
                  .then(response => {
                      if (response.data.success) {
                          toast.success("Heslo bylo změněno");
                          navigate("/account");
                      }
                      else {
                          setErrors(response.data.formErrors);
                      }
                  })
        }
    }

    return (
        <>
            <InputPassword
                ref={originPasswordRef}
                label="Původní heslo"
                value={originPassword}
                onChange={(val) => setOriginPassword(val)}
                required
            />

            <InputPassword
                ref={newPasswordRef}
                label="Nové heslo"
                value={newPassword}
                onChange={(val) => setNewPassword(val)}
                required
                minLength={8}
            />

            <FormErrorSummary
                errors={errors}
            />

            <button
                className="btn btn-primary mt-3 w-100"
                onClick={onSubmit}
            >
                Změnit heslo
            </button>

            <p className="text-center mt-3">
                <NavLink to="/account/forgotten-password">Zapomenuté heslo</NavLink>
            </p>
        </>)
}