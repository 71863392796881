import IInputField from "../Interfaces/IInputField";

export class ValidationHelper {

    public static validateInputs(inputs: React.RefObject<IInputField>[]) {
        let firstInvalid: IInputField;
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current!;

            if (input) {
                if (input.validate) {
                    input.validate();
                }

                if (!input.isValid() && !firstInvalid!) {
                    firstInvalid = input;
                    input.focus!();
                }

            }
        }

        if (firstInvalid!) {
            return false;
        }
        return true;
    }


    public static isValid(inputs: React.RefObject<IInputField>[]) {
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current!;

            if (input) {
                if (input.isValid() != null) {
                    if (!input.isValid()) {
                        return false;
                    }
                }

            }
        }
        return true;
    }
}