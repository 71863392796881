import { error } from "console";
import { inject, observer } from "mobx-react";
import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IArticle from "../../Interfaces/IArticle";
import IFormError from "../../Interfaces/IFormError";
import ArticlesApiService from "../../Services/ArticlesApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import CurrencyHelper from "../../Utils/CurrencyHelper";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { PricingHelper } from "../../Utils/PricingHelper";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { WebsiteHelper } from "../../Utils/WebsiteHelper";
import InputText from "../Inputs/InputText";
import SelectBox from "../Inputs/SelectBox";
import { SubmitButton } from "../Inputs/SubmitButton";
import { FormErrorSummary } from "../Others/FormErrorSummary";

interface IProps {
    onHide(): void,
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
    websiteId: string,
    onGenerated(articles: IArticle[]): void,
}

const articlesApiService = new ArticlesApiService();

export const GenerateArticleTitlesModal = inject('websiteStore', 'currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore?.user;

    const websites = props.websiteStore?.getByUserId(user?.id!);

    const [topic, setTopic] = useState("");
    const topicRef = useRef<InputText>(null);


    const [sectionsCount, setSectionsCount] = useState(3);
    const sectionsCountRef = useRef<SelectBox>(null);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<IFormError[]>([]);

    const onSubmit = () => {
        const inputs = [topicRef, sectionsCountRef];

        if (ValidationHelper.isValid(inputs)) {
            setLoading(true);

            articlesApiService.generateTitles({
                sectionsCount: sectionsCount,
                topic: topic,
                websiteId: props.websiteId,
            }).then(data => {
                if (data.success) {
                    toast.success("Nadpisy vygenerovány");
                    const articles = data.records.articles.items;
                    setLoading(false);
                    props.onGenerated(articles);
                }
                else {
                    setErrors(data.formErrors);
                    setLoading(false);
                }
            })
        }
    }

    const onHide = () => {
        if (!loading) {
            props.onHide();
        }
        else {
            toast.warning("Počkejte na dokončení generování");
        }
    }

    return <>
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Generátor nadpisů dle tématu</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {!loading && <>
                    <ul>
                        <li><b>Vygenerujeme vám nadpisy až pro 15 článků na zadané téma</b></li>
                        <li>Nemusíte vymýšlet témata na články</li>
                        <li>Následně si z nich budete moci vybrat nebo je upravit</li>
                    </ul>

                    <hr />

                    <div className="row mt-2">
                        <div className="col col-md-6">
                            <InputText
                                ref={topicRef}
                                label="Zadejte téma"
                                value={topic}
                                onChange={(val) => setTopic(val)}
                                required
                                placeholder="Téma nebo klíčové slovo"
                            />
                        </div>

                        <div className="col col-md-6">
                            <SelectBox
                                ref={sectionsCountRef}
                                label="Výchozí rozsah"
                                options={GeneratorHelper.getArticleRangeOptions()}
                                value={sectionsCount}
                                defaultValue="Vybrat rozsah"
                                onChange={(val) => setSectionsCount(val)}
                            />
                        </div>
                    </div>

                    <p className="text-primary mt-3">
                        Cena za generování je {PricingHelper.GetGenerateTitlesPrice(user?.currency!)} {CurrencyHelper.getSymbol(user?.currency!)}.
                    </p>
                </>}

                {loading && <div className="my-5 text-center">
                    <h5 className="text-primary">Probíhá generování</h5>
                    <p><b>Může trvat i 1 minutu. Vyčkejte prosím na této stránce.</b></p>
                </div>}

                <FormErrorSummary
                    errors={errors}
                />
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    loading={loading}
                    onClick={onSubmit}
                >
                    Generovat nadpisy
                </SubmitButton>

                <Button
                    onClick={onHide}
                    variant="secondary"
                    disabled={loading}
                >
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}))