import { RegisterForm } from "../../Components/Account/RegisterForm"
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"

export const RegisterPage = () => {
    return <>
        <CustomDocumentTitle title="Registrovat se">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-user me-2"></i> Registrovat se</h3>
                        </div>
                        <div className="portlet-body">
                            <RegisterForm />
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}