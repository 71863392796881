import { useState } from "react";
import Swal from "sweetalert2";
import IUser from "../../Interfaces/IUser"
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import { SubmitButton } from "../Inputs/SubmitButton";

interface IProps {
    user: IUser,
}

const userApiService = new CurrentUserApiService();

export const VerifyEmailAlert = (props: IProps) => {

    const { user } = props;

    const [loading, setLoading] = useState(false);

    const onVerify = () => {
        setLoading(true);
        userApiService.sendVerifyEmail()
            .then(data => {
                setLoading(false);

                if (data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: "E-mail jsme právě odeslali",
                        text: "Pokud Vám e-mail nedorazí do 5 minut, zkontrolujte složku spam."
                    })
                }
            })
    }

    return <>
        <div className="alert alert-label-secondary" style={{ display: 'flex', justifyContent: 'center' }}>
            <i className="fa-solid fa-envelope me-2"></i>Potvrďte Váš email

            <SubmitButton
                className="ms-3"
                onClick={onVerify}
                loading={loading}
            >
                Zaslat znovu ověřovací e-mail
            </SubmitButton>
        </div>
    </>
}