import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { NotFoundScreen } from "../../Components/Others/NotFoundScreen";
import WebsitesApiService from "../../Services/WebsitesApiService";
import ArticleStore from "../../Stores/ArticleStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import Pagination from 'react-js-pagination';
import { WebsiteArticlesExportsButtons } from "../../Components/Websites/WebsiteArticlesExportsButtons";
import { WebsiteArticlesTable } from "../../Components/Websites/WebsiteArticlesTable";

interface IProps {
    websiteStore?: WebsiteStore,
    articleStore?: ArticleStore,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteDetailPage = inject('websiteStore', 'articleStore')(observer((props: IProps) => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const countToShow = 20;
    const [skipCount, setSkipCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [articlesIds, setArticlesIds] = useState<string[]>([]);

    useEffect(() => {
        websitesApiService.getWebsite(id!)
            .then(data => {
                setLoading(false);
            })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    useEffect(() => {
        setLoading(true);

        websitesApiService.getWebsiteArticles(id!, countToShow, skipCount)
            .then(data => {
                setLoading(false);

                const articles = data.records.articles.items;
                setArticlesIds(articles.map(s => s.id));
                setTotalCount(data.others.get("totalCount"));
            })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [currentPage])

    const website = props.websiteStore?.getById(id!);

    if (loading) return <LoadingScreen />
    if (!website) return <NotFoundScreen />

    const articles = props.articleStore?.filterByIds(articlesIds);

    const onPageChange = (pageNumber: number) => {
        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        setSkipCount(skipCount);
        setCurrentPage(pageNumber);
    }

    return <>
        <CustomDocumentTitle title={`Web - ${website?.name}`}>
            <div className="portlet">
                <div className="portlet-header portlet-header-bordered mt-2">
                    <h3 className="portlet-title"><i className="fa-solid fa-globe me-2"></i>Web - <b>{website?.name}</b></h3>
                    <div className="float-end">
                        <button
                            onClick={() => navigate(`/websites/${website.id}/generate`)}
                            className="btn btn-primary me-2"
                        >
                            <i className="fa-solid fa-rocket me-1"></i>Generovat články
                        </button>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Název</td>

                                            <td>{website.name}</td>
                                        </tr>

                                        <tr>
                                            <td>Url webu</td>

                                            <td>
                                                <a target="_blank"
                                                    href={website.domain}
                                                >
                                                    {website.domain}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portlet">
                <div className="portlet-header portlet-header-bordered mt-2 d-block">
                    <div className="row">
                        <div className="col col-lg-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-list me-2" /> Články ({website.articlesCount})</h3>
                        </div>

                        <div className="col col-lg-10">
                            <div className="float-end">
                                <WebsiteArticlesExportsButtons website={website} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="portlet-body">
                    <WebsiteArticlesTable
                        website={website}
                        articles={articles || []}
                    />

                    {totalCount > countToShow && <Pagination
                        pageRangeDisplayed={countToShow}
                        activePage={currentPage}
                        itemsCountPerPage={countToShow}
                        totalItemsCount={totalCount}
                        onChange={(val) => onPageChange(val)}
                        linkClass="page-link"
                        itemClass="page-item"
                        innerClass="pagination justify-content-center mt-3"
                        hideDisabled={true}
                        hideFirstLastPages={true}
                    />}
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))