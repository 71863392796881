import { ArticleType } from "../../Enums/ArticleType";
import IArticle from "../../Interfaces/IArticle"
import { Bag, BookHalf, FileText } from 'react-bootstrap-icons';

interface IProps {
    article: IArticle,
}

export const ArticleTypeIcon = (props: IProps) => {

    const { article } = props;

    if (article.type === ArticleType.General) {
        return <FileText className="me-2" />
    }
    else if (article.type === ArticleType.Product) {
        return <Bag className="me-2" />
    }
    else if (article.type === ArticleType.Story) {
        return <BookHalf className="me-2" />
    }
    return <></>
}