import IWebsite from "../../Interfaces/IWebsite";
import { WebsiteItem } from "./WebsiteItem";

interface IProps {
    websites: IWebsite[],
}

export const WebsitesTable = (props: IProps) => {

    const { websites } = props;

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>        
                        <th>Název</th>

                        <th>Url webu</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {websites.map(website => {
                        return <WebsiteItem
                            key={website.id}
                            website={website}
                        />
                    })}
                </tbody>
            </table>
        </div>)
}