import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import IOrder from "../../Interfaces/IOrder"
import IWebsite from "../../Interfaces/IWebsite";
import { Tooltip } from 'react-tooltip'
import { useState } from "react";
import { OrderAssignToWebsiteModal } from "./OrderAssignToWebsiteModal";

interface IProps {
    order: IOrder,
    website?: IWebsite,
}

export const OrderArticlesExportsButtons = (props: IProps) => {

    const { order, website } = props;

    const showFileIsDownloading = () => {
        toast.success("Stahování zahájeno");
    }

    const navigate = useNavigate();

    const [showAssignToWeb, setShowAssignToWeb] = useState(false);
     
    return <>
        <div className="float-end">

            {website ?
                <button onClick={() => navigate(`/websites/${website.id}/wp-export`)} className="btn btn-label-primary me-2">
                    <i className="fa-solid fa-upload me-2"></i>Wordpress
                </button> :

                <>
                    {showAssignToWeb && <OrderAssignToWebsiteModal
                        order={order}
                        onHide={() => setShowAssignToWeb(false)}
                    />}
                    <button
                        id="wpExportDisabled"
                        className="btn btn-label-primary me-2"
                        onClick={() => setShowAssignToWeb(true)}
                    >
                        <i className="fa-sharp fa-solid fa-plus me-2"></i>Přiřadit k webu
                    </button>

                    <button id="wpExportDisabled" className="btn btn-label-primary disabled me-2">
                        <i className="fa-solid fa-upload me-2"></i>Wordpress
                    </button>
                </>}

            <Tooltip
                anchorId="wpExportDisabled"
                place="bottom"
                html={`Jakmile přiřadíte web budete moci exportovat do Wordpressu`}
            />

            <a
                onClick={showFileIsDownloading}
                className="btn btn-label-primary me-2"
                href={`/api/orders/${order.id}/articles/json`}
                download={`Order#${order.orderId}_Articles.json`}
            >
                <i className="fa-solid fa-download me-2"></i>JSON
            </a>

            <a
                onClick={showFileIsDownloading}
                className="btn btn-label-primary me-2"
                href={`/api/orders/${order.id}/articles/xml`}
                download={`Order#${order.orderId}_Articles.xml`}
            >
                <i className="fa-solid fa-download me-2"></i>XML
            </a>
        </div>
    </>
}