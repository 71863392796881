import { useParams } from "react-router-dom";
import { ResetPasswordForm } from "../../Components/Account/ResetPasswordForm"
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"

export const ResetPasswordPage = () => {

    const { code } = useParams();

    return <>
        <CustomDocumentTitle title="Nastavení nového hesla">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-user me-2"></i> Nastavení nového hesla</h3>
                        </div>
                        <div className="portlet-body">
                            <ResetPasswordForm code={code || ''} />
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}