import { filter, find, round, uniq } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBox from "../../Components/Inputs/CheckBox";
import InputEmail from "../../Components/Inputs/InputEmail";
import SelectBox from "../../Components/Inputs/SelectBox";
import { SubmitButton } from "../../Components/Inputs/SubmitButton";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle";
import { FormErrorSummary } from "../../Components/Others/FormErrorSummary";
import { Language } from "../../Enums/Language";
import IFormError from "../../Interfaces/IFormError";
import OrdersApiService from "../../Services/OrdersApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { PricingHelper } from "../../Utils/PricingHelper";
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import WebsiteStore from "../../Stores/WebsiteStore";
import { WebsiteHelper } from "../../Utils/WebsiteHelper";
import InputText from "../../Components/Inputs/InputText";
import { LoginModal } from "../../Components/Account/LoginModal";
import Swal from "sweetalert2";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { OrderState } from "../../Enums/OrderState";
import Textarea from "../../Components/Inputs/Textarea";
import ArticlesApiService from "../../Services/ArticlesApiService";
import { Currency } from "../../Enums/Currency";
import CurrencyHelper from "../../Utils/CurrencyHelper";
import IOptionData from "../../Interfaces/IOptionData";
import MultiSelectBox from "../../Components/Inputs/MultiSelectBox";
import { WebsiteWordpressUpdateCategoriesModal } from "../../Components/Websites/WebsiteWordpressUpdateCategoriesModal";
import ICreateStoryOrderRequest from "../../Interfaces/Orders/ICreateStoryOrderRequest";
import { StoryArticleGeneratorHelper } from "../../Utils/StoryArticleGeneratorHelper";

interface IProps {
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
}

const orderApiService = new OrdersApiService();
const currentUserApiService = new CurrentUserApiService();
const articlesApiService = new ArticlesApiService();

export const GenerateStoriesArticlesPage = inject('currentUserStore', 'websiteStore')(observer((props: IProps) => {

    const navigate = useNavigate();

    const currentUser = props.currentUserStore?.user;

    const storageStoriesNamesArr = JSON.parse(localStorage.getItem("storiesNames") || "[]") as string[];

    const [storiesNamesArr, setStoriesNamesArr] = useState<string[]>(storageStoriesNamesArr || []);

    const [email, setEmail] = useState("");
    const emailRef = useRef<InputEmail>(null)

    const languageRef = useRef<SelectBox>(null);

    const [websiteId, setWebsiteId] = useState<string | undefined>(undefined);
    const [websiteOption, setWebsiteOption] = useState<string | undefined>(undefined);

    const [agreeTerms, setAgreeTerms] = useState(false);

    const [errors, setErrors] = useState<IFormError[]>([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [addNewWebsite, setAddNewWebsite] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const [settings, setSettings] = useState(StoryArticleGeneratorHelper.getDefaultSettings());

    const storiesNamesRef = useRef<Textarea>(null);


    const [currency, setCurrency] = useState(Currency.Czk);

    useEffect(() => {
        if (websiteOption === "other") {
            setAddNewWebsite(true);
            setWebsiteId(undefined);
        } else {
            setAddNewWebsite(false);
            setWebsiteId(websiteOption);
        }

    }, [websiteOption])

    const websites = currentUser ? props.websiteStore?.getByUserId(currentUser.id) : [];

    const websitesOptions = WebsiteHelper.getWebsiteOptions(websites || []);
    websitesOptions.push({ label: "Jiný web", value: "other" })

    const [newWebsiteName, setNewWebsiteName] = useState("");

    const [wordpressCategoriesIds, setWordpressCategoriesIds] = useState<number[]>([]);
    const selectedWebsite = find(websites, { id: websiteId });
    const [showWebWpUpdateCategoriesModal, setShowWebWpUpdateCategoriesModal] = useState(false);

    useEffect(() => {
        return () => {
            orderApiService.cancelRequests();
            currentUserApiService.cancelRequests();
            articlesApiService.cancelRequests();
        }
    }, []);

    function onSubmit() {
        let errors: IFormError[] = [];
        var uniqStoriesNamesArr = uniq(storiesNamesArr);

        if (uniqStoriesNamesArr.length === 0) {
            errors.push({ message: "Zadejte témata" });
        }

        if (!currentUser && !agreeTerms) {
            errors.push({ message: "Musíte souhlasit s podmínkami" });
        }

        if (!currentUser && !email) {
            errors.push({ message: "Zadejte e-mail" });
        }

        if (errors.length > 0) {
            setErrors([errors[0]]);
        }
        else {
            setErrors([]);
        }

        const inputs = [storiesNamesRef, languageRef];
        const isValid = ValidationHelper.validateInputs(inputs);

        if (errors.length === 0 && isValid) {
            setSubmitLoading(true);

            // create order

            var createStoryOrderRequest: ICreateStoryOrderRequest = {
                settings: settings,
                storiesNames: storiesNamesArr,
                websiteId: websiteId,
                newWebsiteName: newWebsiteName,
                newUserEmail: email,
                wordpressCategoriesIds: wordpressCategoriesIds,
            };

            orderApiService.createStoryOrder(createStoryOrderRequest)
                .then(data => {
                    if (data.success) {
                        const order = data.records.orders.items[0];

                        cleanTopicsLocalStorage();

                        toast.success("Objednávka vytvořena");

                        if (order.state === OrderState.PendingPayment) {
                            navigate(`/account`)
                        }
                        else {
                            navigate(`/orders/${order.id}`)
                        }
                    }
                    else {
                        const errorType = data.others.get("errorType");

                        if (errorType === "loginRequired") {
                            setShowLoginModal(true);

                            toast.warning("Již jste registrován. Je nutné se přihlásit");
                        }

                        setErrors(data.formErrors);
                        setSubmitLoading(false);
                    }
                })
        }
    }

    const saveStoriesNamesToLocalStorage = () => {
        let array = storiesNamesArr.filter(str => str !== "");
        array = uniq(array);
        localStorage.setItem("storiesNames", JSON.stringify(array));

        setStoriesNamesArr(array);
    }

    const cleanTopicsLocalStorage = () => {
        localStorage.setItem("storiesNames", JSON.stringify([]));
    }

    const isFree = !currentUser && storiesNamesArr.length <= 1;
    const website = props.websiteStore?.getById(websiteId || '');

    const wpCategoriesOptions = WebsiteHelper.convertWpCategoriesToOptions(selectedWebsite?.wordpressCategories);

    const selectedCategoriesOptions = filter(wpCategoriesOptions, (opt: IOptionData) => {
        if (wordpressCategoriesIds.includes(opt.value)) {
            return true;
        }
        return false;
    })

    const onChangeLanguage = (lang: Language) => {
        setSettings({
            ...settings,
            language: lang
        })
    }

    return <>
        <CustomDocumentTitle title="Generovat příběhové články">
            {showLoginModal && <LoginModal
                defaultEmail={email}
                onLogged={() => {
                    setShowLoginModal(false);
                    setErrors([]);
                }}
                onHide={() => setShowLoginModal(false)}
            />}

            {showWebWpUpdateCategoriesModal && selectedWebsite &&
                <WebsiteWordpressUpdateCategoriesModal
                    website={selectedWebsite!}
                    onHide={() => setShowWebWpUpdateCategoriesModal(false)}
                />
            }

            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Hromadný generátor příběhových článků</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-12 col-md-6 col-xl-6">
                    <div className="portlet" style={{ backgroundColor: 'rgb(252 137 3 / 10%)' }}>
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3
                                className="portlet-title text-black"
                                style={{ whiteSpace: 'pre-wrap' }}
                            >
                                <i className="fa-solid fa-list me-1"></i> 1. KROK - ZADEJTE TÉMATA PŘÍBĚHŮ
                            </h3>
                        </div>
                        <div className="portlet-body" id="s">
                            <p>
                                Aplikace vám vygeneruje příběhové články na základě témat. Příběhy jsou fiktivní.
                            </p>

                            <p className="mt-3 text-primary">
                                <i className="fa-solid fa-circle-info" /> Každé téma příběhu = 1 článek
                            </p>

                            <Textarea
                                ref={storiesNamesRef}
                                onChange={(value) => setStoriesNamesArr(GeneratorHelper.topicsToArray(value))}
                                label=""
                                value={storiesNamesArr.join("\n")}
                                rows={11}
                                className="topics"
                                placeholder="&#10;‎ZADÁVEJTE TÉMATA NA PŘÍBĚHY&#10;např.‎ &#10;  Objevovali jsme jižní Itálii&#10;...Drak, který zachránil Ukrajinu před agresivním Ruskem&#10;...Sci-fi o umělé inteligenci, která změnila lidstvo a změnila osud lidstva&#10;...Závěrečná jízda v autoškole: Moje nešikovnost skončila tragédií&#10;...Pohádka o lesních vílách&#10;"
                                required
                                onBlur={() => saveStoriesNamesToLocalStorage()}
                                wrap="off"
                            />

                            <p className="mt-2">TIP: <b>Můžete zadat 1 až 300 témat</b>.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-gear me-2"></i>2. KROK - PARAMETRY ČLÁNKU</h3>
                        </div>
                        <div className="portlet-body">
                            <div className="row mb-3 position-relative">
                                {currentUser && <>  <div className="col-12 col-lg-6">
                                    <SelectBox
                                        defaultValue="Nevybráno"
                                        label="Přiřadit k webu"
                                        options={websitesOptions}
                                        value={websiteOption}
                                        onChange={(val) => setWebsiteOption(val)}
                                    />
                                </div>
                                    {addNewWebsite && <>
                                        <div className="col-12 col-lg-6">
                                            <InputText
                                                label="Název webu"
                                                value={newWebsiteName}
                                                onChange={(val) => setNewWebsiteName(val)}
                                                required
                                            />
                                        </div></>}

                                    {selectedWebsite && selectedWebsite.wordpressSetuped && <>
                                        <div className="col-12 col-lg-6">

                                            <div className="position-relative">
                                                <MultiSelectBox
                                                    label="Kategorie"
                                                    options={wpCategoriesOptions}
                                                    onChange={(ids) => setWordpressCategoriesIds(ids.map(s => parseInt(s)))}
                                                    selected={selectedCategoriesOptions}
                                                    uid=""
                                                    required={false}
                                                />

                                                <div
                                                    className="position-absolute"
                                                    style={{ top: 0, left: '63px' }}
                                                >
                                                    <span
                                                        className="text-primary clickable"
                                                        style={{ fontSize: '9px' }}
                                                        onClick={() => setShowWebWpUpdateCategoriesModal(true)}
                                                    >
                                                        Aktualizovat
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </>}
                                </>}

                                <div className="col-12 col-lg-6">
                                    <SelectBox
                                        ref={languageRef}
                                        label="Jazyk"
                                        options={StoryArticleGeneratorHelper.getLanguageOptions()}
                                        value={settings.language}
                                        required
                                        onChange={(val) => onChangeLanguage(val)}
                                        defaultValue="Vyberte jazyk"
                                    />
                                </div>
                            </div>


                            <p className="mt-3 text-primary">
                                <i className="fa-solid fa-check" /> Rozsah 180 - 350 slov
                            </p>

                            <hr />

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <p className="text-primary">Cena za 1 článek cca&nbsp;
                                        {isFree ?
                                            <>
                                                <b><s>{PricingHelper.getStoryEstimatedPrice(1, currency)} {CurrencyHelper.getSymbol(currency)} </s></b>
                                            </>
                                            :
                                            <><b>{PricingHelper.getStoryEstimatedPrice(1, currency)} {CurrencyHelper.getSymbol(currency)}</b></>}
                                    </p>

                                    {!currentUser && <p className="text-danger"><b>První článek máte ZDARMA!</b></p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    {storiesNamesArr.length >= 2 && <>
                                        <p className="text-primary">
                                            {storiesNamesArr.length} článků bude stát&nbsp;
                                            <b>zhruba {PricingHelper.getStoryEstimatedPrice(storiesNamesArr.length, currency)} {CurrencyHelper.getSymbol(currency)}</b>
                                        </p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portlet">
                        <div className="portlet-body">
                            {!currentUser &&
                                <>
                                    <div className="row mb-4">
                                        <div className="col-12 col-lg-6">
                                            <InputEmail
                                                ref={emailRef}
                                                label="Váš e-mail"
                                                value={email}
                                                onChange={(val) => setEmail(val)}
                                                required
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <InputText
                                                label="Název vašeho webu"
                                                value={newWebsiteName}
                                                onChange={(val) => setNewWebsiteName(val)}
                                                placeholder="nepovinné"
                                            />
                                        </div>
                                    </div>
                                </>}

                            {!currentUser && <CheckBox
                                checked={agreeTerms}
                                htmlLabel={<>Souhlasím s <a href="/documents/vop.pdf" target="_blank">podmínkami služby</a></>}
                                onChange={(val) => setAgreeTerms(val)}
                            />}

                            <FormErrorSummary
                                errors={errors}
                                className="mt-3"
                            />

                            <div className="text-center m-4">
                                <SubmitButton
                                    loading={submitLoading}
                                    className="btn-lg"
                                    loadingText="Odesílám Vaši objednávku..."
                                    onClick={onSubmit}
                                >
                                    <>
                                        <i className="fa-solid fa-arrow-right me-2"></i> {isFree ? "Vygenerovat článek" : "Zaplatit z kreditu"}
                                    </>
                                </SubmitButton>
                                {!isFree && <p className="text-secondary mt-3">
                                    Za 1 NS zaplatíte {PricingHelper.getGeneralArticlePricePerSP(currency)} {CurrencyHelper.getSymbol(currency)} (tj. 1800 znaků).
                                </p>}
                                <p className="text-primary mt-3">Následně můžete <b>exportovat do Wordpressu, JSON a XML</b>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))