import { first } from "lodash";
import React from "react";

interface IProps {
    errors: string[]
}

export const InputErrors = (props: IProps) => {

    const { errors } = props;

    const firstError = first(errors);

    if (firstError) {
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{firstError}</li>
            </ul>)
    }
    return null;
}