import { OrderState } from "../../Enums/OrderState";
import IOrder from "../../Interfaces/IOrder";
import { SeparateThousands } from "../Others/SeparateThousands";

interface IProps {
    order: IOrder
}

export const OrderPriceLabel = (props: IProps) => {

    const { order } = props;

    if (order.state === OrderState.Finished ||
        order.state === OrderState.Cancelled) {
        return <><SeparateThousands roundPrecision={1} number={order.price} /> Kč</> 
    }
    else {
        return <>odhad <SeparateThousands roundPrecision={1} number={order.estimatedPrice} /> Kč</>
    }
}