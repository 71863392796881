import { Button, Modal } from "react-bootstrap";

interface IProps {
    onHide(): void,
}

export const HowToWriteProductsNamesModal = (props: IProps) => {

    const { onHide } = props;

    return (
        <Modal size="lg" centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Jak zadat produktové články</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ul className="list-unstyled" style={{ fontSize: '16px' }}>
                    <li>
                        <span className="text-success">
                            <i className="fa-solid fa-check me-2" /> Na každý řádek vložte 1 název produktu. Z každého produktu vznikne 1 článek.
                        </span>
                    </li>

                    <li className="mt-2">
                        <span className="text-success">
                            <i className="fa-solid fa-check me-2" /> <b>Napište přesný název produktu, pod kterým se prodává na českém internetu</b> 
                        </span>
                    </li>

                    <li className="mt-2">
                        <span className="text-success">
                            <i className="fa-solid fa-check me-2" /> Nezapomeňte na diakritiku
                        </span>
                    </li>
                </ul>

                <h5 className="mt-5">Příklady</h5>
                <hr />
                <div style={{ fontSize: '12px' }}>

                    <p>
                        <span className="text-danger">
                            <i className="fa-solid fa-times" /> Herní myš Razer DeathAdder V2 (neuvádějte typ zboží, pokud to není přímo název produktu)
                        </span>
                    </p>

                    <p>
                        <span className="text-success">
                            <i className="fa-solid fa-check" /> Razer DeathAdder V2
                        </span>
                    </p>

                    <hr />

                    <p>
                        <span className="text-danger">
                            <i className="fa-solid fa-times" /> Robotický vysavač Roborock (není specifikován jasný produkt, je potřeba být konkrétní)
                        </span>
                    </p>

                    <p>
                        <span className="text-success">
                            <i className="fa-solid fa-check" /> Roborock S7
                        </span>
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)
}