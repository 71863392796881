import ReactLoading from "react-loading"
import { CustomDocumentTitle } from "../Layout/CustomDocumentTitle"

interface IProps {
    title?: string,
}

export const LoadingScreen = (props: IProps) => {

    const { title } = props;

    return <>
        <CustomDocumentTitle title="Načítání...">
            <div className="portlet">
                <div className="portlet-body">
                    <div className=" loadingScreen">
                        <ReactLoading type={"spin"} color={"#2196f3"} height={45} width={45} />
                    </div>

                    {title &&
                        <div className="text-center">
                            <h4 className="text-primary mb-5">{title}</h4>
                        </div>}
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}