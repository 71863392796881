import { orderBy } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { CreateWebsiteModal } from "../../Components/Websites/CreateWebsiteModal";
import { WebsitesTable } from "../../Components/Websites/WebsitesTable";
import WebsitesApiService from "../../Services/WebsitesApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import WebsiteStore from "../../Stores/WebsiteStore";

interface IProps {
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
}

const websitesApiService = new WebsitesApiService();

export const MyWebsitesPage = inject('websiteStore', 'currentUserStore')(observer((props: IProps) => {

    const [loading, setLoading] = useState(true);
    const currentUser = props.currentUserStore?.user;

    useEffect(() => {
        websitesApiService.getWebsites()
            .then(data => {
                setLoading(false);
            })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    const [showCreateWebsiteModal, setShowCreateWebsiteModal] = useState(false);

    const websites = orderBy(props.websiteStore?.getByUserId(currentUser?.id!), ["name"], ["asc"]);

    if (loading) return <LoadingScreen />

    return <>
        <CustomDocumentTitle title="Moje weby">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-globe me-2"></i> Moje weby</h3>
                        </div>
                        <div className="portlet-body">
                            {showCreateWebsiteModal &&
                                <CreateWebsiteModal
                                    onHide={() => setShowCreateWebsiteModal(false)}
                                />}

                            <button
                                onClick={() => setShowCreateWebsiteModal(true)}
                                className="btn btn-primary mb-3"
                            >
                                Přidat web
                            </button>

                            <WebsitesTable websites={websites} />
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))