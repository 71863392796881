import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"
import { NavLink } from "react-router-dom";

export const NotFoundPage = () => {
    return <>
        <CustomDocumentTitle title="Stránka nebyla nalezena">
            <div className="holder">
                <div className="wrapper ">
                    <div className="content">
                        <div className="container-fluid g-5">
                            <div className="row g-0 align-items-center justify-content-center h-100">
                                <div className="col-md-8 col-lg-6 col-xl-4 text-center">
                                    <h1 className="widget20">404</h1>
                                    <h2 className="mb-3">Stránka nebyla nalezena :-(</h2>
                                    <p className="mb-4">
                                        Vypadá to, že jste klikli na špatný odkaz...
                                    </p>
                                    <NavLink
                                        to="/"
                                        className="btn btn-label-primary btn-lg btn-widest"
                                    >
                                        Přejít na hlavní stránku
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}