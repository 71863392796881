import { observable, action, makeAutoObservable } from 'mobx'
import { filter, findIndex, orderBy, remove } from 'lodash';
import IArticle from '../Interfaces/IArticle';

export default class ArticleStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IArticle[] = [];

    @action
    addRecords(data: IArticle[]): any {
        data.forEach((record: IArticle) => this.addRecord(record));
    }

    @action
    private addRecord(record: IArticle) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getByUserId(userId: string): IArticle[] {
        return filter(this.list, coupon => {
            if (coupon.userId === userId) {
                return true;
            }
            return false;
        });
    }

    getByOrderId(orderId: string): IArticle[] {
        return filter(this.list, coupon => {
            if (coupon.orderId === orderId) {
                return true;
            }
            return false;
        });
    }

    filterByIds(ids: string[]): IArticle[] {
        return orderBy(filter(this.list, article => {
            if (ids.includes(article.id)) {
                return true;
            }
            return false;

        }), prod => [prod.createdUtc], ['desc']);
    }
}