import { Tab, Tabs } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nnfx } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { IExampleArticle } from "../../Interfaces/Articles/IExampleArticle";
import { html_beautify } from 'js-beautify';
import { ArticleImageSourceDescription } from "../Articles/ArticleImageSourceDescription";
import CurrencyHelper from "../../Utils/CurrencyHelper";

interface IProps {
    exampleArticle: IExampleArticle
}

export const ExampleProductArticle = (props: IProps) => {

    const { exampleArticle } = props;

    /* const estimatedPrice = PricingHelper.getEstimatedPrice(1, exampleArticle.paragraphCount || 0, exampleArticle.leadParagraph || true, Language.Czech, Currency.Czk);
     const realPrice = round(PricingHelper.getGeneralArticlePricePerChar(Currency.Czk) * exampleArticle.totalChars, 1);
     */
    const beautifiedHtmlString = html_beautify(exampleArticle.content,
        {
            indent_level: 0,
            wrap_line_length: 85,
            end_with_newline: false,
            indent_head_inner_html: false,
            max_preserve_newlines: 0,
            preserve_newlines: true,
            indent_size: 0,
            disabled: false,
            content_unformatted: [],
            extra_liners: ["h2", "p"]
        }
    );

    return <>
        <div className="portlet">
            <div className="portlet-header portlet-header-bordered mt-2">
                <h3 className="portlet-title">Ukázka článku "{exampleArticle.title}"</h3>
            </div>
            <div className="portlet-body">
                <Tabs defaultActiveKey="visual">
                    <Tab className="visualHtml exampleArticle" eventKey="visual" title="Vizuálně">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <td>Cena odhad</td>

                                        <td><b>{exampleArticle.estPrice} {CurrencyHelper.getSymbol(exampleArticle.currency!)}</b></td>
                                    </tr>

                                    <tr>
                                        <td>Výsledná cena</td>

                                        <td><b>{exampleArticle.finalPrice} {CurrencyHelper.getSymbol(exampleArticle.currency!)}</b></td>
                                    </tr>

                                    <tr>
                                        <td>Výsledný článek</td>

                                        <td><b>{exampleArticle.totalChars} znaků / {exampleArticle.wordsCount} slov</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {exampleArticle.photoFileName &&
                            <>
                                <img
                                    className="img-fluid mt-2"
                                    src={`/Templates/Images/Articles/${exampleArticle.photoFileName}`}
                                />
                                {exampleArticle.imageSource &&
                                    <small>
                                        <span>Zdroj obrázku:</span> <ArticleImageSourceDescription
                                            source={exampleArticle.imageSource}
                                        />
                                    </small>}
                            </>
                        }
                        <h1>{exampleArticle.title}</h1>

                        <div
                            className="mt-4"
                            dangerouslySetInnerHTML={{ __html: exampleArticle.content }}
                        ></div>
                    </Tab>
                    <Tab eventKey="raw" title="HTML">
                        <div className="p-2">
                            <SyntaxHighlighter
                                language="htmlbars"
                                style={nnfx}
                                wrapLongLines={true}
                            >
                                {beautifiedHtmlString || ''}
                            </SyntaxHighlighter>
                        </div>
                    </Tab>

                    <Tab eventKey="structure" title="Zadání článku">
                        <div className="p-2">

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Název produktu</td>

                                            <td><b>{exampleArticle.title}</b></td>
                                        </tr>

                                        <tr>
                                            <td>Jazyk</td>

                                            <td><b>Čeština</b></td>
                                        </tr>

                                        <tr>
                                            <td>Stahovat obrázek</td>

                                            <td>{exampleArticle.downloadImage ? <i className="fa-solid fa-check" /> : <i className="fa-solid fa-xmark" />} </td>
                                        </tr>

                                        {exampleArticle.downloadImage && <>
                                            <tr>
                                                <td>Povolit zkompilovat více obrázků do jednoho</td>

                                                <td>{exampleArticle.createCollage ? <i className="fa-solid fa-check" /> : <i className="fa-solid fa-xmark" />} </td>
                                            </tr>

                                            <tr>
                                                <td>Velikost obrázku (px)</td>

                                                <td>{exampleArticle.imageWidth} x {exampleArticle.imageHeight}</td>
                                            </tr>
                                        </>}
                                    </tbody>
                                </table>
                            </div>

                            <h5 className="text-black my-2">Struktura článku</h5>
                            <hr />
                            <SyntaxHighlighter
                                language="htmlbars"
                                style={nnfx}
                                wrapLongLines={true}
                                customStyle={{
                                    backgroundColor: "#f7f7f7"
                                }}
                            >
                                {exampleArticle.structure || ''}
                            </SyntaxHighlighter>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    </>
}