import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Currency } from "../../../Enums/Currency";
import IAccountTransaction from "../../../Interfaces/IAccountTransaction";
import OrderStore from "../../../Stores/OrderStore";
import CurrencyHelper from "../../../Utils/CurrencyHelper";
import { SeparateThousands } from "../../Others/SeparateThousands";
import { ShowDateTime } from "../../Others/ShowDateTime";

interface IProps {
    transaction: IAccountTransaction,
    orderStore?: OrderStore,
    currency: Currency
}

export const TransactionItem = inject('orderStore')(observer((props: IProps) => {

    const { transaction, currency } = props;

    const order = transaction.orderId ? props.orderStore?.getById(transaction.orderId!) : null;

    const showFileIsDownloading = () => {
        toast.success("Stahování zahájeno");
    }

    return <>
        <tr key={transaction.id}>

            <td>
                <ShowDateTime date={transaction.dateUtc} />
            </td>

            <td>
                <SeparateThousands roundPrecision={1} number={transaction.amount} /> {CurrencyHelper.getSymbol(currency)} {transaction.settled ? "" : "(blokace)"}
            </td>

            <td>
                {transaction.description}
            </td>

            <td>
                {order && <>
                    <Link to={`/orders/${order.id}`}>Objednávka #{order.orderId}</Link>
                </>}
                {transaction.topUp && <>Dobití</>}
            </td>

            <td>
                {transaction.invoiceIDokladId &&
                    <a
                        onClick={showFileIsDownloading}
                        className="btn btn-label-primary me-2"
                        href={`/api/accounttransactions/${transaction.id}/invoice/download`}
                        download={`Daň. doklad ${transaction.invoiceIDokladId}.pdf`}
                    >
                        <i className="fa-solid fa-download me-2"></i> Daň. doklad
                    </a>}
            </td>
        </tr>
    </>
}))