import { websiteStore } from "../App";
import { IProductArticleGenerateSettings } from "../Interfaces/IProductArticleGenerateSettings";
import IResponseData from "../Interfaces/IResponseData";
import { IStoryArticleGenerateSettings } from "../Interfaces/IStoryArticleGenerateSettings";
import ICreateWebsiteRequest from "../Interfaces/Websites/ICreateWebsiteRequest";
import IEditWebsiteRequest from "../Interfaces/Websites/IEditWebsiteRequest";
import { IWebsiteWordpressOptions } from "../Interfaces/Websites/IWebsiteWordpressOptions";
import BaseApiService from "./BaseApiService";

export default class WebsitesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getWebsites(): Promise<IResponseData> {
        return this.getRequest(`websites`, {})
            .then(response => {
                return response.data;
            })
    }


    public getWebsite(id: string): Promise<IResponseData> {
        return this.getRequest(`websites/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public createWebsite(createWebsite: ICreateWebsiteRequest): Promise<IResponseData> {
        return this.postRequest(`websites/`, createWebsite, {})
            .then(response => {
                return response.data;
            })
    }

    public editWebsite(id: string, editWebsite: IEditWebsiteRequest): Promise<IResponseData> {
        return this.putRequest(`websites/${id}`, editWebsite, {})
            .then(response => {
                return response.data;
            })
    }

    public getWebsiteArticles(id: string, takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`websites/${id}/articles`, {
            takeCount: (takeCount || 10).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public getWebsiteArticlesConcepts(id: string, takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`websites/${id}/articles/concepts`, {
            takeCount: (takeCount || 10).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public deleteWebsite(id: string): Promise<IResponseData> {
        return this.deleteRequest(`websites/${id}`, {}, {})
            .then(response => {
                if (response.data.success) {
                    websiteStore.removeRecords([id]);
                }
                return response.data;
            })
    }

    public wordpressSetup(id: string, userName: string, password: string, domain: string): Promise<IResponseData> {
        return this.postRequest(`websites/${id}/export/wordpress/setup`,
            {
                userName: userName,
                password: password,
                domain: domain,
            },
            {})
            .then(response => {
                return response.data;
            })
    }

    public removeWpSetup(id: string) {
        return this.deleteRequest(`websites/${id}/export/wordpress/setup`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public markToExport(id: string, options: IWebsiteWordpressOptions): Promise<IResponseData> {
        return this.postRequest(`websites/${id}/export/wordpress`, options, {})
            .then(response => {
                return response.data;
            })
    }

    public saveProductArticleGenerateSettings(id: string, settings: IProductArticleGenerateSettings) {
        return this.postRequest(`websites/${id}/product-article-generate-settings`, settings, {})
            .then(response => {
                return response.data;
            })
    }

    public updateWpCategories(id: string) {
        return this.postRequest(`websites/${id}/update-wp-categories`, {} , {})
            .then(response => {
                return response.data;
            })
    }

    public saveStoryArticleGenerateSettings(id: string, settings: IStoryArticleGenerateSettings) {
        return this.postRequest(`websites/${id}/story-article-generate-settings`, settings, {})
            .then(response => {
                return response.data;
            })
    }
}