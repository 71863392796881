import { ChangePasswordForm } from "../../Components/Account/ChangePasswordForm"
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"

export const ChangePasswordPage = () => {
    return <>
        <CustomDocumentTitle title="Změna hesla">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-lock me-2"></i> Změna hesla</h3>
                        </div>
                        <div className="portlet-body">
                            <ChangePasswordForm />
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}