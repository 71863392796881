import { NavLink, useNavigate } from "react-router-dom"
import { LoginForm } from "../../Components/Account/LoginForm"
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"

export const LoginPage = () => {

    const navigate = useNavigate();

    return <>
        <CustomDocumentTitle title="Přihlásit se">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-user me-2"></i> Přihlásit se</h3>
                        </div>
                        <div className="portlet-body">
                            <LoginForm onLogged={() => navigate("/")} />

                            <p className="text-center mt-3">
                                <NavLink to="/account/forgotten-password">Zapomenuté heslo</NavLink> | <NavLink to="/account/register">Registrovat se</NavLink>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}