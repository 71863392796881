import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import IUser from "../../Interfaces/IUser"
import CurrentUserApiService from "../../Services/CurrentUserApiService";

interface IProps {
    show: boolean,
    onHide(): void,
    currentUser?: IUser,
}

const currentUserApiService = new CurrentUserApiService();

export const SidebarMenu = (props: IProps) => {
    const { show, onHide, currentUser } = props;
    const [logoutLoading, setLogoutLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    }, [])

    if (!show) {
        return <></>
    }

    const onLogout = () => {
        setLogoutLoading(true);
        currentUserApiService.logout()
            .then(result => {
                if (result) {
                    onHide();
                    navigate("/account/login");
                }
            })
            .finally(() => {
                setLogoutLoading(false);
            })
    }

    return <>
        {/* BEGIN Offcanvas */}
        <div
            className={`offcanvas offcanvas-start ${show && "show"}`}
            id="offcanvas-navigation"
        >
            <div className="offcanvas-header">
                <h3 className="offcanvas-title">Clonty Writer Menu</h3>
                <button
                    className="btn btn-label-danger btn-icon"
                    data-bs-dismiss="offcanvas"
                    onClick={() => props.onHide()}
                >
                    <i className="fa fa-times" />
                </button>
            </div>
            <div
                className="offcanvas-body px-0"
                data-simplebar=""
                data-simplebar-direction="ltr"
            >
                {/* BEGIN Menu */}
                <div className="menu">
                    <div className="menu-item">
                        <Link
                            to="/"
                            className="menu-item-link"
                            onClick={() => onHide()}
                        >
                            <span
                                className="menu-item-text"
                            >
                                <i className="fa-solid fa-rocket me-1"></i>
                                Generovat články
                            </span>
                        </Link>
                    </div>

                    {currentUser &&
                        <>
                            <div className="menu-item">
                                <Link
                                    to="/orders"
                                    className="menu-item-link"
                                    onClick={() => onHide()}
                                >
                                    <span
                                        className="menu-item-text"
                                    >
                                        Moje objednávky
                                    </span>
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link
                                    to="/websites"
                                    className="menu-item-link"
                                    onClick={() => onHide()}
                                >
                                    <span
                                        className="menu-item-text"
                                    >
                                        Moje weby
                                    </span>
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link
                                    to="/articles"
                                    className="menu-item-link"
                                    onClick={() => onHide()}
                                >
                                    <span
                                        className="menu-item-text"
                                    >
                                        Články
                                    </span>
                                </Link>
                            </div>
                        </>
                    }


                    <div className="menu-item">
                        <Link
                            to="/how-it-works"
                            className="menu-item-link"
                            onClick={() => onHide()}
                        >
                            <span
                                className="menu-item-text"
                            >
                                Jak to funguje
                            </span>
                        </Link>
                    </div>

                    <div className="menu-item">
                        <Link
                            to="/faq"
                            className="menu-item-link"
                            onClick={() => onHide()}
                        >
                            <span
                                className="menu-item-text"
                            >
                                FAQ
                            </span>
                        </Link>
                    </div>

                    <div className="menu-item">
                        <Link
                            to="/examples"
                            className="menu-item-link"
                            onClick={() => onHide()}
                        >
                            <span
                                className="menu-item-text"
                            >
                                Příklady a ukázky
                            </span>
                        </Link>
                    </div>

                    <div className="menu-item">
                        <Link
                            to="/contact"
                            className="menu-item-link"
                            onClick={() => onHide()}
                        >
                            <span
                                className="menu-item-text"
                            >
                                Kontakt
                            </span>
                        </Link>
                    </div>

                    {!currentUser && <>

                        <div className="menu-item">
                            <Link
                                to="/account/login"
                                className="menu-item-link"
                                onClick={() => onHide()}
                            >
                                <span
                                    className="menu-item-text text-primary"
                                >
                                    <i className="fa-solid fa-user me-2"></i>Přihlásit se
                                </span>
                            </Link>
                        </div>
                    </>}

                    {currentUser && <>

                        <div className="menu-item">
                            <Link
                                to="/account/transactions"
                                className="menu-item-link"
                                onClick={() => onHide()}
                            >
                                <span
                                    className="menu-item-text"
                                >
                                    Pohyby na účtu
                                </span>
                            </Link>
                        </div>

                        <div className="menu-item">
                            <Link
                                to="/account"
                                className="menu-item-link"
                                onClick={() => onHide()}
                            >
                                <span
                                    className="menu-item-text text-success"
                                >
                                    <i className="fa-solid fa-user me-2"></i> Můj účet
                                </span>
                            </Link>
                        </div>

                        {logoutLoading ?
                            <>
                                <div className="menu-item">
                                    <span
                                        className="menu-item-text text-danger"
                                    >
                                        <i className="fa-solid fa-right-from-bracket me-2"></i>Odhlašování...
                                    </span>
                                </div>
                            </> :
                            <div className="menu-item">
                                <Link
                                    to="/account/login"
                                    className="menu-item-link"
                                    onClick={() => onLogout()}
                                >
                                    <span
                                        className="menu-item-text text-danger"
                                    >
                                        <i className="fa-solid fa-right-from-bracket me-2"></i>Odhlásit se
                                    </span>
                                </Link>
                            </div>}
                    </>}

                </div>
                {/* END Menu */}
            </div>
        </div>
        {/* END Offcanvas */}
    </>
}