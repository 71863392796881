export enum Country {
    Czechia = 1,
    Slovakia = 2,
    Poland = 3,
    Germany = 4,
    Italy = 6,
    Usa = 7,
    France = 8,
    Austria = 9,
    Switzerland = 10,
    Netherlands = 11,
    Luxembourg = 12,
    Belgium = 13,
    Spain = 14,
    Sweden = 15,
    Denmark = 16,
    Other = 17
}