import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class AccountTransactionsApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getAccountTransactions(takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`accounttransactions`, {
            takeCount: (takeCount || 10).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }
}