
interface IProps {
    onClick(): void,
    className?: string,
}

export const ButtonViewDetail = (props: IProps) => {

    return (
        <button
            onClick={() => props.onClick()}
            className={`btn btn-label-success ${props.className}`}
        >
            <i className="fa-solid fa-eye"></i> Zobrazit
        </button>
    )
}