import { Currency } from "../Enums/Currency";
import { ExportStatus } from "../Enums/ExportStatus";
import { IArticleRegenerateImageRequest } from "../Interfaces/Articles/IArticleRegenerateImageRequest";
import { ICreateArticleConcept } from "../Interfaces/Articles/ICreateArticlesConceptsRequest";
import { IGenerateTitlesRequest } from "../Interfaces/Articles/IGenerateTitlesRequest";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class ArticlesApiService extends BaseApiService {

    constructor() {
        super();
    }

    public getArticles(takeCount?: number, skipCount?: number): Promise<IResponseData> {
        return this.getRequest(`articles`, {
            takeCount: (takeCount || 10).toString(),
            skipCount: (skipCount || 0).toString(),
        })
            .then(response => {
                return response.data;
            })
    }

    public getArticle(id: string): Promise<IResponseData> {
        return this.getRequest(`articles/${id}`, {})
            .then(response => {
                return response.data;
            })
    }

    public editArticle(id: string, title: string, content: string): Promise<IResponseData> {
        return this.putRequest(`articles/${id}`, {
            title: title,
            content: content,
        }, {})
            .then(response => {
                return response.data;
            })
    }

    public cancelArticle(id: string): Promise<IResponseData> {
        return this.postRequest(`articles/${id}/cancel`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public createConcepts(model: ICreateArticleConcept): Promise<IResponseData> {
        return this.postRequest(`articles/concepts`, model, {})
            .then(response => {
                return response.data;
            })
    }

    public generateTitles(request: IGenerateTitlesRequest): Promise<IResponseData> {
        return this.postRequest(`articles/generate-titles`, request, {})
            .then(response => {
                return response.data;
            })
    }

    public findImage(id: string): Promise<IResponseData> {
        return this.postRequest(`articles/${id}/find-image`, {}, {})
            .then(response => {
                return response.data;
            })
    }

    public changeExportStatus(id: string, status: ExportStatus): Promise<IResponseData> {
        return this.putRequest(`articles/${id}/export-status`, { status }, {})
            .then(response => {
                return response.data;
            })
    }

    public getEstPriceForProductArticle(structure: string, currency: Currency): Promise<IResponseData> {
        return this.postRequest(`articles/type/product/estimated-price`,
            {
                structure: structure,
                currency: currency,
            },
            {})
            .then(response => {
                return response.data;
            })
    }

    public validateProductArticleStructure(structure: string): Promise<IResponseData> {
        return this.postRequest(`articles/type/product/validate/structure`, JSON.parse(JSON.stringify(structure)), {})
            .then(response => {
                return response.data;
            })
    }

    public regenerateImage(id: string, form: IArticleRegenerateImageRequest): Promise<IResponseData> {
        return this.postRequest(`articles/${id}/regenerate-image`, form, {})
            .then(response => {
                return response.data;
            })
    }
}