import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"

export const TutorialPage = () => {
    return <>
        <CustomDocumentTitle title="Návody">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Návody</h3>
                        </div>
                        <div className="portlet-body">
                            
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}