
export const WebsiteWordpressSetupTutorial = () => {

    return <>
        <h5 className="text-primary">
            Je potřeba mít Wordpress 5.6 nebo vyšší
        </h5>
        <p>V případě, že váš web běží na nižší verzi, je potřeba Wordpress aktualizovat.</p>

        <hr />
        <h5 className="text-primary">
            Postup propojení
        </h5>

        <ol>
            <li>Přihlaste se do Wordpressu</li>
            <li>V bočním menu vyberte <b>"Uživatelé"</b></li>
            <li>Následně u vašeho uživatele <b>Upravit</b></li>
            <li>Níže na stránce najdete <b>"Hesla aplikací"</b></li>
            <li>Zadejte název aplikace např. Clonty Writer</li>
            <li>Klikněte <b>"Přidat nové heslo do aplikace"</b></li>
            <li>Vygenerované heslo použijte k propojení s Clonty Writer. Včetně mezer.</li>
        </ol>

        <hr />

        <h5 className="text-primary mt-4">
            Obrázkový návod
        </h5>

        <p className="my-4">Vygenerování hesla pro Aplikace.</p>
        <img
            className="img-fluid"
            src="/Templates/Images/Steps/WP/1.png"
            width={400}
        />

        <p className="my-4">Vygenerované heslo si zkopírujte a použijte k propojení. Včetně mezer.</p>
        <img
            className="img-fluid"
            src="/Templates/Images/Steps/WP/2.png"
            width={400}
        />
    </>
}