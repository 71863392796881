
interface IProps {
    label: string,
    required: boolean,
}

export const InputLabel = (props: IProps) => {

    const { label, required } = props;

    if (label.length === 0) {
        return null;
    }

    return (<label className={'form-control-label'} >
        <span dangerouslySetInnerHTML={{ __html: label }}></span> {required ? <span className="tx-danger">*</span> : null}
    </label>)
}