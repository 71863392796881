import { inject, observer } from "mobx-react";
import CurrentUserApiService from "../../Services/CurrentUserApiService"
import CurrentUserStore from "../../Stores/CurrentUserStore";
import React, { useEffect, useRef, useState } from "react";
import InputEmail from "../Inputs/InputEmail";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import IInputField from "../../Interfaces/IInputField";
import { NavLink } from "react-router-dom";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import IFormError from "../../Interfaces/IFormError";
import { FormSuccessSummary } from "../Others/FormSuccessSummary";
import { SubmitButton } from "../Inputs/SubmitButton";

interface IProps {
    currentUserStore?: CurrentUserStore,
}

const currentUserApiService = new CurrentUserApiService();

export const ForgottenPasswordForm = inject('currentUserStore')(observer((props: IProps) => {

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<IFormError[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    },[]);

    const emailRef = useRef<InputEmail>(null);

    var arr: React.RefObject<IInputField>[] = [emailRef];

    function onSubmit() {
        const isValid = ValidationHelper.validateInputs(arr);

        if (isValid) {
            setLoading(true);
            currentUserApiService.sendResetPasswordEmail(email)
                .then(response => {
                    if (response.data.success) {
                        setSubmitted(true);
                    }
                    else {
                        setErrors(response.data.formErrors);
                    }
                    setLoading(false);
                })
        }
    }

    return (<>
        <InputEmail
            ref={emailRef}
            label="Email"
            value={email}
            onChange={(val) => setEmail(val)}
            required
        />
        {submitted && <FormSuccessSummary messages={[ "Pokud účet existuje, poslali jsme vám odkaz pro nastavení nového hesla"]} />}

        <FormErrorSummary
            errors={errors}
        />

        <SubmitButton
            className="mt-3 w-100"
            onClick={onSubmit}
            loading={loading}
        >
            Obnovit heslo
        </SubmitButton>

        <p className="text-center mt-3">
            <NavLink to="/account/login">Přihlásit se</NavLink> | <NavLink to="/account/register">Registrovat se</NavLink>
        </p>
    </>)
}))