import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { NotFoundScreen } from "../../Components/Others/NotFoundScreen";
import WebsitesApiService from "../../Services/WebsitesApiService";
import ArticleStore from "../../Stores/ArticleStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import Pagination from 'react-js-pagination';
import SelectBox from "../../Components/Inputs/SelectBox";
import { GeneratorHelper } from "../../Utils/GeneratorHelper";
import { PricingHelper } from "../../Utils/PricingHelper";
import { FormErrorSummary } from "../../Components/Others/FormErrorSummary";
import { SubmitButton } from "../../Components/Inputs/SubmitButton";
import IArticle from "../../Interfaces/IArticle";
import { concat, findIndex, orderBy, remove, round } from "lodash";
import { ArticleConceptItem } from "../../Components/Articles/ArticleConceptItem";
import ArticlesApiService from "../../Services/ArticlesApiService";
import { toast } from "react-toastify";
import IFormError from "../../Interfaces/IFormError";
import { CreateArticleConceptTr } from "../../Components/Articles/CreateArticleConceptTr";
import OrdersApiService from "../../Services/OrdersApiService";
import { OrderState } from "../../Enums/OrderState";
import { GenerateArticleTitlesModal } from "../../Components/Articles/GenerateArticleTitlesModal";
import { WebsiteBulkConceptsModal } from "../../Components/Websites/WebsiteBulkConceptsModal";
import { LanguageHelper } from "../../Utils/LanguageHelper";
import Swal from "sweetalert2";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import CurrencyHelper from "../../Utils/CurrencyHelper";

interface IProps {
    websiteStore?: WebsiteStore,
    articleStore?: ArticleStore,
    currentUserStore?: CurrentUserStore,
}

const websitesApiService = new WebsitesApiService();
const articlesApiService = new ArticlesApiService();
const ordersApiService = new OrdersApiService();

export const WebsiteArticlesConceptsPage = inject('websiteStore', 'articleStore', 'currentUserStore')(observer((props: IProps) => {

    const { id } = useParams();

    const user = props.currentUserStore?.user!;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const countToShow = 100;
    const [skipCount, setSkipCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    let [articles, setArticles] = useState<IArticle[]>([]);
    const [errors, setErrors] = useState<IFormError[]>([]);
    const [defSectionsCount, setDefSectionsCount] = useState<number | undefined>();
    const [showGenerateTitles, setShowGenerateTitles] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showBulkConceptsModal, setShowBulkConceptsModal] = useState(false);

    articles = orderBy(articles, a => [a.createdUtc], 'desc');

    useEffect(() => {
        setLoading(true);

        websitesApiService.getWebsiteArticlesConcepts(id!, countToShow, skipCount)
            .then(data => {
                setLoading(false);

                const articles = data.records.articles.items;
                setArticles(articles);
                setTotalCount(data.others.get("totalCount"));
            })

        return () => {
            websitesApiService.cancelRequests();
        }
    }, [currentPage])

    const website = props.websiteStore?.getById(id!);

    if (loading) return <LoadingScreen />
    if (!website) return <NotFoundScreen />

    const onPageChange = (pageNumber: number) => {
        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        setSkipCount(skipCount);
        setCurrentPage(pageNumber);
    }

    const estPrice = PricingHelper.getEstimatedPriceForArticles(articles, user.currency);

    const onArticleChanged = (article: IArticle) => {
        var index = findIndex(articles, { id: article.id });
        let art = articles;
        art[index] = article;
        setArticles([...art]);
    }

    const onCancelArticle = (article: IArticle) => {
        articlesApiService.cancelArticle(article.id)
            .then(data => {
                if (data.success) {
                    let art = articles;
                    remove(art, article);
                    setArticles([...art]);
                    toast.success("Koncept zahozen");
                }
            })
    }

    const setDefaultSectionsCount = (val: number) => {
        Swal.fire({
            icon: "info",
            title: "Opravdu chcete přenastavit všechny koncepty?",
            text: "Tato funkce přenastaví všechny rozsahy u konceptů níže.",
            confirmButtonText: "Ano, přenastavit",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: "Zrušit",
        }).then(result => {
            if (result.isConfirmed) {
                setDefSectionsCount(val);

                articles.forEach(article => {
                    article.sectionsCount = val;
                })

                setArticles([...articles]);
            }
        })
    }

    const onSubmit = () => {
        if (articles.length === 0) {
            setErrors([{ message: "Nemáte žádné koncepty" }])
            return;
        }
        else {
            setErrors([]);
        }

        setSubmitLoading(true);
        ordersApiService.createOrderFromConcepts({
            articles: articles,
            websiteId: website.id,
        })
            .then(data => {
                setSubmitLoading(false);
                if (data.success) {
                    const order = data.records.orders.items[0];

                    toast.success("Objednávka vytvořena");

                    if (order.state === OrderState.PendingPayment) {
                        navigate(`/account`)
                    }
                    else {
                        navigate(`/orders/${order.id}`)
                    }
                }
            })
    }

    return <>
        <CustomDocumentTitle title={`Generování článků pro web - ${website?.name}`}>
            {showGenerateTitles && <GenerateArticleTitlesModal
                onHide={() => setShowGenerateTitles(false)}
                websiteId={website.id}
                onGenerated={(newArticles) => {
                    let ar = articles;
                    ar = concat(ar, newArticles);
                    setArticles([...ar]);
                    setShowGenerateTitles(false);
                }}
            />}

            {showBulkConceptsModal && <WebsiteBulkConceptsModal
                onHide={() => setShowBulkConceptsModal(false)}
                onCreated={(newArticles) => {
                    let ar = articles;
                    ar = concat(ar, newArticles);
                    setArticles([...ar]);
                    setShowBulkConceptsModal(false);
                }}
                websiteId={website.id}
            />}

            <div className="row">
                <div className="col-12 col-xl-8">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2 d-block">
                            <h3 style={{ maxWidth: 420 }} className="portlet-title float-lg-start">
                                <i className="fa-solid fa-list me-2" /> Generování článků - web <b>{website?.name}</b>
                            </h3>

                            <div className="mt-4 mt-lg-0 float-lg-end d-flex">
                                <button
                                    className="btn btn-label-primary"
                                    onClick={() => setShowBulkConceptsModal(true)}
                                >
                                    <i className="fa-solid fa-plus me-2"></i>Hromadně vložit nadpisy
                                </button>
                                <button
                                    className="btn btn-label-success ms-2"
                                    onClick={() => setShowGenerateTitles(true)}
                                >
                                    <i className="fa-solid fa-bolt me-2"></i>Návrh nadpisů dle tématu
                                </button>
                            </div>
                        </div>

                        <div className="portlet-body">
                            <p><b>Hromadný generátor článků pro Váš web {website.name}</b>. Usnadněte si práci návrhem nadpisů dle tématu.</p>

                            {articles.length > 1 &&
                                <div className="row mb-4">
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <SelectBox
                                            label="Přenastavit rozsah"
                                            options={GeneratorHelper.getArticleRangeOptions()}
                                            value={defSectionsCount}
                                            defaultValue="Vybrat rozsah"
                                            onChange={(val) => setDefaultSectionsCount(val)}
                                            id="subtitlesCount"
                                        />
                                    </div>
                                </div>}

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: 420 }}>Nadpis</th>

                                            <th style={{ minWidth: 140 }}>
                                                Rozsah
                                            </th>

                                            <th style={{ minWidth: 100 }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <>
                                            <CreateArticleConceptTr
                                                onCreated={(article) => setArticles([...articles, article])}
                                                websiteId={website.id}
                                            />

                                            {articles.map(article => {
                                                return <ArticleConceptItem
                                                    key={article.id}
                                                    article={article}
                                                    onChange={(article) => onArticleChanged(article)}
                                                    onCancel={() => onCancelArticle(article)}
                                                />
                                            })}
                                        </>
                                    </tbody>
                                </table>
                            </div>

                            {totalCount > countToShow && <Pagination
                                pageRangeDisplayed={countToShow}
                                activePage={currentPage}
                                itemsCountPerPage={countToShow}
                                totalItemsCount={totalCount}
                                onChange={(val) => onPageChange(val)}
                                linkClass="page-link"
                                itemClass="page-item"
                                innerClass="pagination justify-content-center mt-3"
                                hideDisabled={true}
                                hideFirstLastPages={true}
                            />}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Web</td>

                                            <td>
                                                <b>{website.name}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Jazyk (dle webu)</td>

                                            <td>
                                                <b>{LanguageHelper.getLabel(website.language!)}</b>
                                            </td>
                                        </tr>

                                        {estPrice > 0 &&
                                            <>
                                                <tr>
                                                    <td>Průměrná cena za 1 článek</td>

                                                    <td>
                                                        <b>{round(estPrice / articles.length, 1)} {CurrencyHelper.getSymbol(user.currency)}</b> (vyšší odhad)
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>Odhad ceny za {articles.length} článků</td>

                                                    <td>
                                                        <b>{estPrice} {CurrencyHelper.getSymbol(user.currency)}</b>
                                                    </td>
                                                </tr>
                                            </>}
                                    </tbody>
                                </table>
                            </div>

                            <FormErrorSummary
                                errors={errors}
                                className="mt-3"
                            />

                            <div className="text-center m-4">
                                <SubmitButton
                                    loading={submitLoading}
                                    className="btn-lg"
                                    loadingText="Odesílám Vaši objednávku..."
                                    onClick={onSubmit}
                                >
                                    <>
                                        <i className="fa-solid fa-arrow-right me-2"></i>Zaplatit z kreditu
                                    </>
                                </SubmitButton>
                                <p className="text-secondary mt-3">Za 1 NS zaplatíte {PricingHelper.getGeneralArticlePricePerSP(user.currency)} {CurrencyHelper.getSymbol(user.currency)} (tj. 1800 znaků)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))