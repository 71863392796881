import { ignoreNextOnError } from "@sentry/browser/types/helpers";
import { off } from "process";
import { Tooltip } from "react-tooltip";
import { ExportStatus } from "../../Enums/ExportStatus";
import IArticle from "../../Interfaces/IArticle";
import IWebsite from "../../Interfaces/IWebsite";

interface IProps {
    article: IArticle,
    website: IWebsite,
}

export const WebsiteWpArticleExportState = (props: IProps) => {

    const { article, website } = props;

    switch (article?.wpExportStatus) {
        case ExportStatus.Pending:
            if (article.wpPostId) {
                return <>
                    <a id={`wpLink-${article.id}`} href={`${website.domain}?p=${article.wpPostId}`} target="_blank">
                        <span className="badge badge-warning">Ve frontě</span>
                    </a>

                    <Tooltip anchorId={`wpLink-${article.id}`} place="bottom">
                        Zobrazit na webu
                    </Tooltip>
                </>;
            }
            return <span className="badge badge-warning">Ve frontě</span>

        case ExportStatus.Finished:
            return <>
                <a id={`wpLink-${article.id}`} href={`${website.domain}?p=${article.wpPostId}`} target="_blank">
                    <span className="badge badge-success">Exportováno</span>
                </a>

                <Tooltip anchorId={`wpLink-${article.id}`} place="bottom">
                    Zobrazit na webu
                </Tooltip>
            </>;

        case ExportStatus.Cancelled:
            return <span className="badge badge-danger">Zrušeno</span>

        case ExportStatus.Failed:
            return <span className="badge badge-danger">Nezdařilo se</span>

        case ExportStatus.RejectedByUser:
            return <span className="badge badge-danger">Neexportovat</span>

        default:
            return <span className="badge badge-secondary">Neexportováno</span>
    }
}