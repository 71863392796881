
export default class DateTimeHelper {

    public static convertUTCDateToLocalDate(date: Date | undefined) {

        if (!date) {
            return undefined;
        }
        date = new Date(date);

        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }
}