import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { OrderDetailContent } from "../../Components/Orders/OrderDetailContent";
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { NotFoundScreen } from "../../Components/Others/NotFoundScreen";
import { OrderState } from "../../Enums/OrderState";
import OrdersApiService from "../../Services/OrdersApiService";
import ArticleStore from "../../Stores/ArticleStore";
import OrderStore from "../../Stores/OrderStore";
import WebsiteStore from "../../Stores/WebsiteStore";
import Pagination from 'react-js-pagination';
import { OrderArticlesExportsButtons } from "../../Components/Orders/OrderArticlesExportsButtons";
import { OrderArticlesTable } from "../../Components/Orders/OrderArticlesTable";

interface IProps {
    orderStore?: OrderStore,
    articleStore?: ArticleStore,
    websiteStore?: WebsiteStore,
}

const orderApiService = new OrdersApiService();

export const OrderDetailPage = inject('orderStore', 'articleStore', 'websiteStore')(observer((props: IProps) => {

    const { id } = useParams();

    const countToShow = 20;
    const [loading, setLoading] = useState(true);
    const [skipCount, setSkipCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [articlesIds, setArticlesIds] = useState<string[]>([]);

    useEffect(() => {
        orderApiService.getOrder(id!)
            .then(data => {
                setLoading(false);
            })

        return () => {
            orderApiService.cancelRequests();
        }
    }, [])

    useEffect(() => {
        setLoading(true);

        orderApiService.getOrderArticles(id!, countToShow, skipCount)
            .then(data => {
                setLoading(false);

                const articles = data.records.articles.items;
                setArticlesIds(articles.map(s => s.id));
                setTotalCount(data.others.get("totalCount"));
            })

        return () => {
            orderApiService.cancelRequests();
        }
    }, [currentPage])

    const order = props.orderStore?.getById(id!);
    const website = order?.websiteId ? props.websiteStore?.getById(order?.websiteId) : undefined;

    if (loading) return <LoadingScreen />
    if (!order) return <NotFoundScreen />


    const cancelOrder = () => {
        Swal.fire({
            icon: "warning",
            title: "Opravdu chcete zrušit objednávku?",
            text: "Některé články nebudou napsány. Akce je nevratná.",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Ano, zrušit",
            cancelButtonText: "Ne"
        }).then(result => {
            if (result.isConfirmed) {
                orderApiService.cancelOrder(order.id)
                    .then(data => {

                    })
            }
        })
    }

    const articles = props.articleStore?.filterByIds(articlesIds);

    const onPageChange = (pageNumber: number) => {
        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        setSkipCount(skipCount);
        setCurrentPage(pageNumber);
    }

    return <>
        <CustomDocumentTitle title={`Objednávka #${order?.orderId}`}>
            <div className="portlet">
                <div className="portlet-header portlet-header-bordered mt-2">
                    <h3 className="portlet-title"><i className="fa-sharp fa-solid fa-cart-shopping me-2"></i> Objednávka #{order?.orderId}</h3>

                    {(order.state === OrderState.PendingPayment || order.state == OrderState.PendingProcess) &&
                        <a onClick={cancelOrder} className="link-danger clickable float=end">Zrušit</a>}
                </div>
                <div className="portlet-body">
                    <OrderDetailContent
                        order={order}
                        website={website}
                    />
                </div>
            </div>

            <div className="portlet">
                <div className="portlet-header portlet-header-bordered mt-2 d-block">
                    <div className="row">
                        <div className="col col-lg-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-list me-2" /> Články</h3>
                        </div>

                        <div className="col col-lg-10">
                            {order.finishedArticlesCount > 0 && (order.state === OrderState.Finished ||
                                order.state === OrderState.Cancelled) &&
                                <OrderArticlesExportsButtons website={website} order={order} />}
                        </div>
                    </div>
                </div>

                <div className="portlet-body">
                    <OrderArticlesTable
                        order={order}
                        articles={articles || []}
                    />

                    {totalCount > countToShow && < Pagination
                        pageRangeDisplayed={countToShow}
                        activePage={currentPage}
                        itemsCountPerPage={countToShow}
                        totalItemsCount={totalCount}
                        onChange={(val) => onPageChange(val)}
                        linkClass="page-link"
                        itemClass="page-item"
                        innerClass="pagination justify-content-center mt-3"
                        hideDisabled={true}
                        hideFirstLastPages={true}
                    />}
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))