import { Cms } from "../Enums/Cms";
import { Language } from "../Enums/Language";
import { WpOpenStatus } from "../Enums/Websites/WpOpenStatus";
import { WpStatus } from "../Enums/Websites/WpStatus";
import IOptionData from "../Interfaces/IOptionData";
import IWebsite from "../Interfaces/IWebsite";
import { IWebsiteWordpressCategory } from "../Interfaces/Websites/IWebsiteWordpressCategory";

export class WebsiteHelper {

    public static getCmsOptions(): IOptionData[] {
        return [
            { label: "Wordpress", value: Cms.Wordpress },
            { label: "Shoptet", value: Cms.Shoptet },
            { label: "Jiný", value: Cms.Other }
        ]
    }

    public static getWebsiteOptions(websites: IWebsite[]): IOptionData[] {
        let options: IOptionData[] = [];

        websites.forEach(web => {
            options.push({ label: web.name, value: web.id })
        });
        return options;
    }

    public static getWpStatusOptions(): IOptionData[] {
        return [
            { label: "Publikováno", value: WpStatus.Publish },
            { label: "Soukromý", value: WpStatus.Private },
            { label: "Naplánovat", value: WpStatus.Future },
            { label: "Koncept", value: WpStatus.Draft },
            { label: "Čekající na schválení", value: WpStatus.Pending },

        ]
    }

    public static getWpOpenStatusOptions(): IOptionData[] {
        return [
            { label: "Povolit", value: WpOpenStatus.Open },
            { label: "Zakázat", value: WpOpenStatus.Closed },
        ]
    }

    public static getLanguagesOptions(): IOptionData[] {
        return [
            { label: "Čeština", value: Language.Czech },
            { label: "Slovenština", value: Language.Slovak },
            { label: "Angličtina", value: Language.English },
        ]
    }

    public static convertWpCategoriesToOptions(categories: IWebsiteWordpressCategory[] | undefined): IOptionData[] {

        if (!categories) {
            return [];
        }
        else {
            let options: IOptionData[] = [];

            categories.forEach(cat => {
                options.push({ label: cat.name, value: cat.id });
            });
            return options;
        }
    }
}