import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IUser from "../../Interfaces/IUser"
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import CurrencyHelper from "../../Utils/CurrencyHelper";
import { round } from "lodash";

interface IProps {
    user: IUser,
    currentUserStore?: CurrentUserStore,
}

const currentUserApiService = new CurrentUserApiService();

export const MyAccountPortlet = inject('currentUserStore')(observer((props: IProps) => {
    const { user } = props;

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            currentUserApiService.cancelRequests();
        }
    }, []);

    function handleLogout() {
        currentUserApiService.logout()
            .then(result => {
                if (result) {
                    navigate("/account/login");
                }
            })
    }

    return <>
        <div className="portlet">
            <div className="portlet-header portlet-header-bordered mt-2">
                <h3 className="portlet-title"><i className="fa-solid fa-user me-2"></i> Můj účet</h3>
            </div>
            <div className="portlet-body">
                <ul className="list-unstyled">
                    <li>Uživatel: <b>{user?.name}</b></li>
                    <li>Email: <b>{user?.email}</b></li>
                    <li>Stav kreditu: <b>{round(user?.creditBalance,1)} {CurrencyHelper.getSymbol(user.currency)}</b></li>
                </ul>

                <button
                    onClick={() => navigate("/account/change-password")}
                    className="btn btn-secondary me-2">
                    <i className="fa-solid fa-lock me-2"></i> Změnit heslo
                </button>

                <button
                    onClick={() => navigate("/account/transactions")}
                    className="btn btn-secondary me-2">
                    Pohyby na účtu
                </button>

                <button
                    onClick={handleLogout}
                    className="btn btn-danger">
                    <i className="fa-solid fa-right-from-bracket me-2"></i>Odhlásit se
                </button>
            </div>
        </div>
    </>
}))