import { useState } from "react";
import { ArticleState } from "../../Enums/ArticleState";
import IArticle from "../../Interfaces/IArticle";
import IOrder from "../../Interfaces/IOrder";
import { ArticleDetailModal } from "../Articles/ArticleDetailModal";
import { ArticleStateLabel } from "../Articles/ArticleStateLabel";
import { ArticleTypeIcon } from "../Articles/ArticleTypeIcon";
import { ButtonViewDetail } from "../Inputs/ButtonViewDetail";

interface IProps {
    article: IArticle,
    order: IOrder,
}

export const OrderArticleItem = (props: IProps) => {

    const { article, order } = props;

    const [showDetail, setShowDetail] = useState(false);

    return <>
        <tr key={article.id}>

            <td>
                <ArticleTypeIcon article={article} />
                {article.title}
            </td>

            <td>
                <ArticleStateLabel
                    error={article.error}
                    orderState={order.state}
                    articleState={article.state}
                    cancellationReason={article.cancellationReason}
                />
            </td>

            <td>{article.contentLength || '-'} znaků / {article.wordsCount || '-'} slov</td>

            <td>
                {article.state === ArticleState.Finished &&
                    <ButtonViewDetail
                        onClick={() => setShowDetail(true)} />
                }

                {showDetail &&
                    <ArticleDetailModal
                        article={article}
                        onHide={() => setShowDetail(false)}
                    />}
            </td>
        </tr>
    </>
}