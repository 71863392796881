import React from 'react';
import Select from 'react-select'
import IInputField from '../../Interfaces/IInputField';
import IOptionData from '../../Interfaces/IOptionData';
 
interface IProps {
    uid: string,
    required?: boolean;
    label: string;
    options: IOptionData[];
    selected: IOptionData[],
    onChange(selectedIds: string[], key: string): void,
    isClearable?: boolean,
}

interface IState {
    errors: string[],
}

// export default class InputDouble extends React.Component<IProps, IState> implements IInputField {
export default class MultiSelectBox extends React.Component<IProps, IState> implements IInputField {

    constructor(props: IProps) {
        super(props);
     
        this.state = {
            errors: [],
        };
    }

    validate(value = this.props.selected || []) {
        this.setState({
            errors: this.getErrors(value),
        })
    }

    getErrors(value = this.props.selected || []) {
        let errors: string[] = [];

        if (errors.length != 0) {
            return errors;
        }

        this.validateInput(value, errors);
        return errors;
    }

    isValid() {
        return this.getErrors().length == 0;
    }

    validateInput(value: IOptionData[], errors: string[]) {
        if (this.props.required === false && (this.props.selected || []).length === 0) {
            return;
        }

        if (this.props.required && (value || '').length == 0) {
            errors.push("Toto pole je povinné");
        }     
    }

    focus() {
        
    }

    handleChange(selected: any) {
        const ids = selected.map((s: IOptionData) => s.value);
        this.props.onChange(ids, this.props.uid);
    }

    renderError() {
        if (this.state.errors.length === 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        const { options, selected } = this.props;

        return (
            <>
                <Select
                    options={options}
                    isMulti={true}
                    value={selected}
                    onChange={this.handleChange.bind(this)}
                    noOptionsMessage={() => "Žádné možnosti"}
                    placeholder={'Vyberte'}
                    isClearable={this.props.isClearable}
                    className="input-multiselect"
                    classNamePrefix="multiselect"
                />
            </>
        );
    }

    renderLabel() {
        if ((this.props.label || '').length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="mb-3">
                <div className={`form-group position-relative mb-0`}>
                    {this.renderLabel()}
                    {this.renderInput()}
                </div>
                {this.renderError()}
            </div>
        );
    }
}