import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IProps {
    onHide(): void,
}

export const ExitModal = (props: IProps) => {

    const { onHide } = props;
  
    return (
        <Modal size="lg" centered={true} show={true} onHide={onHide}>      
            <Modal.Body>
                <div className="card mb-4">
                    <div className="card-body position-relative">
                        <div
                            className="position-absolute clickable"
                            style={{
                                right: 20,
                                fontSize: 20
                            }}
                            onClick={onHide}
                        >
                            <i className="fa-solid fa-xmark" />
                        </div>

                        <div className="py-5 my-5 text-center">
                            <h1 className="text-black">První článek máte ZDARMA!</h1>
                          
                            <p className="text-primary mt-4">
                                <span>
                                    <i className="fa-solid fa-check"></i> Chytrý export do Wordpressu, JSON a XML
                                </span>
                            </p>

                            <div className="mt-4">
                                <Link onClick={onHide} id="goGenerate" to="/generate/articles" className="btn btn-primary btn-lg me-1">
                                    <i className="fa-solid fa-arrow-right me-2"></i>Vyzkoušet ZDARMA
                                </Link>

                                <Link onClick={onHide} id="examplesButton" to="/examples" className="btn btn-secondary btn-lg ms-1">
                                    Příklady a ukázky
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>)
}