import { filter, find } from "lodash"
import { useState } from "react"
import { ListGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { HomePageFooterCard } from "../Components/AboutApp/HomePageFooterCard"
import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"
import { ExampleArticleHelper } from "../Utils/ExampleArticleHelper"
import { Bag, FileText } from "react-bootstrap-icons"
import { IExampleArticle } from "../Interfaces/Articles/IExampleArticle"
import { ArticleType } from "../Enums/ArticleType"
import { ExampleGeneralArticle } from "../Components/Examples/ExampleGeneralArticle"
import { ExampleProductArticle } from "../Components/Examples/ExampleProductArticle"
import * as Scroll from 'react-scroll';

export const ExamplesPage = () => {

    const articles = ExampleArticleHelper.getArticles();
    const [activeKey, setActiveKey] = useState<string>("Nike - boty, které chcete mít");

    const generalArticles = filter(articles, (ar: IExampleArticle) => {
        if (ar.type === ArticleType.General) {
            return true;
        }
        return false;
    })

    const productArticles = filter(articles, (ar: IExampleArticle) => {
        if (ar.type === ArticleType.Product) {
            return true;
        }
        return false;
    })

    const article = find(articles, { title: activeKey })!;
    const navigate = useNavigate();


    let scroll = Scroll.animateScroll;

    const setActiveArticle = (key: string) => {
        setActiveKey(key)
        Scroll.scroller.scrollTo("example", {
            duration: 800,
            delay: 0,
           // smooth: 'easeInOutQuart',
        });
    }

    return <>
        <CustomDocumentTitle title="Příklady a ukázky">
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Vygenerované články</h3>
                        </div>
                        <div className="portlet-body">
                            <p>Vygenerovali jsme pro vás články, které vám dodají představu, jak takový článek vypadá, kolik stál a jaké byly parametry zadání.</p>
                            <hr />

                            <h5 className="text-black mb-3">
                                <FileText className="me-1 mb-1" />Klasické články
                            </h5>
                            <ListGroup
                                activeKey={article.title}
                                defaultActiveKey="#link1"
                            >
                                <>
                                    {generalArticles.map((article) => {
                                        return (<ListGroup.Item
                                            key={article.title}
                                            action
                                            active={article.title === activeKey}
                                            onClick={() => setActiveArticle(article.title)}
                                        >
                                            {article.title}
                                        </ListGroup.Item>)
                                    })}
                                </>
                            </ListGroup>

                            <hr />
                            <h5 className="text-black my-3">
                                <Bag className="me-1 mb-1" />Produktové články
                            </h5>
                            <ListGroup
                                activeKey={article.title}
                                defaultActiveKey="#link2"
                            >
                                <>
                                    {productArticles.map((article) => {
                                        return (<ListGroup.Item
                                            key={article.title}
                                            action
                                            active={article.title === activeKey}
                                            onClick={() => setActiveArticle(article.title)}
                                        >
                                            {article.title}
                                        </ListGroup.Item>)
                                    })}
                                </>
                            </ListGroup>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-8" id="example">
                    {article.type === ArticleType.General && <ExampleGeneralArticle
                        exampleArticle={article}
                    />}

                    {article.type === ArticleType.Product && <ExampleProductArticle
                        exampleArticle={article}
                    />}
                </div>
            </div>

            <div className="d-lg-none">
                <HomePageFooterCard />
            </div>
        </CustomDocumentTitle >
    </>
}