import { observable, action, reaction, computed, makeObservable } from 'mobx'
import IUser from '../Interfaces/IUser';

export default class CurrentUserStore {

    user: IUser | undefined = undefined;

    setCurrentUser(user: IUser) {
        if (user) {
            this.user = user;

            if (user) {
                window.smartsupp('name', user.name);
                window.smartsupp('email', user.email);
                window.smartsupp('variables', {
                    Id: user.id,
                    CreditBalance: user.creditBalance,
                    Vs: user.vs,
                });
            }
        }
    }

    logout() {
        this.user = undefined;
    }

    constructor() {
        makeObservable(this, {
            user: observable,
            setCurrentUser: action,
            logout: action
        })
    }
}
