import React, { useState } from "react"
import { InputErrors } from "./InputErrors";
import { InputLabel } from "./InputLabel";

interface IProps {
    label: string,
    onChange(value: string): void,
    required?: boolean,
    value: string,
    maxLength?: number,
    minLength?: number,
    rows?: number,
    placeholder?: string,
    disabled?: boolean,
    validate?(): boolean,
    className?: string,
    onBlur?():void,
}

export const NewTextarea = React.forwardRef((props: IProps, ref: React.Ref<HTMLTextAreaElement>) => {

    const [errors, setErrors] = useState<string[]>([]);
    const { label, onChange, required, value, maxLength, minLength, rows, placeholder, disabled} = props;
   // const input = useRef < HTMLTextAreaElement>();

    const [used, setUsed] = useState(false);

    /*
    useEffect(() => {
        if (used) validate();
    }, [value, validate])*/

    function focus() {
       // input.current.focus();
    }

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        onChange(event.target.value);
        setUsed(true);
       // validate();
    }

    function validate() {
        let errors: string[] = [];

        if (disabled) {
            return;
        }
        if (required && value.length === 0) {
            errors.push('Toto pole je vyžadováno');
        }
        if (minLength && value.length < minLength) {
            errors.push(`Minimální délka je ${minLength}`);
        }
        setErrors(errors);
    }

    const onBlur = () => {
        validate();
        if (props.onBlur) {
            props.onBlur();
        }
    }

    return (
        <>
            <InputLabel label={label} required={required!} />

            <textarea
                ref={ref}
                maxLength={maxLength}
                onChange={(event) => handleChange(event)}
                value={value}
                rows={rows || 3}
                className={`form-control data-hj-allow ${props.className} ${errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={placeholder}
                data-clarity-unmask="True"
                disabled={disabled}
                onBlur={onBlur}
            />

            <InputErrors errors={errors} />
        </>)
})