import { OrderState } from "../../Enums/OrderState";

interface IProps {
    state: OrderState,
}

export const OrderStateLabel = (props: IProps) => {

    const { state } = props;

    switch (state) {
        case OrderState.PendingPayment:
            return <span className="badge badge-warning">Čekající na platbu</span>;

        case OrderState.PendingProcess:
            return <span className="badge badge-warning">Zpracovává se</span>;

        case OrderState.Finished:
            return <span className="badge badge-success">Dokončeno</span>;

        case OrderState.Cancelled:
            return <span className="badge badge-danger">Zrušeno</span>;

        default:
    }

    return <></>
}