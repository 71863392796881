import { AxiosError } from "axios";
import { filter, sumBy } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BillingForm } from "../../Components/Account/BillingForm";
import { MyAccountPortlet } from "../../Components/Account/MyAccountPortlet";
import { PaymentTransferQRPortlet } from "../../Components/Account/Payments/PaymentTransferQRPortlet"
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { OrdersTable } from "../../Components/Orders/OrdersTable";
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { NotFoundScreen } from "../../Components/Others/NotFoundScreen";
import { OrderState } from "../../Enums/OrderState";
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import OrdersApiService from "../../Services/OrdersApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import OrderStore from "../../Stores/OrderStore";

interface IProps {
    currentUserStore?: CurrentUserStore,
    orderStore?: OrderStore
}

const currentUserApiService = new CurrentUserApiService();
const ordersApiService = new OrdersApiService();

export const TopUpPage = inject('currentUserStore', 'orderStore')(observer((props: IProps) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadingOrders, setLoadingOrders] = useState(true);

    useEffect(() => {
        currentUserApiService.getCurrentUser()
            .then(user => {
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                if (error.response && (error.response!.status === 404 ||
                    error.response!.status === 401)) {
                    navigate("/account/login");
                }
            })

        ordersApiService.getOrders()
            .finally(() => {
                setLoadingOrders(false);
            })

        return () => {
            currentUserApiService.cancelRequests();
            ordersApiService.cancelRequests();
        }
    }, []);

    const currentUser = props.currentUserStore!.user;

    if (loading || loadingOrders) return <LoadingScreen />
    if (!currentUser) {
        return <NotFoundScreen />
    }

    const notPaidOrders = filter(props.orderStore?.getByUserId(currentUser.id), { state: OrderState.PendingPayment });
    const amountToDeposit = sumBy(notPaidOrders, s => s.estimatedPrice) - currentUser.creditBalance;

    return <>
        <CustomDocumentTitle title="Můj účet">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6">
                    <PaymentTransferQRPortlet
                        amount={amountToDeposit}
                        vs={currentUser!.vs}
                        currency={currentUser!.currency}
                    />

                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-address-book me-2"></i> Fakturační údaje</h3>
                        </div>
                        <div className="portlet-body">
                            {!currentUser.billing && <p className="text-primary">Bez vyplněných fakturačních údajů nelze zpracovat platby.</p>}
                            <BillingForm />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-8 col-lg-6">
                    <MyAccountPortlet user={currentUser} />

                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-sharp fa-solid fa-cart-shopping me-2"></i> Objednávky k zaplacení</h3>
                        </div>
                        <div className="portlet-body">
                            {notPaidOrders.length === 0 && <>
                                <div className="my-5 text-center">
                                    <h3 className="text-success">Všechny objednávky jsou uhrazené</h3>
                                </div>
                            </>}

                            {notPaidOrders.length > 0 && <>
                                <OrdersTable orders={notPaidOrders || []} />
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))