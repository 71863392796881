import IOrder from "../../Interfaces/IOrder";
import { OrderItem } from "./OrderItem";

interface IProps {
    orders: IOrder[],
}

export const OrdersTable = (props: IProps) => {

    const { orders } = props;

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th></th>

                        <th>Datum</th>

                        <th>Stav</th>

                        <th>Zpracováno</th>

                        <th>Cena</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {orders.map(order => {
                        return <OrderItem key={order.id} order={order} />
                    })}
                </tbody>
            </table>
        </div>)
}