import { html_beautify } from "js-beautify";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import IArticle from "../../Interfaces/IArticle";
import ArticlesApiService from "../../Services/ArticlesApiService";
import InputText from "../Inputs/InputText";
import { NewTextarea } from "../Inputs/NewTextarea";
import { SubmitButton } from "../Inputs/SubmitButton";

interface IProps {
    content: string,
    article: IArticle,
    onSaveChanged(content: string): void,
}

const articleApiService = new ArticlesApiService();

export const ArticleEditContent = (props: IProps) => {

    const { article } = props;

    const beautifiedHtmlString = html_beautify(props.content,
        {
            indent_level: 0,
            wrap_line_length: 150,
            end_with_newline: false,
            indent_head_inner_html: false,
            max_preserve_newlines: 0,
            preserve_newlines: true,
            indent_size: 0,
            disabled: false,
            content_unformatted: [],
            extra_liners: ["h2", "p"]
        }
    );


    const [title, setTitle] = useState(article.title);
    const [content, setContent] = useState(beautifiedHtmlString);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return (() => {
            articleApiService.cancelRequests();
        })
    }, [])

    const onSave = () => {
        articleApiService.editArticle(article.id, title, content)
            .then(data => {
                if (data.success) {
                    toast.success("Změny uloženy");
                    props.onSaveChanged(data.others.get("content"));                
                }
                else {
                    toast.error("Došlo k chybě");
                }
            })
    }

    return <>
        <div className="row">
            <div className="col-12 col-lg-10">
                <InputText
                    label="Nadpis"
                    value={title}
                    onChange={(val) => setTitle(val)}
                    required
                />

                <div className="mt-4">
                    <NewTextarea
                        label="Obsah"
                        onChange={(val) => setContent(val)}
                        value={content}
                        rows={25}
                    />
                </div>
            </div>
        </div>

        <SubmitButton
            className="mt-3"
            loading={loading}
            onClick={onSave}
        >
            Uložit změny
        </SubmitButton>
    </>
} 