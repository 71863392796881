import { useState } from "react";
import { Link } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { SelectTypeArticleToGenerateModal } from "../Generator/SelectTypeArticleToGenerateModal";

export const HomePageFooterCard = () => {

    const [showSelectArticleType, setShowSelectArticleType] = useState(false);

    return <div className="card mb-4" style={{ backgroundColor: "rgb(116 255 58 / 10%)" }}>
        <div className="card-body">
            {showSelectArticleType && <SelectTypeArticleToGenerateModal
                onHide={() => setShowSelectArticleType(false)}
            />}
            <div className="py-5 my-4 text-center">
                <h1 className="text-black">První článek máte ZDARMA!</h1>

                <p className="text-primary mt-4">
                    <span>
                        <i className="fa-solid fa-check"></i> Chytrý export do Wordpressu, JSON a XML
                    </span>
                </p>

                <div className="mt-5">
                    <span id="goGenerate" onClick={() => setShowSelectArticleType(true)} className="btn btn-primary btn-lg d-block d-sm-inline me-sm-1">
                        <i className="fa-solid fa-arrow-right me-2"></i>Vyzkoušet ZDARMA
                    </span>

                    <Tooltip
                        anchorId="goGenerate"
                        place="bottom"
                        html={`<span class="text-warning">Nemáte strach, že Vás nahradí umělá inteligence?</span>`}
                    />

                    <Link id="examplesButton" to="/examples" className="btn btn-secondary d-block d-sm-inline btn-lg ms-sm-1 mt-2 mt-sm-0">
                        Příklady a ukázky
                    </Link>

                    <Tooltip
                        anchorId="examplesButton"
                        place="bottom"
                        html={`<span class="text-warning">To musíte vidět! :D</span>`}
                    />
                </div>
            </div>
        </div>
    </div>
}