import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify";
import IOrder from "../../Interfaces/IOrder";
import OrdersApiService from "../../Services/OrdersApiService";
import WebsiteStore from "../../Stores/WebsiteStore";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { WebsiteHelper } from "../../Utils/WebsiteHelper";
import SelectBox from "../Inputs/SelectBox";

interface IProps {
    onHide(): void,
    order: IOrder,
    websiteStore?: WebsiteStore,
}

const ordersApiService = new OrdersApiService();

export const OrderAssignToWebsiteModal = inject('websiteStore')(observer((props: IProps) => {

    useEffect(() => {
        return () => {
            ordersApiService.cancelRequests();
        }
    }, [])

    const { onHide, order } = props;

    const websiteIdRef = useRef<SelectBox>(null);

    const websites = props.websiteStore?.getByUserId(order.userId);

    const [websiteId, setWebsiteId] = useState();

    const onSubmit = () => {

        const inputs = [websiteIdRef];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            ordersApiService.assignToWebsite(order.id, websiteId!)
                .then(response => {
                    if (response.success) {
                        toast.success("Články byly přiřazeny k webu");
                        onHide();
                    }
                });
        }
    }

    return (
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Přiřadit články k webu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectBox
                    ref={websiteIdRef}
                    label="Web"
                    options={WebsiteHelper.getWebsiteOptions(websites || [])}
                    onChange={(val) => setWebsiteId(val)}
                    required
                    defaultValue="Vyberte"
                    value={websiteId}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} variant="primary">
                    Přiřadit k webu
                </Button>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)
}));