import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { ArticleState } from "../../Enums/ArticleState";
import { ExportStatus } from "../../Enums/ExportStatus";
import IArticle from "../../Interfaces/IArticle";
import IWebsite from "../../Interfaces/IWebsite";
import ArticlesApiService from "../../Services/ArticlesApiService";
import { ArticleDetailModal } from "../Articles/ArticleDetailModal";
import { ArticleTypeIcon } from "../Articles/ArticleTypeIcon";
import { ButtonViewDetail } from "../Inputs/ButtonViewDetail";
import { WebsiteWpArticleExportState } from "./WebsiteWpArticleExportState";

interface IProps {
    article: IArticle,
    website: IWebsite,
}

const articlesApiService = new ArticlesApiService();

export const WebsiteArticleItem = (props: IProps) => {

    const { article, website } = props;

    const [showDetail, setShowDetail] = useState(false);

    const onExportReject  = () => {
        articlesApiService.changeExportStatus(article.id, ExportStatus.RejectedByUser)
            .then(data => {
                if (data.success) {
                    toast.success("Článek nebude exportován");
                }
            })
    }

    const onExportPending = () => {
        articlesApiService.changeExportStatus(article.id, ExportStatus.Pending)
            .then(data => {
                if (data.success) {
                    toast.success("Článek bude exportován");
                }
            })
    }

    const onExportEnable = () => {
        articlesApiService.changeExportStatus(article.id, ExportStatus.Enabled)
            .then(data => {
                if (data.success) {
                    toast.success("Export povolen");
                }
            })
    }

    return <>
        <tr key={article.id}>

            <td>
                <ArticleTypeIcon article={article} />
                {article.title}
            </td>

            <td>
                <WebsiteWpArticleExportState
                    article={article}
                    website={website}
                />
            </td>

            <td>{article.contentLength || '-'} znaků / {article.wordsCount || '-'} slov</td>

            <td className="d-flex">
                {article.state === ArticleState.Finished &&
                    <ButtonViewDetail
                        onClick={() => setShowDetail(true)} />
                }

                {showDetail &&
                    <ArticleDetailModal
                        article={article}
                        onHide={() => setShowDetail(false)}
                    />}

                <Dropdown className="ms-2">
                    <Dropdown.Toggle variant="label-secondary" data-boundary="window" className="ml-1 h-100" id="dropdownProductOptions">
                        <span className="mr-2">Možnosti</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {website.wordpressSetuped && (!article.wpExportStatus || article.wpExportStatus !== ExportStatus.Pending) && < Dropdown.Item
                            className="text-primary"
                            onClick={onExportPending}
                        >
                            <><i className="fa-solid fa-upload me-2"></i>Zařadit k exportu</>
                        </Dropdown.Item>}

                        {article.wpExportStatus === ExportStatus.RejectedByUser && < Dropdown.Item
                            className="text-primary"
                            onClick={onExportEnable}
                        >
                            <>Povolit export</>
                        </Dropdown.Item>}

                        {article.wpExportStatus !== ExportStatus.Finished && article.wpExportStatus !== ExportStatus.RejectedByUser && !article.wpPostId && <Dropdown.Item
                            className="text-danger"
                            onClick={onExportReject}
                        >
                            <><i className="fa-solid fa-xmark me-2"></i>Neexportovat</>
                        </Dropdown.Item>}

                        {article.wpPostId && <Dropdown.Item
                            className="text-success"
                            onClick={() => window.open(`${website.domain}?p=${article.wpPostId}`, '_blank', 'noreferrer')}
                        >
                            <i className="fa-solid fa-arrow-up-right-from-square me-2"></i>Zobrazit na webu
                        </Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    </>
}