import { observable, action, makeAutoObservable } from 'mobx'
import { filter, find, findIndex, remove } from 'lodash';
import IOrder from '../Interfaces/IOrder';

export default class OrderStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable list: IOrder[] = [];

    @action
    addRecords(data: IOrder[]): any {
        data.forEach((record: IOrder) => this.addRecord(record));
    }

    @action
    private addRecord(record: IOrder) {
        if (!record) {
            return;
        }

        const index = findIndex(this.list, { id: record.id });
        if (index >= 0) {
            this.list.splice.apply(this.list, [index, 1, record]);
        } else {
            this.list.push(record);
        }
    }

    @action
    removeRecords(ids: string[]) {
        remove(this.list, (record) => {
            return ids.indexOf(record.id) >= 0;
        });
    }

    getByUserId(userId: string): IOrder[] {
        return filter(this.list, coupon => {
            if (coupon.userId === userId) {
                return true;
            }
            return false;
        });
    }

    getById(id: string): IOrder | undefined {
        return find(this.list, { id: id });
    }
}