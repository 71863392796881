import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IArticle from "../../Interfaces/IArticle";
import IFormError from "../../Interfaces/IFormError";
import ArticlesApiService from "../../Services/ArticlesApiService";
import { ValidationHelper } from "../../Utils/ValidationHelper";
import { SubmitButton } from "../Inputs/SubmitButton";
import { FormErrorSummary } from "../Others/FormErrorSummary";
import InputNumber from "../Inputs/InputNumber";
import { IArticleRegenerateImageRequest } from "../../Interfaces/Articles/IArticleRegenerateImageRequest";
import CheckBox from "../Inputs/CheckBox";

interface IProps {
    onHide(): void,
    article: IArticle,
    onRefreshImage(): void,
}

const articlesApiService = new ArticlesApiService();

export const ProductArticleRegenerateModal = (props: IProps) => {

    const { article } = props;

    const [formErrors, setFormErrors] = useState<IFormError[]>([]);

    const imageWidthRef = useRef<InputNumber>(null);
    const imageHeightRef = useRef<InputNumber>(null);

    const [form, setForm] = useState<IArticleRegenerateImageRequest>({
        allowCreateCollage: article.allowCreateImageCollage,
        width: article.imageWidth || 1200,
        height: article.imageHeight || 630,
    })

    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        const inputs = [imageWidthRef, imageHeightRef];

        setFormErrors([]);

        if (ValidationHelper.isValid(inputs)) {
            setLoading(true);

            articlesApiService.regenerateImage(article.id, form)
                .then(data => {
                    setLoading(false);

                    if (data.success) {
                        props.onRefreshImage();
                        toast.success("Obrázek byl přegenerován");
                        props.onHide();
                    }
                    else {
                        data.formErrors.map(err => {
                            formErrors.push(err);
                        })
                    }
                });
        }
    }

    const onHide = () => {
        if (!loading) {
            props.onHide();
        }
        else {
            toast.warning("Počkejte na dokončení generování");
        }
    }

    const onChange = (key: string, value: any) => {
        setForm({
            ...form,
            [key]: value,
        });
    }

    return <>
        <Modal backdrop={false} centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Přegenerování obrázku</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading && <>
                    <p>Můžete si změnit velikost obrázku a my vám jej vygenerujeme znovu.</p>

                    <hr />

                    <CheckBox
                        label="Povolit zkompilovat více obrázků do jednoho"
                        checked={form.allowCreateCollage}
                        onChange={(val) => onChange('allowCreateCollage', val)}
                    />

                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <InputNumber
                                ref={imageWidthRef}
                                label="Šířka (px)"
                                value={form.width}
                                onChange={(val) => onChange('width', val)}
                                minValue={50}
                                required
                                maxValue={1500}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <InputNumber
                                ref={imageHeightRef}
                                label="Výška (px)"
                                value={form.height}
                                onChange={(val) => onChange('height', val)}
                                minValue={50}
                                required
                                maxValue={1500}
                            />
                        </div>
                    </div>
                </>}

                {loading && <div className="my-5 text-center">
                    <h5 className="text-primary">Probíhá generování</h5>
                    <p><b>Může trvat i 1 minutu. Vyčkejte prosím na této stránce.</b></p>
                </div>}

                <FormErrorSummary
                    errors={formErrors}
                />
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    loading={loading}
                    onClick={onSubmit}
                >
                    Přegenerovat obrázek
                </SubmitButton>

                <Button
                    onClick={onHide}
                    variant="secondary"
                    disabled={loading}
                >
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}