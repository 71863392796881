import DocumentTitle from 'react-document-title';
import { ReactNode } from 'react';
import { ScrollRestoration } from 'react-router-dom';

interface IProps {
    title: string,
    children?: ReactNode;
}

export const CustomDocumentTitle = (props: IProps) => {
    let title = props.title;
    title += ' | Clonty AI Writer';
    return (
        <DocumentTitle title={title}>
            <>
                <ScrollRestoration />
                <>{props.children}</>
            </>     
        </DocumentTitle>
    );
}
    