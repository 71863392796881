import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"
import { NavLink } from "react-router-dom";

export const ErrorPage = () => {
    return <>
        <CustomDocumentTitle title="Došlo k chybě">
            <div className="holder">
                <div className="wrapper ">
                    <div className="content">
                        <div className="container-fluid g-5">
                            <div className="row g-0 align-items-center justify-content-center h-100">
                                <div className="col-md-8 col-lg-6 col-xl-4 text-center">
                                    <h2 className="mb-3"><i className="fa-solid fa-bug me-2"></i> Něco se pokazilo...</h2>
                                    <p className="mb-4">
                                        Chybu jme zaznamenali.
                                    </p>
                                    <NavLink
                                        to="/"
                                        className="btn btn-label-primary btn-lg btn-widest"
                                    >
                                        Přejít na hlavní stránku
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}