import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import StoreUtil from '../Stores/StoreUtil';

axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    if (response.data.format === 'universal') {
        if (response.data.others) {
            response.data.others = new Map(Object.entries(response.data.others || []));
        }
        StoreUtil.process(response.data);
    }
    return response;
}, (error: AxiosError) => {
    if (axios.isCancel(error) === false) {
        if (error.response) {
            if (error.response.status === 403) {
                /* Swal.fire(
                     'K této akci nemáš patřičná oprávnění',
                     error.response.data.error,
                     'error')*/
            }
            if (error.response.status === 503) {
                /*  Swal.fire(
                      'Aplikace je dočasně nedostupná',
                      'Zřejmě probíhá údržba aplikace. Zkuste to později.',
                      'error')*/
            }
            if (error.response.status === 401 && window.location.pathname !== '/account/login') {
                //window.location.assign('/ucet/prihlaseni');
            }
            /*   if (error.response.data.format === 'universal') {
                   StoreUtil.process(error.response.data);
               }*/
        }
    }
    return Promise.reject(error);
});