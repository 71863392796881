import { useNavigate } from "react-router-dom";
import IOrder from "../../Interfaces/IOrder";
import { ButtonViewDetail } from "../Inputs/ButtonViewDetail";
import { SeparateThousands } from "../Others/SeparateThousands";
import { ShowDateTime } from "../Others/ShowDateTime";
import { OrderPriceLabel } from "./OrderPriceLabel";
import { OrderStateLabel } from "./OrderStateLabel";

interface IProps {
    order: IOrder,
}

export const OrderItem = (props: IProps) => {
    var navigate = useNavigate();

    const { order } = props;

    return <>
        <tr key={order.id}>

            <td>#{order.orderId}</td>

            <td>
                <ShowDateTime date={order.createdUtc} />
            </td>

            <td>
                <OrderStateLabel state={order.state} />
            </td>

            <td>
                {order.finishedArticlesCount}/{order.articlesCount}
            </td>

            <td>
                <OrderPriceLabel order={order} />
            </td>

            <td>
                <ButtonViewDetail
                    onClick={() => navigate(`/orders/${order.id}`)}
                />
            </td>
        </tr>
    </>
}