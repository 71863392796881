import { CustomDocumentTitle } from "../Layout/CustomDocumentTitle"

export const NotFoundScreen = () => {

    return <>
        <CustomDocumentTitle title="Nenalezeno">
            <div className="portlet">
                <div className="portlet-body text-center">
                    Nenalezeno
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}