import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { ErrorPage } from "../../Pages/ErrorPage";
import { NotFoundPage } from "../../Pages/NotFoundPage";

export const ErrorBoundary = () => {
    let error = useRouteError();
    console.error(error);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <NotFoundPage />
        }
    }
    return <ErrorPage />
}