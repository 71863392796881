import { CustomDocumentTitle } from "../Components/Layout/CustomDocumentTitle"

export const FaqPage = () => {
    return <>
        <CustomDocumentTitle title="FAQ - nejčastější otázky">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">FAQ - nejčastější otázky</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Vliv na SEO a indexaci</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b className="text-primary">Google nedokáže detekovat články napsané pomocí AI. </b>Obzvlášť v češtině to bude trvat věky, než se mu to podaří. Doporučujeme psát články na takové témata,
                                kdy AI má dostatek informací a tudíž neopakuje věty. <b className="text-primary">Vytvořili jsme čistě vygenerovaný web a Google jej úspěšně indexuje</b>.</p>
                            <img src="/Templates/Images/Steps/8.png" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Jsou články i s obrázky?</h3>
                        </div>
                        <div className="portlet-body">
                            <p><b>Ano! Ke každému článku se snažíme dohledat nejrelevantnější obrázek. Obrázky dohledáváme ZDARMA.</b> </p>

                            <p>Využíváme služeb CC0 fotobank. Takže obrázky můžete použít i komerčně.</p>
                            <img src="\Templates\Images\Articles\coconut.jpg" className="img-fluid" />
                            <p className="mt-3">Ovšem může se stát, že zrovna pro váš článek nebude dostupný z důvodu specifického tématu.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Práva ke článkům</h3>
                        </div>
                        <div className="portlet-body">
                            <p>
                                Obsah je generován pomocí AI modelů společnosti OpenAI. Více o podmínkách použití najdete na <a target="_blank" href="https://openai.com/policies/terms-of-use">https://openai.com/policies/terms-of-use</a>.
                                Takto generovaný obsah je následně upraven vrstvou Clonty Text Layer.
                            </p>

                            <p>
                                <b>Vámi vygenerované články patří vám. Můžete je použít komerčně i nekomerčně. K libovolnému použití.</b>
                            </p>

                            <p>
                                Vygenerovaný obsah si ovšem zkontrolujte, zda neporušuje platné zákony. Jste odpovědní za obsah, včetně zajištění toho, že neporušuje žádné platné zákony. 
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title">Práva k obrázkům</h3>
                        </div>
                        <div className="portlet-body">
                            <p>
                                <b>Obrázky můžou mít různé licence. Obrázky nejčastěji pocházejí z free fotobank.</b> U každého obrázku je uvedená informace o zdroji. Podle zdroje se určuje licence daného obrázku.
                            </p>

                            <p>Aplikace využívá fotobanky, jejichž obrázky můžete použít k nekomerčnímu, ale i ke komerčnímu použití.</p>

                            <ul>
                                <li>Pexels - <a href="https://www.pexels.com/license/" target="_blank">https://www.pexels.com/license/</a> </li>
                                <li>Pixabay - <a href="https://pixabay.com/service/license/" target="_blank">https://pixabay.com/service/license/</a></li>
                                <li>Unsplash - <a href="https://unsplash.com/license" target="_blank">https://unsplash.com/license</a></li>
                            </ul>

                            <p>
                                <b>Obrázky k produktovým článkům</b> - Tyto obrázky pocházejí z vyhledávání na internetu a jejich použití je na vás. Spoustu obrázků jsou veřejně dostupné a často pocházejí přímo od výrobce daného produktu.
                            </p>

                            <p>Vždy si ovšem zkontrolujte, zda obrázek neporušuje platné zákony. Jste odpovědní za obsah, včetně zajištění toho, že neporušuje žádné platné zákony.</p>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}