import { Language } from "../Enums/Language";
import IOptionData from "../Interfaces/IOptionData";
import { IProductArticleGenerateSettings } from "../Interfaces/IProductArticleGenerateSettings";

export class ProductArticleGeneratorHelper {

    public static replaceStructureVariables(input: string): string {
        const replacements: Record<string, string> = {
            "{leadParagraph}": "<p>Toto je úvodní odstavec. Krátký a informativní úvod, který zaujme čtenáře a nastíní obsah článku. Nesnaží se zajít do detailů.</p>",
            "{detailsParagraphs}": "<p>Toto je první odstavec, který se zabývá vlastnostmi produktu.</p><p>V závislosti na daném produktu a množství informací, tak se může objevit další odstavce jako je tento.</p>",
            "{customerExperience}": "<p>Tento odstavec shrnuje zkušenosti zákazníků s tímto produktem. Vyjadřuje pocity zákazníků.</p>",
            "{conclusion}": "<p>Tento odstavec shrnuje hlavní téma článku a motivuje čtenáře k nákupu.</p>",
            "{youtubeVideo}": "<img src=\"/Templates/Images/youtubeFrame.png\" class=\"img-fluid\" />",
            "{productName}": "Název produktu"
            // Add more replacements as needed
        };

        // Create a regular expression pattern to match all the variables in the replacements object
        const pattern = new RegExp(Object.keys(replacements).map(key => `\\${key}`).join('|'), 'g');

        // Use the pattern with the input string to replace all occurrences of the variables
        const output = input.replace(pattern, (match) => {
            // Use the matched string as the key to retrieve the replacement from the `replacements` object,
            // or return the original match if no replacement is found
            return replacements[match] || match;
        });

        return output;
    }

    public static getDefaultStructure(): string {

      return `{leadParagraph}

{youtubeVideo}

<h2>Popis a vlastnosti</h2>
{detailsParagraphs}

<h2>Zkušenosti zákazníků</h2>
{customerExperience}

<h2>Závěr</h2>
{conclusion}`;
    }

    public static getProductArticleLanguageOptions(): IOptionData[] {
        return [
            { label: "Čeština", value: Language.Czech },
        ]
    }

    public static getDefaultSettings(): IProductArticleGenerateSettings {
        return {
            downloadImage: true,
            allowCreateImageCollage: false,
            imageHeight: 630,
            imageWidth: 1200,
            structure: this.getDefaultStructure(),
        }
    }
}