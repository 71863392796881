import { round } from "lodash"
import StringHelper from "../../Utils/StringHelper"

interface IProps {
    number: number,
    roundPrecision?: number,
}

export const SeparateThousands = (props: IProps) => {
    return <span dangerouslySetInnerHTML={{ __html: StringHelper.separateThousands(round(props.number, props.roundPrecision || 0))}}></span>
}