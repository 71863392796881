import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import CurrentUserApiService from "../../Services/CurrentUserApiService";

const userApiService = new CurrentUserApiService();

export const EmailConfirmPage = () => {

    const { email } = useParams();
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {

        userApiService.verifyEmail(email || '', token || '')
            .then(data => {
                if (data.success) {
                    toast.success("Email potvrzen");
                }
                else {
                    data.formErrors.map(err => {
                        toast.error(err.message);
                    })
                }
                navigate("/");
            })

        return () => {
            userApiService.cancelRequests();
        }
    }, [])

    return <>
        {loading && <LoadingScreen />}
    </>
}