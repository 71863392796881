import IInputField from '../../Interfaces/IInputField';
import InputText from './InputText';

export default class InputPassword extends InputText implements IInputField {
    handleChange(e: any) {
        this.validate(e.target.value);
        this.props.onChange!(e.target.value);
    }

    validateInput(value: string, errors: string[]) {
        super.validateInput(value, errors);
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="password"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.handleChange.bind(this)}
                name="password"
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}