import axios from "axios";

interface IProps {
    imageUrl: string;
    className?: string,
    btnVariant?: string,
    text: string,
    fileName: string,
}

export const ImageDownloadButton = (props: IProps) => {

    const onDownload = () => {
        axios({
            url: props.imageUrl, //your url
            method: 'GET',
            responseType: 'blob', // important

        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', props.fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    return (
        <a onClick={onDownload} className={`btn btn-${props.btnVariant} ${props.className}`}>
            <i className="fa-solid fa-download me-2"></i>{props.text}
        </a>
    );
};