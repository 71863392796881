import { AxiosError } from "axios";
import { filter, sumBy } from "lodash";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { currentUserStore } from "../../App";
import { BillingForm } from "../../Components/Account/BillingForm";
import { MyAccountPortlet } from "../../Components/Account/MyAccountPortlet";
import { PaymentTransferQRPortlet } from "../../Components/Account/Payments/PaymentTransferQRPortlet"
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { OrdersTable } from "../../Components/Orders/OrdersTable";
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import { NotFoundScreen } from "../../Components/Others/NotFoundScreen";
import { OrderState } from "../../Enums/OrderState";
import CurrentUserApiService from "../../Services/CurrentUserApiService";
import OrdersApiService from "../../Services/OrdersApiService";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import OrderStore from "../../Stores/OrderStore";

interface IProps {
    currentUserStore?: CurrentUserStore,
    orderStore?: OrderStore
}

const currentUserApiService = new CurrentUserApiService();
const ordersApiService = new OrdersApiService();

export const MyAccountPage = inject('currentUserStore', 'orderStore')(observer((props: IProps) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadingOrders, setLoadingOrders] = useState(true);

    useEffect(() => {
        currentUserApiService.getCurrentUser()
            .then(user => {
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                if (error.response && (error.response!.status === 404 ||
                    error.response!.status === 401)) {
                    navigate("/account/login");
                }
            })

        ordersApiService.getOrders()
            .finally(() => {
                setLoadingOrders(false);
            })

        return () => {
            currentUserApiService.cancelRequests();
            ordersApiService.cancelRequests();
        }
    }, []);

    const currentUser = props.currentUserStore!.user;

    if (loading || loadingOrders) return <LoadingScreen />
    if (!currentUser) {
        return <NotFoundScreen />
    }

    const notPaidOrders = filter(props.orderStore?.getByUserId(currentUser.id), { state: OrderState.PendingPayment });
    const amountToDeposit = sumBy(notPaidOrders, s => s.estimatedPrice) - currentUser.creditBalance;

    const handleDeleteAccount = () => {
        Swal.fire({
            icon: 'error',
            title: 'Opravdu chcete smazat svůj účet?',
            text: 'Ztratíte přístup k účtu. Daňové doklady si před smazáním stáhněte v seznamu pohybů na účtu.',
            confirmButtonText: 'Ano, smazat účet',
            showCancelButton: true,
            cancelButtonText: "Ne, zatím ponechat účet",
            focusCancel: true,
        }).then(result => {
            if (result.isConfirmed) {
                currentUserApiService.deleteAccount()
                    .then(data => {
                        if (data.success) {
                            toast.success("Účet byl smazán");
                            navigate("/");
                            currentUserStore.logout();
                        }
                    })
            }
        })
    }

    return <>
        <CustomDocumentTitle title="Můj účet">           
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                    <PaymentTransferQRPortlet
                        amount={amountToDeposit}
                        vs={currentUser!.vs}
                        currency={currentUser!.currency}
                    />

                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-address-book me-2"></i> Fakturační údaje</h3>
                        </div>
                        <div className="portlet-body">
                            {!currentUser.billing && <>
                                <div className="alert alert-label-danger">
                                    <i className="fa-solid fa-address-book me-2"></i><b>Před odesláním platby si vyplňte fakturační údaje</b>. Bez nich nelze připsat kredit..
                                </div>
                            </>}
                            <BillingForm />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2">
                    <MyAccountPortlet user={currentUser} />

                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-sharp fa-solid fa-cart-shopping me-2"></i> Objednávky k zaplacení</h3>
                        </div>
                        <div className="portlet-body">
                            {notPaidOrders.length === 0 && <>
                                <div className="my-5 text-center">
                                    <h3 className="text-success">Všechny objednávky jsou uhrazené</h3>
                                </div>
                            </>}

                            {notPaidOrders.length > 0 && <>
                                <OrdersTable orders={notPaidOrders || []} />
                            </>}
                        </div>
                    </div>

                    <div className="float-end">
                        <button
                            onClick={handleDeleteAccount}
                            className="btn btn-outline-danger ms-2">
                            <i className="fa-solid fa-trash me-2"></i> Smazat účet
                        </button>
                    </div>
                </div>
            </div>
        
        </CustomDocumentTitle>
    </>
}))