import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import IWebsite from "../../Interfaces/IWebsite";
import WebsitesApiService from "../../Services/WebsitesApiService";
import { ButtonViewDetail } from "../Inputs/ButtonViewDetail";
import { EditWebsiteModal } from "./EditWebsiteModal";

interface IProps {
    website: IWebsite,
}

const websiteApiService = new WebsitesApiService();

export const WebsiteItem = (props: IProps) => {

    const { website } = props;
    const [showEditModal, setShowEditModal] = useState(false);

    const navigate = useNavigate();

    const onDelete = () => {
        Swal.fire({
            icon: 'warning',
            text: `Opravdu chcete smazat web "${website.name}"?`,
            showCancelButton: true,
            cancelButtonText: "Zrušit",
            showConfirmButton: true,
            confirmButtonText: "Ano, smazat",
        })
            .then(result => {
                if (result.isConfirmed) {
                    websiteApiService.deleteWebsite(website.id)
                        .then(data => {
                            if (data.success) {
                                toast.success("Web smazán");
                            }
                        })
                }
            })

    }

    return <>
        <tr key={website.id}>

            <td>{website.name}</td>

            <td>
                <a href={website.domain} target="_blank">
                    {website.domain}
                </a>
            </td>

            <td className="d-flex">
                {showEditModal &&
                    <EditWebsiteModal
                        website={website}
                        onHide={() => setShowEditModal(false)}
                    />
                }

                <button
                    onClick={() => navigate(`/websites/${website.id}/generate`)}
                    className="btn btn-label-primary me-2"
                >
                    <i className="fa-solid fa-rocket me-1"></i>Generovat články
                </button>

                <ButtonViewDetail
                    className="me-2"
                    onClick={() => navigate(`/websites/${website.id}`)}
                />

                <Dropdown>
                    <Dropdown.Toggle variant="label-secondary" data-boundary="window" className="ml-1 h-100" id="dropdownProductOptions">
                        <span className="mr-2">Možnosti</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            className="text-primary"
                            onClick={() => setShowEditModal(true)}
                        >
                            <i className="fa-regular fa-pen-to-square me-2"></i>Upravit
                        </Dropdown.Item>

                        <Dropdown.Item
                            className="text-danger"
                            onClick={onDelete}
                        >
                            <i className="fa-solid fa-trash me-2"></i>Smazat
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    </>
}