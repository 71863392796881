import IArticle from "../../Interfaces/IArticle";
import IOrder from "../../Interfaces/IOrder";
import { OrderArticleItem } from "./OrderArticleItem";

interface IProps {
    articles: IArticle[],
    order: IOrder
}

export const OrderArticlesTable = (props: IProps) => {

    const { articles, order } = props;

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                    
                        <th>Nadpis</th>

                        <th>Stav</th>

                        <th></th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {articles.map(article => {
                        return <OrderArticleItem
                            key={article.id}
                            order={order}
                            article={article}
                        />
                    })}
                </tbody>
            </table>
        </div>)
}