import React from 'react';
import IInputField from '../../Interfaces/IInputField';

interface IProps {
    length?: number,
    maxLength?: number,
    minLength?: number,
    required?: boolean;
    icon?: string;
    placeholder?: string;
    label: string;
    value: string | undefined;
    validate?: Function;
    validateAsync?(name: string,): Promise<string[]>,
    onChange?(value: any): void;
    autoComplete?: string,
    allowOnlyDigits?: boolean,
    readonly?: boolean,
    forbidSpecialChars?: boolean,
    tooltip?: string,
    onBlur?(): void,
    saved?: boolean,
    onEnter?(): void,
    name?:string,
}

interface IState {
    errors: string[],
}

export default class InputText extends React.Component<IProps, IState> implements IInputField {
    protected input: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errors: [],
        };
    }

    focus() {
        this.input.current!.focus();
    }

    getErrors(value = this.props.value || '') {
        let errors: string[] = [];

        if (this.props.validate) {
            this.props.validate(
                value || '',
                errors,
                () => this.validateInput(value, errors),
            );
            if (errors.length !== 0) {
                return errors;
            }
        }
        this.validateInput(value, errors);
        return errors;
    }

    async getErrorsAsync(value = this.props.value || ''): Promise<string[]> {
        let errors: string[] = [];

        if (this.props.validate) {
            this.props.validate(
                value || '',
                errors,
                () => this.validateInput(value, errors),
            );
        }

        if (this.props.validateAsync) {
            let err = await this.props.validateAsync(value);
            errors = errors.concat(err);
        }
        this.validateInput(value, errors);
        return errors;
    }

    isValid() {
        // return this.state.errors.length === 0;
        return this.getErrors().length === 0;
    }


    validate(value = this.props.value || '') : string[] {
        const errors = this.getErrors(value);
        this.setState({
            errors: errors,
        })
        return errors;
    }

    async validateAsync(value = this.props.value || '') {
        const errors = await this.getErrorsAsync(value);
        this.setState({
            errors: errors,
        })
    }

    validateInput(value: string, errors: string[]) {
        if (this.props.required === false && (this.props.value || '').length === 0) {
            return;
        }

        if (this.props.required && (value || '').length === 0) {
            errors.push("Toto pole je povinné");
        }
        if (this.props.minLength && (value || '').length < this.props.minLength) {
            errors.push("Zadejte alespoň " + this.props.minLength + " znaků");
        }
        if (this.props.length && (value || '').length !== this.props.length) {
            if ((this.props.required || (value || '').length > 0)) {
                errors.push("Pole musí obsahovat " + this.props.length + " znaků");
            }

        }
        if (this.props.forbidSpecialChars === true && (value || '')) {
            var filter = /^[^<>!@$]*$/;

            if (!filter.test(value || '')) {
                errors.push("Pole nesmí obsahovat speciální znaky");
            }
        }
        if (this.props.allowOnlyDigits && (value || '').length > 0) {
            var isnum = /^\d+$/.test(value || '');
            if (!isnum) {
                errors.push("Pole může obsahovat pouze číslice");
            }
        }
        if (this.props.maxLength && (value || '').length > this.props.maxLength) {
            errors.push("Pole může obsahovat maximálně " + this.props.maxLength + " znaků");
        }
    }

    handleOnBlur() {
        // this.validate();

        this.validateAsync().then(value => {
            if (this.props.onBlur) {
                this.props.onBlur();
            }
        })

    }

    handleChange(e: any) {
        let value = e.target.value;

        if (this.props.maxLength && (value || '').length > this.props.maxLength) {
            value = value.slice(0, this.props.maxLength);
        }

        //     this.validate(value);
        this.props.onChange!(value);
    }

    handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
        if (event.key === 'Enter') {
            if (this.props.onEnter) {
                this.props.onEnter();
            }
        }
    }

    addError(message: string): string[] {
        let errors = this.state.errors;
        errors.push(message);

        this.setState({
            errors: errors
        })
        return errors;
    }

    renderError() {
        if (this.state.errors.length === 0) { return null; }
        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderCountOfChars() {
        if (this.props.maxLength) {
            return (
                <small>
                    Napsáno: {this.props.value != null ? this.props.value.length : null} (max: {this.props.maxLength} znaků)
                </small>
            );
        }
        return null;
    }

    renderLabel() {
        if (this.props.label.length === 0) {
            return null;
        }

        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required && this.props.label.length !== 0 ? <span className="text-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                <div className="input-group">
                    <input
                        data-clarity-unmask="True"
                        type="text"
                        ref={this.input}
                        className={`form-control data-hj-allow ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                        placeholder={this.props.placeholder}
                        value={this.props.value || ''}
                        onChange={this.handleChange.bind(this)}
                        autoComplete={this.props.autoComplete}
                        readOnly={this.props.readonly}
                        disabled={this.props.readonly}
                        onBlur={this.handleOnBlur.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        name={this.props.name}
                    />
                </div>

                <div className="">
                    {this.renderCountOfChars()}

                    {this.props.saved === true ? <small>
                        <span className="float-right text-right text-success mt-1">
                            <i className={`fas fa-check text-success tx-16 lh-0 op-6`}></i> uloženo</span>
                    </small> : ''}
                </div>

                {this.renderError()}
            </div>
        );
    }
}
