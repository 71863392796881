import { Button, Modal } from "react-bootstrap";
import { WebsiteWordpressSetupTutorial } from "./WebsiteWordpressSetupTutorial";

interface IProps {
    onHide(): void,
}

export const WebsiteWordpressSetupTutorialModal = (props: IProps) => {

    const { onHide } = props;

    return <>
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Jak připojit Wordpress web</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <WebsiteWordpressSetupTutorial />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}