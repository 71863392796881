import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import IArticle from "../../Interfaces/IArticle";
import ArticlesApiService from "../../Services/ArticlesApiService"
import { LoadingScreen } from "../Others/LoadingScreen";
import { ArticleContent } from "./ArticleContent";

interface IProps {
    article: IArticle,
    onHide(): void,
}

const articleApiService = new ArticlesApiService();

export const ArticleDetailModal = (props: IProps) => {

    const { article, onHide } = props;
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState<string>();

    useEffect(() => {
        articleApiService.getArticle(article.id)
            .then(data => {
                const content = data.others.get("content") as string;
                setLoading(false);
                setContent(content);
            });

        return (() => {
            articleApiService.cancelRequests();
        })
    }, [])

    return (
        <Modal size={"lg"} centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-dark-brown">
                <Modal.Title>Detail článku</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? <LoadingScreen /> :
                    <ArticleContent
                        article={article}
                        content={content || ''}
                    />}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Zavřít
                </Button>
            </Modal.Footer>
        </Modal>)

}