import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { ArticlesTable } from "../../Components/Articles/ArticlesTable";
import { CustomDocumentTitle } from "../../Components/Layout/CustomDocumentTitle"
import { LoadingScreen } from "../../Components/Others/LoadingScreen";
import ArticlesApiService from "../../Services/ArticlesApiService";
import ArticleStore from "../../Stores/ArticleStore";
import CurrentUserStore from "../../Stores/CurrentUserStore";
import WebsiteStore from "../../Stores/WebsiteStore";

interface IProps {
    websiteStore?: WebsiteStore,
    currentUserStore?: CurrentUserStore,
    articleStore?: ArticleStore,
}

const articlesApiService = new ArticlesApiService();

export const MyArticlesPage = inject('articleStore', 'websiteStore', 'currentUserStore')(observer((props: IProps) => {

    const countToShow = 15;
    const [loading, setLoading] = useState(true);
    const [skipCount, setSkipCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [articlesIds, setArticlesIds] = useState<string[]>([]);

    const currentUser = props.currentUserStore?.user;

    useEffect(() => {
        setLoading(true);

        articlesApiService.getArticles(countToShow, skipCount)
            .then(data => {
                setLoading(false);

                const articles = data.records.articles.items;

                setArticlesIds(articles.map(s => s.id));
                setTotalCount(data.others.get("totalCount"));
            })

        return () => {
            articlesApiService.cancelRequests();
        }
    }, [currentPage])

    const articles = props.articleStore?.filterByIds(articlesIds);

    const onPageChange = (pageNumber: number) => {
        let skipCount = 0;

        if (pageNumber >= 2) {
            skipCount = (pageNumber - 1) * countToShow;
        }

        setSkipCount(skipCount);
        setCurrentPage(pageNumber);
    }

    if (loading) return <LoadingScreen />

    return <>
        <CustomDocumentTitle title="Články">
            <div className="row">
                <div className="col">
                    <div className="portlet">
                        <div className="portlet-header portlet-header-bordered mt-2">
                            <h3 className="portlet-title"><i className="fa-solid fa-list me-2" /> Články</h3>
                        </div>
                        <div className="portlet-body">

                            <ArticlesTable articles={articles || []} />

                            {totalCount > countToShow && <Pagination
                                pageRangeDisplayed={countToShow}
                                activePage={currentPage}
                                itemsCountPerPage={countToShow}
                                totalItemsCount={totalCount}
                                onChange={(val) => onPageChange(val)}
                                linkClass="page-link"
                                itemClass="page-item"
                                innerClass="pagination justify-content-center mt-3"
                                hideDisabled={true}
                                hideFirstLastPages={true}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    </>
}))